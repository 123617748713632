import { createFileRoute } from "@tanstack/react-router";

import { NotificationList } from "@/components/Notifications/list/component.tsx";

const UserNotificationsView = () => {
  // eslint-disable-next-line no-use-before-define
  const { id } = Route.useParams();

  return <NotificationList id={id} />;
};

export const Route = createFileRoute("/users/$id/notifications/")({
  component: UserNotificationsView,
});
