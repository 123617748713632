import type { SVGProps } from "react";
import { memo } from "react";
const SvgOffice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    color={props.color || "#0077FB"}
  >
    <path
      fill='#fff'
      d='M18.6 17.4H18v-6h.3c.168 0 .3-.132.3-.3v-.6c0-.168-.132-.3-.3-.3h-4.5V6.6h.3c.168 0 .3-.132.3-.3v-.6c0-.168-.132-.3-.3-.3H5.7c-.168 0-.3.132-.3.3v.6c0 .168.132.3.3.3H6v10.8h-.6c-.33 0-.6.27-.6.6s.27.6.6.6h13.2c.33 0 .6-.27.6-.6s-.27-.6-.6-.6m-7.8 0H9v-1.2h1.8zm2.4-6.9v6.9h-1.8v-1.65a.15.15 0 0 0-.15-.15h-2.7a.15.15 0 0 0-.15.15v1.65H6.6V6.6h6.6zm4.2 6.9h-3.6v-6h3.6z'
    />
    <path
      fill='#fff'
      d='M8.25 7.8h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M10.35 7.8h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M12.45 7.8h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M8.25 9.6h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M10.35 9.6h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M12.45 9.6h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M8.25 11.4h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M10.35 11.4h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M12.45 11.4h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M8.25 13.2h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M10.35 13.2h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M12.45 13.2h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M16.05 12.6h-.9a.15.15 0 0 0-.15.15v.9c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-.9a.15.15 0 0 0-.15-.15M16.05 14.4h-.9a.15.15 0 0 0-.15.15v1.5c0 .083.067.15.15.15h.9a.15.15 0 0 0 .15-.15v-1.5a.15.15 0 0 0-.15-.15'
    />
  </svg>
);
const Memo = memo(SvgOffice);

export default Memo;
