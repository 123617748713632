import { RFC } from "react";
import { AuthProvider } from "react-oidc-context";

import { getOidcConfig, getUserStore } from "./config.ts";

export const AuthenticationContext: RFC = ({ children }) => {
  const oidcConfig = getOidcConfig();
  const userStore = getUserStore();

  return (
    <AuthProvider {...oidcConfig} userStore={userStore}>
      {children}
    </AuthProvider>
  );
};
