export const roleProfileCodes = [
  "ADMIN",
  "MANAGER",
  "ACCOUNTANT",
  "VIEWER",
] as const;

export type RolesProfileCode = (typeof roleProfileCodes)[number];

export const isRolesProfileCode = (code: string): code is RolesProfileCode =>
  roleProfileCodes.some((rolesProfileCode) => rolesProfileCode === code);

export type RolesProfileIconProps = {
  className?: string;
  dataTestId?: string;
  code: RolesProfileCode;
  size?: number;
};
