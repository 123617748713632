import { useMutation } from "@tanstack/react-query";

import { disableAgentMutation } from "@/api/graphql/agents/disable/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { AccountsMsDisableSupportUserRequest } from "@/api/graphql/types/graphql.ts";

const disableSupportUserQuery = (
  request: AccountsMsDisableSupportUserRequest,
) => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(disableAgentMutation, { request });
};
const useDisableSupportUser = () => {
  const {
    mutateAsync: disableSupportUser,
    isSuccess,
    error,
  } = useMutation({
    mutationFn: async (id: string) => await disableSupportUserQuery({ id: id }),
    onSuccess: () => "success",
    onError: () => "error",
  });

  return { disableSupportUser, isSuccess, error };
};

export { useDisableSupportUser };
