import { css, Global } from "@emotion/react";
import {
  DsProvider,
  DsProviderProps,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { createContext, RFC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useThemePreferences } from "@/hooks/styles/useThemePreferences";

import { type ThemeContextType } from "./types";

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeContextProvider: RFC = ({ children }) => {
  const { preferedTheme } = useThemePreferences();
  const { i18n } = useTranslation();
  const [mode, setMode] = useState<ThemeContextType["mode"]>(preferedTheme);

  useEffect(() => {
    setMode(preferedTheme);
  }, [preferedTheme]);

  const language = useMemo<DsProviderProps["locale"]>(() => {
    if (i18n.language?.includes("fr")) {
      return "fr";
    }
    return "en";
  }, [i18n.language]);

  return (
    <ThemeContext.Provider value={{ mode, setMode }}>
      <DsProvider mode={mode} locale={language}>
        <>
          <Global
            styles={css`
              :root {
                color-scheme: ${mode === "dark" ? "dark" : "light"};
              }
            `}
          />
          {children}
        </>
      </DsProvider>
    </ThemeContext.Provider>
  );
};

/*
export const useTheme = (): UseThemeReturnType => {
  const context = useContext(ThemeContext);
  const dsTheme = useDsTheme();

  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeContextProvider");
  }

  return { ...context, dsTheme };
};
*/
