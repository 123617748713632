import { BubbleText } from "@totalenergiescode/mobility-business-rev-design-system";
import React from "react";

import { StyledContainer, StyledText } from "./styles";
import { type TabMenuButtonProps } from "./types";

const TabMenuButton: React.FC<TabMenuButtonProps> = ({
  content,
  index,
  isSelectedTab,
  setTabSelected,
  dataTestId,
}) => (
  <StyledContainer
    data-testid={dataTestId}
    isSelectedTab={isSelectedTab}
    onClick={() => {
      setTabSelected(index);
      content.onClick();
    }}
  >
    <StyledText variant='title1' fontWeight={isSelectedTab ? "bold" : "medium"}>
      {content.label}
    </StyledText>
    &nbsp;
    {content.hasBubbleText ? (
      <BubbleText variant={content.variant} text={content.text} isBadge />
    ) : null}
  </StyledContainer>
);

export const MemoizedTabMenuButtonComponent = React.memo(TabMenuButton);
MemoizedTabMenuButtonComponent.displayName = "TabMenuButton";
