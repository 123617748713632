import styled from "@emotion/styled";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { FC } from "react";
import { AuthContextProps, useAuth } from "react-oidc-context";

import { Navbar } from "@/components/Navbar";
import { SideBar } from "@/components/Sidebar";
import { SignIn } from "@/components/SignIn";
import { RoundedFullPage } from "@/layouts/common/RoundedFullPage";
import { AuthorizationLayout } from "@/layouts/guards/authorization/layout";

const RoundedFullPageWithShadow = styled(RoundedFullPage)`
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.7));
  overflow: hidden;
`;
const GradientBackground = styled.div`
  flex-grow: 1;
  display: flex;
  min-height: 100dvh;
  background: linear-gradient(#0ea3fb, #0077fb);
`;
const RootLayout: FC = () => {
  const auth = useAuth();

  return auth.user ? (
    <AuthorizationLayout>
      <>
        <GradientBackground>
          <SideBar />
          <RoundedFullPageWithShadow>
            <>
              <Navbar />
              <Outlet />
            </>
          </RoundedFullPageWithShadow>
        </GradientBackground>
        <TanStackRouterDevtools />
      </>
    </AuthorizationLayout>
  ) : (
    <SignIn />
  );
};

interface MyRouterContext {
  auth: AuthContextProps;
  roles: string[];
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootLayout,
});
