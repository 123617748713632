import "@emotion/react";

// only place where material should be used, eventually export Theme from DS
import { Theme as MuiTheme } from "@mui/material";

declare module "@emotion/react" {
  // eslint-disable-next-line
  export interface Theme extends MuiTheme {}
}

export const iconProps = {
  width: "20px",
  height: "20px",
};
