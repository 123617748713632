import React from "react";

import { Breadcrumb } from "@/components/shared/Breadcrumb";
import { GoBackArrowButton } from "@/components/shared/GoBackArrowButton/component";
import {
  StyledContent,
  StyledTitle,
  StyledTitleAndBreadcrumbContainer,
} from "@/components/shared/PageHeader/styles";
import { Props } from "@/components/shared/PageHeader/types";

const PageHeader: React.FC<Props> = ({
  breadcrumbItems,
  title,
  href,
  className,
  goBackDataTestId = "common__page-header_go-back-arrow-button",
  titleDataTestId = "common__page-header_title",
  context = "main-menu",
  rightContent,
}) => (
  <StyledContent
    className={className}
    $showIcon={Boolean(href)}
    $context={context}
  >
    {href ? (
      <GoBackArrowButton dataTestId={goBackDataTestId} href={href} />
    ) : null}
    <StyledTitleAndBreadcrumbContainer>
      <>
        <StyledTitle
          dataTestId={titleDataTestId}
          variant='title5'
          fontWeight='bold'
        >
          {title}
        </StyledTitle>
        <Breadcrumb breadcrumbItems={breadcrumbItems} />
      </>
    </StyledTitleAndBreadcrumbContainer>

    {rightContent}
  </StyledContent>
);

export const MemoizedPageHeader = React.memo(PageHeader);
MemoizedPageHeader.displayName = "PageHeader";
