import styled from "@emotion/styled";
import { Link } from "@tanstack/react-router";
import {
  ArrowRight,
  Button,
  Divider,
  Text,
  TextField,
} from "@totalenergiescode/mobility-business-rev-design-system";

export const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled(Text)`
  padding: 24px 0px 24px 34px;
`;

export const StyledDivider = styled(Divider)`
  margin-inline: 34px;
`;
export const StyledTableHeader = styled("div")``;
export const StyledTable = styled("div")``;
export const StyledCell = styled("div")``;
export const StyledCellWithEndArrow = styled("div")``;
export const StyledRightIcon = styled(ArrowRight)``;
export const StyledRowContainerLink = styled(Link)`
  cursor: pointer;
`;
export const StyledCellHeaderText = styled(Text)``;
export const StyledCellText = styled(Text)``;

export const StyledSearchFieldContainer = styled("div")`
  width: 60%;
  min-width: 200px;
  padding-left: 24px;
  margin: 14px;
`;
export const StyledSearchField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 50px 50px 0px 50px;
  *.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.grey[200]};
    border-radius: 50px 50px 0px 50px;
    border-width: 1;
  }
  &:focus {
    /* Styles for the focused input field */
    /* Add your styles for the selected input field here */
    border-color: #007bff; /* Example: change border color when focused */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Example: add a box shadow when focused */
  }
  .MuiOutlinedInput-root {
    padding-left: 8px;
  }
  .MuiInputAdornment-root > div {
    padding: 8px;
  }
`;

export const StyledRoundedLeftContainer = styled("div")`
  padding-inline: 34px;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.palette.grey[100]} 0%,
    ${({ theme }) => theme.palette.background.paper} 30%
  );
  border-top-left-radius: 40px;
`;

export const StyledCustomTableContainer = styled("div")`
  padding-inline: 34px;

  ${StyledTableHeader} {
    display: grid;
    grid-template-columns: repeat(6, 1fr) 30px;
    width: 100%;
    min-width: 100%;
    border-radius: 10px 10px 0px 0px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
  ${StyledTable} {
  }

  ${StyledRowContainerLink} {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(6, 1fr) 30px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }

  ${StyledCell} {
    padding-left: 14px;
    min-height: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-block: 14px;
    overflow-wrap: break-word;
    word-break: break-all;
    min-width: 150px;

    ${StyledCellHeaderText} {
      color: ${({ theme }) => theme.palette.grey[700]};
      font-weight: 700;
    }

    ${StyledCellText} {
      color: ${({ theme }) => theme.palette.grey[900]};
    }
  }

  ${StyledCellWithEndArrow} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${StyledRightIcon} {
      color: ${({ theme }) => theme.palette.primary[500]};
      width: 26px;
      height: auto;
    }
  }
`;

export const StyledButton = styled(Button)``;

export const StyledButtonContainer = styled("div")`
  display: flex;
  margin-top: 14px;
  margin-inline: 34px;
  justify-content: center;
  ${StyledButton} {
    width: 100%;
  }
`;
