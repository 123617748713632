import { graphql } from "@/api/graphql/types/gql.ts";

export const GetNotificationsByUserQuery = graphql(`
  query GetNotificationsByUserQuery(
    $request: NotificationsMsSearchNotificationsRequest
  ) {
    NotificationsMsSearchNotifications(request: $request) {
      data {
        id
        subject
        hasAttachments
        createdTs
        status
        failedReason
        AccountIdData {
          registrationName
          number
        }
        UserIdData {
          firstName
          lastName
          email
        }
      }
      pagination {
        size
        page
        total
      }
    }
  }
`);
