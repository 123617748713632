import React from "react";

import { StyledBorder, StyledContainer, StyledLeftHighlight } from "./styles";
import { type HighlightLeftCardProps } from "./types";

export const HighlightLeftCard: React.FC<HighlightLeftCardProps> = ({
  highlightColor,
  borderRadius,
  children,
  ...props
}) => (
  <StyledContainer
    {...props}
    $highlightColor={highlightColor}
    $borderRadius={borderRadius}
  >
    <StyledBorder />
    <StyledLeftHighlight />
    {children}
  </StyledContainer>
);
