import { RequestMiddleware } from "graphql-request";

import { getBearer, getIdToken } from "@/utils/api";

import { namedOperations } from "./constants";

export const addBearer = (request: Parameters<RequestMiddleware>[0]) => {
  const authorization =
    request.headers &&
    "Authorization" in request.headers &&
    request.headers["Authorization"]
      ? request.headers["Authorization"]
      : null;

  if (!authorization) {
    const bearer = getBearer();

    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${bearer}`,
      },
    };
  }

  return request;
};

export const addBearerWithIdToken = (
  request: Parameters<RequestMiddleware>[0],
) => {
  const authorization =
    request.headers &&
    "Authorization" in request.headers &&
    request.headers["Authorization"]
      ? request.headers["Authorization"]
      : null;

  if (!authorization) {
    const idToken = getIdToken();

    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${idToken}`,
      },
    };
  }

  return request;
};

export const requestMiddlewares = (
  request: Parameters<RequestMiddleware>[0],
): Parameters<RequestMiddleware>[0] => {
  let requestWithBearer;

  if (request.operationName == namedOperations.SearchAccountsMsMe) {
    requestWithBearer = addBearerWithIdToken(request);
  } else {
    requestWithBearer = addBearer(request);
  }

  return requestWithBearer;
};
