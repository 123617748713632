import {
  Administrateur,
  Comptable,
  Consultation,
  Gestionnaire,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { DEFAULT_ROLE_PROFILE_ICON_SIZE } from "@/components/shared/RolesProfileIcon/constants";
import { RolesProfileIconProps } from "@/components/shared/RolesProfileIcon/types";

export const RolesProfileIcon = ({
  className,
  dataTestId,
  code,
  size = DEFAULT_ROLE_PROFILE_ICON_SIZE,
}: RolesProfileIconProps) => {
  const iconProps = {
    className,
    "data-testid": dataTestId,
    width: size,
    height: size,
  };

  switch (code) {
    case "MANAGER":
      return <Gestionnaire {...iconProps} />;
    case "ACCOUNTANT":
      return <Comptable {...iconProps} />;
    case "VIEWER":
      return <Consultation {...iconProps} />;
    case "ADMIN":
    default:
      return <Administrateur {...iconProps} />;
  }
};
