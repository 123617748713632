import { enableAgentMutation } from "@/api/graphql/agents/enable/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { AccountsMsEnableSupportUserRequest } from "@/api/graphql/types/graphql.ts";

const enableSupportUserQuery = (
  request: AccountsMsEnableSupportUserRequest,
) => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(enableAgentMutation, { request });
};

export { enableSupportUserQuery };
