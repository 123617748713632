import styled from "@emotion/styled";

export const StyledContainer = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-top-left-radius: 40px;
`;
