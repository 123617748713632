import { useQuery } from "@tanstack/react-query";

import { getAccountDetail } from "@/api/graphql/accounts/getAccountDocument/api.ts";
import { toCompanyDetails } from "@/api/graphql/accounts/getAccountDocument/mappers.ts";
import { queries } from "@/api/graphql/constants.ts";

export const useGetAccountDetail = (accountId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: [queries.GET_ACCOUNT_DETAIL, accountId],
    queryFn: async () => {
      try {
        const response = await getAccountDetail({
          filters: { ids: [accountId] },
        });

        return response.AccountsMsSearchAccounts;
      } catch {
        return;
      }
    },
  });

  return { companyData: toCompanyDetails(data), isLoading };
};
