import { useMutation } from "@tanstack/react-query";

import { AccountsMsStartSupportRequest } from "../../types/graphql";
import { startSupportSession } from "./api";

export const useStartSupportSession = () => {
  const { mutateAsync, isSuccess, error } = useMutation({
    mutationFn: async (request: AccountsMsStartSupportRequest) => {
      const data = await startSupportSession(request);

      return data.AccountsMsStartSupport;
    },
  });

  return { mutateAsync, isSuccess, error };
};
