import { GetAccountDetailQuery } from "@/api/graphql/accounts/getAccountDocument/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { AccountsMsSearchAccountsRequest } from "@/api/graphql/types/graphql.ts";

export const getAccountDetail = ({
  filters,
}: AccountsMsSearchAccountsRequest) => {
  const ids = filters?.ids || [];
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(GetAccountDetailQuery, {
    request: {
      filters: {
        ids,
      },
    },
  });
};
