import styled from "@emotion/styled";
import {
  ArrowRight,
  Button,
  Identity,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { PageHeader } from "@/components/shared/PageHeader";

export const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const StyledSectionContainer = styled("div")`
  padding-left: 34px;
`;

export const StyledTitle = styled(Text)`
  padding: 24px 0px 24px 34px;
`;

export const StyledCardContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  padding: 0px 68px 34px 34px;
`;

export const StyledTextContainer = styled("div")``;

export const StyledCard = styled("div")`
  padding: 24px;
  background-color: ${(props) => props.theme.palette.grey[50]};
  border-radius: 8px;
`;

export const StyledRoundedTopRightCard = styled("div")`
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  border-radius: 8px 40px 8px 8px;
`;

export const StyledCardTitle = styled(Text)`
  font-family: "Nunito", sans-serif;
  line-height: 1;
  color: ${(props) => props.theme.palette.grey[500]};
  padding: 0px 0px 16px 0px;
`;

export const StyledCardContent = styled(Text)`
  padding: 16px 0px 0px 0px;
  font-size: 14px;
`;

export const StyledIdentity = styled(Identity)`
  padding: 16px 0px 0px 0px;
  .MuiAvatar-circular {
    background-color: #50e3c2;
    * {
      color: white;
    }
  }
  .MuiTypography-text1 {
    color: #86959b;
  }
`;

export const StyledButtonHeaderContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
export const StyledPageHeader = styled(PageHeader)`
  justify-content: space-between;
  padding: 0 40px 20px 40px;
`;

export const StyledButtonNotifications = styled(Button)`
  gap: 2px;
`;

export const StyledArrowRight = styled(ArrowRight)`
  color: ${({ theme }) => theme.palette.white};
`;
