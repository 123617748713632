import {
  Connexion,
  IconAndTextButton,
  TotalEnergies,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { LanguageSelector } from "@/components/LanguageSelector";
import {
  StyledAvatar,
  StyledDiv,
  StyledIconTriggersContainer,
  StyledIdentity,
  StyledItemsContainer,
  StyledNavbarContainer,
  StyledNavbarContainerBackground,
  StyledPopOverContainer,
  StyledSearchFieldContainer,
} from "@/components/Navbar/styles.ts";
import Search from "@/components/Search/component";
import { StyledPopOver } from "@/components/shared/AvatarPopOver/styles";
import { useMe } from "@/hooks/session/useMe";

const iconSize = 60;

export const Navbar: FC = () => {
  const auth = useAuth();
  const { userInfo } = useMe();
  const { t: tCommon } = useTranslation("common");
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  return (
    <StyledNavbarContainerBackground>
      <StyledNavbarContainer>
        <StyledItemsContainer>
          <TotalEnergies height={iconSize} width={iconSize} />
          <StyledSearchFieldContainer>
            <Search />
          </StyledSearchFieldContainer>
          <StyledIconTriggersContainer>
            <LanguageSelector dataTestId='language' />
            <StyledDiv>
              <StyledDiv onClick={(e) => setProfileAnchorEl(e.currentTarget)}>
                <StyledAvatar
                  firstName={userInfo.firstname || "N"}
                  lastName={userInfo.lastname || "F"}
                  size='m'
                  type='initials'
                />
              </StyledDiv>
              <StyledPopOver
                anchorEl={profileAnchorEl}
                onClose={() => setProfileAnchorEl(null)}
                open={Boolean(profileAnchorEl)}
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "bottom",
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
              >
                <StyledPopOverContainer>
                  <StyledDiv sx={{ marginBottom: "10px" }}>
                    <StyledIdentity
                      igg={userInfo.igg || "Temp IGG"}
                      avatar={{
                        firstName: userInfo.firstname,
                        lastName: userInfo.lastname,
                        type: "initials",
                      }}
                      subtitle={userInfo.email}
                      title={
                        userInfo.firstname && userInfo.lastname
                          ? `${userInfo.firstname} ${userInfo.lastname}`
                          : "N F"
                      }
                    />
                  </StyledDiv>

                  <IconAndTextButton
                    color='rgba(237, 0, 0, 1)'
                    iconLeft={<Connexion color='rgba(237, 0, 0, 1)' />}
                    onClick={() => auth.signoutRedirect()}
                    size='l'
                    text={tCommon("common.auth.logout")}
                    textAlign='left'
                  />
                </StyledPopOverContainer>
              </StyledPopOver>
            </StyledDiv>
          </StyledIconTriggersContainer>
        </StyledItemsContainer>
      </StyledNavbarContainer>
    </StyledNavbarContainerBackground>
  );
};
