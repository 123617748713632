import styled from "@emotion/styled";
import {
  Text,
  TotalEnergies,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { DIVIDED_BY_IMAGE_LAYOUT_CHILDREN_MOBILE_MIN_HEIGHT } from "@/utils/styles/constants";

export const StyledTitleText = styled(Text)``;
export const StyledHintText = styled(Text)``;
export const StyledInfoText = styled(Text)``;
export const StyledLogo = styled(TotalEnergies)``;
export const StyledContent = styled("div")``;

export const StyledContainer = styled("div")<{
  $isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: ${DIVIDED_BY_IMAGE_LAYOUT_CHILDREN_MOBILE_MIN_HEIGHT};
  margin-inline: 27px;

  ${StyledLogo} {
    width: 80px;
    height: 80px;
    position: relative;
  }

  ${StyledContent} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 35px;

    ${StyledTitleText} {
      text-align: center;
      margin-bottom: 10px;
    }

    ${StyledHintText} {
      text-align: center;
      margin-bottom: 24px;
      color: ${(props) => props.theme.palette.grey[500]};
    }

    ${StyledInfoText} {
      font-style: italic;
      font-size: 10px;
      text-align: center;
      margin-bottom: 24px;
      margin-top: 16px;
      color: ${(props) => props.theme.palette.grey[500]};
    }
  }

  ${(props) =>
    !props.$isMobile &&
    `
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin-inline: auto;

    min-height: 100vh;
    max-width: 305px;
    
    ${StyledLogo} {
      width: 120px;
      height: 120px;
      position: absolute;
      top: 35px;
    }
    
    ${StyledContent} {
      flex: 1 1 auto;
      padding-top: 0px;
    }
  `}
`;
