import { useTranslation } from "react-i18next";

import { BreadcrumbItem } from "@/components/shared/Breadcrumb/types.ts";

export const Utils = () => {
  const { t: tUsers } = useTranslation("users");

  return [
    {
      label: tUsers("users.forms.agent"),
      value: "AGENT",
    },
    {
      label: tUsers("users.forms.superAgent"),
      value: "SUPER_AGENT",
    },
  ];
};

export const useBreadcrumbItems = (): BreadcrumbItem[] => {
  const { t: tCommon } = useTranslation("common");
  const { t: tUsers } = useTranslation("users");

  return [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: tUsers("users.forms.backOfficeAgents"),
      path: "/backoffice-users",
    },
    {
      title: tUsers("users.forms.inviteAgent"),
      path: "/backoffice-users/add",
    },
  ];
};
