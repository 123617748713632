import { styled, SxProps, TextField, Theme } from "@mui/material";
import {
  No,
  Search as SearchIcon,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { Office } from "@/components/Icons/generated";

export const StyledSearchField = styled(TextField)`
  background-color: #f1f4f6;
  border-radius: 50px 50px 0px 50px;
  *.MuiOutlinedInput-notchedOutline {
    border-radius: 50px 50px 0px 50px;
    border-width: 0;
  }
  &:focus {
    /* Styles for the focused input field */
    /* Add your styles for the selected input field here */
    border-color: #007bff; /* Example: change border color when focused */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Example: add a box shadow when focused */
  }
  .MuiOutlinedInput-root {
    padding-left: 8px;
  }
  .MuiInputAdornment-root > div {
    padding: 8px;
    background-color: #0077fb;
    border-radius: 50%;
  }
  input {
    padding: 14px;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  color: white;
`;

export const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
  .MuiFormLabel-root {
    font-size: 14px;
  }
`;

export const StyledFlexContainer = styled("div")`
  display: flex;
`;

export const StyledDiv = styled("div")``;

export const StyledOptionDescriptionText = styled(Text)<{
  $hasTopMargin: boolean;
}>`
  margin-left: ${({ theme }) => theme.spacing(1.75)};
  color: ${({ theme }) => theme.palette.grey[500]};
  ${({ $hasTopMargin }) => ($hasTopMargin ? "margin-top: 4px;" : "")}
`;

export const StyledIconContainer = styled("div")<{
  $hasSpacingLeft?: boolean;
  $hasSpacingRight?: boolean;
}>`
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  ${({ theme, $hasSpacingLeft }) =>
    $hasSpacingLeft ? `margin-left: ${theme.spacing(1)};` : ""}
  ${({ theme, $hasSpacingRight }) =>
    $hasSpacingRight ? `margin-right: ${theme.spacing(1)};` : ""}
`;

export const StyledAdornmentContainer = styled("div")`
  display: flex;
  align-items: center;
`;

export const StyledAffixText = styled(Text)`
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const StyledPopOverContainer = styled("div")`
  width: 100%;
  background-color: white;
  margin-top: 10px;
  border: 1px solid #d5dfe4;
  border-radius: 20px 0px 20px 20px;
  box-shadow: 5px 5px 10px 0px #24292a0d;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
`;

export const StyledPersonIcon = styled(No)`
  color: white;
  padding: 8px;
  background-color: #019d7f;
  border-radius: 80px;
`;

export const StyledOfficeIcon = styled(Office)`
  color: white;
  padding: 6px;
  background-color: ${({ theme }) => theme.palette.info.main};
  border-radius: 80px;
`;

export const StyledGroupText = styled(Text)`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledOptionContainer = styled("li")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

export const StyledLeftSideOptionContainer = styled("div")`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 15px;
`;

/**
 * Multiple components include a TextField and overrides in createTheme did not work properly
 * The following functions are expected to be used every time a component requires TextField styles, e.g. in DatePicker or Select
 */
export const getTextFieldInputSx = (theme: Theme): SxProps<Theme> => ({
  flexGrow: 1,
  borderRadius: "6px",
  "& MuiTextField-root": {
    flexGrow: 1,
  },
  "& input": {
    fontSize: theme.typography.text2.fontSize,
  },
  "&:not(.Mui-error):not(.Mui-disabled):hover fieldset.MuiOutlinedInput-notchedOutline":
    {
      borderColor: theme.palette.primary[400],
    },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.grey[100],

    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
});

export const getTextFieldLabelSx = ({
  theme,
  isError,
}: {
  theme: Theme;
  isError: boolean | undefined;
}): SxProps<Theme> => ({
  color: isError ? undefined : theme.palette.primary.main,
});
