/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Any: { input: any; output: any };
  Double: { input: any; output: any };
  Int64: { input: number; output: number };
  Map: { input: any; output: any };
  Void: { input: any; output: any };
};

export type AccountsMsCountSupportUsersByStatusResponse = {
  __typename?: "AccountsMsCountSupportUsersByStatusResponse";
  all: Scalars["Int64"]["output"];
  disabled: Scalars["Int64"]["output"];
  enabled: Scalars["Int64"]["output"];
  inviting: Scalars["Int64"]["output"];
};

/**  Count the users by status, scoped to the current account  */
export type AccountsMsCountUsersByStatusRequest = {
  /**  when provided, it will count only the ones that match the provided roles profiles  */
  rolesProfileIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type AccountsMsCountUsersByStatusResponse = {
  __typename?: "AccountsMsCountUsersByStatusResponse";
  all: Scalars["Int64"]["output"];
  disabled: Scalars["Int64"]["output"];
  enabled: Scalars["Int64"]["output"];
  inviting: Scalars["Int64"]["output"];
};

/**  Count the users in a roles profile, scoped to the current account  */
export type AccountsMsCountUsersInRolesProfileRequest = {
  rolesProfileId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsCountUsersInRolesProfileResponse = {
  __typename?: "AccountsMsCountUsersInRolesProfileResponse";
  disabled: Scalars["Int64"]["output"];
  enabled: Scalars["Int64"]["output"];
  inviting: Scalars["Int64"]["output"];
  total: Scalars["Int64"]["output"];
};

/**  Create a new agency  */
export type AccountsMsCreateAgencyRequest = {
  agent?: InputMaybe<AccountsMsCreateAgencyRequestAgent>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subsidiaries?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type AccountsMsCreateAgencyRequestAgent = {
  /**  The user email address  */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /**  The first name  */
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /**  Digital Pass identifier  */
  igg?: InputMaybe<Scalars["String"]["input"]>;
  /**  The functions of the user (mandatory)  */
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  /**  The last name  */
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsCreateAgencyResponse = {
  __typename?: "AccountsMsCreateAgencyResponse";
  /**  The id of the created agency  */
  id: Scalars["ID"]["output"];
};

/**
 *  Creates a roles profile
 *
 * When a roles profile is created under another one, the roles selection is restricted to the parent roles profile roles.
 */
export type AccountsMsCreateRolesProfileRequest = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentId?: InputMaybe<Scalars["ID"]["input"]>;
  roles?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type AccountsMsCreateRolesProfileResponse = {
  __typename?: "AccountsMsCreateRolesProfileResponse";
  data: AccountsMsRolesProfile;
};

/**
 *  Deletes a roles profile
 *
 * Only unused roles profile can be deleted.
 */
export type AccountsMsDeleteRolesProfileRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsDeleteRolesProfileResponse = {
  __typename?: "AccountsMsDeleteRolesProfileResponse";
  deleted: Scalars["Boolean"]["output"];
};

/**
 *  Disable a privilege
 *
 * The related user won't have access to this account anymore. Nothing happens if the privilege is already disabled.
 */
export type AccountsMsDisableRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Disable support user  */
export type AccountsMsDisableSupportUserRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 *  Enable a privilege
 *
 * The related user will be granted back his access to this account. Nothing happens if the privilege is already enabled.
 */
export type AccountsMsEnableRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Enable support user  */
export type AccountsMsEnableSupportUserRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 *  Extend a support session
 *
 * Extend a session for a support operator.
 */
export type AccountsMsExtendSupportRequest = {
  /**  The session id  */
  sessionId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsExtendSupportResponse = {
  __typename?: "AccountsMsExtendSupportResponse";
  /**  When the code expires  */
  expiresTs: Scalars["Int64"]["output"];
};

/**
 *  Forget a privilege
 *
 * Deletes a privilege forever.
 */
export type AccountsMsForgetRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsGetOwnerResponse = {
  __typename?: "AccountsMsGetOwnerResponse";
  PrivilegeIdData?: Maybe<AccountsMsSearchPrivilegesResponseData>;
  UserIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  privilegeId: Scalars["ID"]["output"];
  /**  The related user  */
  userId: Scalars["ID"]["output"];
};

export type AccountsMsGetRolesGridRequest = {
  roleProfileId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsGetRolesGridResponse = {
  __typename?: "AccountsMsGetRolesGridResponse";
  billingSection: AccountsMsGetRolesGridResponseBillingSection;
  collaboratorsSection: AccountsMsGetRolesGridResponseCollaboratorSection;
  contractSection: AccountsMsGetRolesGridResponseContractSection;
  ledgersSection: AccountsMsGetRolesGridResponseLedgersSection;
  reportingSection: AccountsMsGetRolesGridResponseReportingSection;
  usersSection: AccountsMsGetRolesGridResponseUsersSection;
};

export type AccountsMsGetRolesGridResponseBillingSection = {
  __typename?: "AccountsMsGetRolesGridResponseBillingSection";
  billing: AccountsMsGetRolesGridResponseBillingSectionBillingLine;
  tid: AccountsMsGetRolesGridResponseBillingSectionTidLine;
};

export type AccountsMsGetRolesGridResponseBillingSectionBillingLine = {
  __typename?: "AccountsMsGetRolesGridResponseBillingSectionBillingLine";
  read: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseBillingSectionTidLine = {
  __typename?: "AccountsMsGetRolesGridResponseBillingSectionTidLine";
  read: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseCollaboratorSection = {
  __typename?: "AccountsMsGetRolesGridResponseCollaboratorSection";
  collaborators: AccountsMsGetRolesGridResponseCollaboratorSectionCollaboratorsLine;
  vehicles: AccountsMsGetRolesGridResponseCollaboratorSectionVehiclesLine;
};

export type AccountsMsGetRolesGridResponseCollaboratorSectionCollaboratorsLine =
  {
    __typename?: "AccountsMsGetRolesGridResponseCollaboratorSectionCollaboratorsLine";
    read: Scalars["Boolean"]["output"];
    write: Scalars["Boolean"]["output"];
  };

export type AccountsMsGetRolesGridResponseCollaboratorSectionVehiclesLine = {
  __typename?: "AccountsMsGetRolesGridResponseCollaboratorSectionVehiclesLine";
  read: Scalars["Boolean"]["output"];
  write: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseContractSection = {
  __typename?: "AccountsMsGetRolesGridResponseContractSection";
  contract: AccountsMsGetRolesGridResponseContractSectionContractLine;
};

export type AccountsMsGetRolesGridResponseContractSectionContractLine = {
  __typename?: "AccountsMsGetRolesGridResponseContractSectionContractLine";
  read: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseLedgersSection = {
  __typename?: "AccountsMsGetRolesGridResponseLedgersSection";
  ledgers: AccountsMsGetRolesGridResponseLedgersSectionLedgersLine;
  transactions: AccountsMsGetRolesGridResponseLedgersSectionTransactionsLine;
};

export type AccountsMsGetRolesGridResponseLedgersSectionLedgersLine = {
  __typename?: "AccountsMsGetRolesGridResponseLedgersSectionLedgersLine";
  block: Scalars["Boolean"]["output"];
  read: Scalars["Boolean"]["output"];
  write: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseLedgersSectionTransactionsLine = {
  __typename?: "AccountsMsGetRolesGridResponseLedgersSectionTransactionsLine";
  read: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseReportingSection = {
  __typename?: "AccountsMsGetRolesGridResponseReportingSection";
  alerting: AccountsMsGetRolesGridResponseReportingSectionAlertingLine;
  reporting: AccountsMsGetRolesGridResponseReportingSectionReportingLine;
};

export type AccountsMsGetRolesGridResponseReportingSectionAlertingLine = {
  __typename?: "AccountsMsGetRolesGridResponseReportingSectionAlertingLine";
  read: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseReportingSectionReportingLine = {
  __typename?: "AccountsMsGetRolesGridResponseReportingSectionReportingLine";
  advancedRead: Scalars["Boolean"]["output"];
  read: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseUsersSection = {
  __typename?: "AccountsMsGetRolesGridResponseUsersSection";
  rolesProfiles: AccountsMsGetRolesGridResponseUsersSectionRolesProfilesLine;
  users: AccountsMsGetRolesGridResponseUsersSectionUsersLine;
};

export type AccountsMsGetRolesGridResponseUsersSectionRolesProfilesLine = {
  __typename?: "AccountsMsGetRolesGridResponseUsersSectionRolesProfilesLine";
  read: Scalars["Boolean"]["output"];
  write: Scalars["Boolean"]["output"];
};

export type AccountsMsGetRolesGridResponseUsersSectionUsersLine = {
  __typename?: "AccountsMsGetRolesGridResponseUsersSectionUsersLine";
  read: Scalars["Boolean"]["output"];
  write: Scalars["Boolean"]["output"];
};

/**  Invite support user  */
export type AccountsMsInviteSupportUserRequest = {
  /**  The user email address  */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /**  The first name  */
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /**  Digital Pass identifier  */
  igg?: InputMaybe<Scalars["String"]["input"]>;
  /**  The functions of the user  */
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  /**  The last name  */
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  /**  The profile of the user  */
  profile?: InputMaybe<AccountsMsInviteSupportUserRequestProfile>;
};

/**  The profile of the user  */
export enum AccountsMsInviteSupportUserRequestProfile {
  Agent = "AGENT",
  ProfileUnknown = "PROFILE_UNKNOWN",
  SuperAgent = "SUPER_AGENT",
}

export type AccountsMsInviteSupportUserResponse = {
  __typename?: "AccountsMsInviteSupportUserResponse";
  /**  The id of the invited user  */
  id: Scalars["ID"]["output"];
};

/**
 *  Invite a user.
 *
 * Create a privilege for the given user in the current account. A notification is sent to the user.
 * When the user do not exist, we send a welcome notification. Otherwise, we send a classical invitation notification.
 * A new user is created with the same timezone and locale of the current user when the account preferred_locale is
 * not set.
 */
export type AccountsMsInviteUserRequest = {
  /**  An optional email address (required if the user id is empty)  */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /**  The first name of the user if the user_id is empty  */
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /**  The function of the user in the given account  */
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  /**  The last name of the user if the user_id is empty  */
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  /**  The phone number of the user, prefixed with the country code (following E164 standard), if the user_id is empty  */
  phone?: InputMaybe<Scalars["String"]["input"]>;
  /**  The alternative phone number of the user, prefixed with the country code (following E164 standard), if the user_id is empty  */
  phoneAlt?: InputMaybe<Scalars["String"]["input"]>;
  /**  An optional list of roles profiles, otherwise the user is created with the default role profile(s)  */
  rolesProfileIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /**  An optional list of sectors, otherwise the user is created without restriction  */
  sectorIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /**  An optional user id (required if the email is empty). Filled up from a LookupUser response.  */
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsInviteUserResponse = {
  __typename?: "AccountsMsInviteUserResponse";
  /**  The id of the invited user  */
  userId: Scalars["ID"]["output"];
};

export type AccountsMsListLocalesResponse = {
  __typename?: "AccountsMsListLocalesResponse";
  codes?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type AccountsMsListRolesResponse = {
  __typename?: "AccountsMsListRolesResponse";
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 *  Find a user.
 *
 * Search a user in the user book. Multiple results may be returned.
 */
export type AccountsMsLookupUserRequest = {
  /**  The email of the user or other search information.  */
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsLookupUserResponse = {
  __typename?: "AccountsMsLookupUserResponse";
  matches?: Maybe<Array<AccountsMsLookupUserResponseUserPreview>>;
};

export type AccountsMsLookupUserResponseUserPreview = {
  __typename?: "AccountsMsLookupUserResponseUserPreview";
  /**  The first name and the first letter of the family name of the user.  */
  name: Scalars["String"]["output"];
  userId: Scalars["ID"]["output"];
};

/**
 *  The current identity information
 *
 * Returns the current user information, used by the frontend to load partial data about the user profile.
 */
export type AccountsMsMeRequest = {
  /**
   *  The list of account ids narrowing down the returned visa list.
   * The client should add all account ids it caches in order to invalidate the related visa.
   * When no account id is specified, we consider it filled with the user's default account id if there is such.
   */
  accountIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type AccountsMsMeResponse = {
  __typename?: "AccountsMsMeResponse";
  customerUserInfo: AccountsMsMeResponseCustomerUser;
  /**  The user profile ts is the last time of the user's profile shared data were updated.  */
  sharedUserProfileTs: Scalars["Int64"]["output"];
  supportUserInfo: AccountsMsMeResponseSupportUser;
  systemUserInfo?: Maybe<Scalars["Void"]["output"]>;
  /**  The customer user or the support user ID  */
  userId: Scalars["ID"]["output"];
};

export type AccountsMsMeResponseCustomerUser = {
  __typename?: "AccountsMsMeResponseCustomerUser";
  /**  The default account id of the user, if not defined the user must select among the account list.  */
  defaultAccountId: Scalars["ID"]["output"];
  /**  The user's email  */
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  /**  The user's locale  */
  locale: Scalars["String"]["output"];
  /**  The total of accounts the user know. This also count inactive privileges.  */
  numberOfAccounts: Scalars["Int64"]["output"];
  /**  The number of accounts the user has access to. This does not count inactive privileges.  */
  numberOfSwitchableAccounts: Scalars["Int64"]["output"];
  phone: Scalars["String"]["output"];
  phoneAlt: Scalars["String"]["output"];
  /**  The user's timezone  */
  timezone: Scalars["String"]["output"];
  /**  The public visa information, narrowed to the requested account ids  */
  visas?: Maybe<Array<AccountsMsMeResponseCustomerUserPublicVisa>>;
};

/**  The public visa information, narrowed to the requested account ids  */
export type AccountsMsMeResponseCustomerUserPublicVisa = {
  __typename?: "AccountsMsMeResponseCustomerUserPublicVisa";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  /**  The account id  */
  accountId: Scalars["ID"]["output"];
  /**  True if the account is active  */
  accountIsActive: Scalars["Boolean"]["output"];
  /**  The customer account number  */
  customerAccountNumber: Scalars["String"]["output"];
  /**  The exhaustive list of feature codes  */
  features?: Maybe<Array<AccountsMsMeResponseCustomerUserPublicVisaFeatures>>;
  /**  True if the user owns this account  */
  owner: Scalars["Boolean"]["output"];
  /**  An exhaustive list of user roles for the given account  */
  roles?: Maybe<Array<AccountsMsMeResponseCustomerUserPublicVisaRoles>>;
};

/**  The exhaustive list of feature codes  */
export enum AccountsMsMeResponseCustomerUserPublicVisaFeatures {
  BlockLedger = "BLOCK_LEDGER",
  CancelLedgerOrder = "CANCEL_LEDGER_ORDER",
  CreateLedger = "CREATE_LEDGER",
  DownloadAllTransactionsReport = "DOWNLOAD_ALL_TRANSACTIONS_REPORT",
  DownloadBudgetReport = "DOWNLOAD_BUDGET_REPORT",
  DownloadInvoice = "DOWNLOAD_INVOICE",
  DownloadInvoiceFilesTid = "DOWNLOAD_INVOICE_FILES_TID",
  DownloadLedgersStatusesReport = "DOWNLOAD_LEDGERS_STATUSES_REPORT",
  DownloadMileageReport = "DOWNLOAD_MILEAGE_REPORT",
  DownloadThermalConsumptionReport = "DOWNLOAD_THERMAL_CONSUMPTION_REPORT",
  DownloadTid = "DOWNLOAD_TID",
  DownloadTransactions = "DOWNLOAD_TRANSACTIONS",
  DriverCodes = "DRIVER_CODES",
  FeatureUndefined = "FEATURE_UNDEFINED",
  ListLedgers = "LIST_LEDGERS",
  ListTransactions = "LIST_TRANSACTIONS",
  ManageCollaborators = "MANAGE_COLLABORATORS",
  ManageGuaranteePayment = "MANAGE_GUARANTEE_PAYMENT",
  ManageInvoicePayment = "MANAGE_INVOICE_PAYMENT",
  ManageLedgerAssignments = "MANAGE_LEDGER_ASSIGNMENTS",
  ManageLedgerRenewal = "MANAGE_LEDGER_RENEWAL",
  ManageRolesProfiles = "MANAGE_ROLES_PROFILES",
  ManageSectors = "MANAGE_SECTORS",
  ManageUsers = "MANAGE_USERS",
  ManageUserRolesProfiles = "MANAGE_USER_ROLES_PROFILES",
  ManageVehicles = "MANAGE_VEHICLES",
  PriceCeilingReport = "PRICE_CEILING_REPORT",
  ReportDetailedEvTransactions = "REPORT_DETAILED_EV_TRANSACTIONS",
  ResendPinCode = "RESEND_PIN_CODE",
  UpdateLedger = "UPDATE_LEDGER",
}

/**  An exhaustive list of user roles for the given account  */
export enum AccountsMsMeResponseCustomerUserPublicVisaRoles {
  RoleAdmin = "ROLE_ADMIN",
  RoleAlerts = "ROLE_ALERTS",
  RoleAssignmentCreate = "ROLE_ASSIGNMENT_CREATE",
  RoleAssignmentDelete = "ROLE_ASSIGNMENT_DELETE",
  RoleAssignmentUpdate = "ROLE_ASSIGNMENT_UPDATE",
  RoleAssignmentView = "ROLE_ASSIGNMENT_VIEW",
  RoleBilling = "ROLE_BILLING",
  RoleBillingInvoiceDownload = "ROLE_BILLING_INVOICE_DOWNLOAD",
  RoleBillingInvoiceManager = "ROLE_BILLING_INVOICE_MANAGER",
  RoleBillingInvoiceView = "ROLE_BILLING_INVOICE_VIEW",
  RoleBillingTidManager = "ROLE_BILLING_TID_MANAGER",
  RoleCollaboratorArchive = "ROLE_COLLABORATOR_ARCHIVE",
  RoleCollaboratorCreate = "ROLE_COLLABORATOR_CREATE",
  RoleCollaboratorDelete = "ROLE_COLLABORATOR_DELETE",
  RoleCollaboratorManager = "ROLE_COLLABORATOR_MANAGER",
  RoleCollaboratorUpdate = "ROLE_COLLABORATOR_UPDATE",
  RoleCollaboratorView = "ROLE_COLLABORATOR_VIEW",
  RoleContractManager = "ROLE_CONTRACT_MANAGER",
  RoleContractView = "ROLE_CONTRACT_VIEW",
  RoleDocumentEoyStatement = "ROLE_DOCUMENT_EOY_STATEMENT",
  RoleDocumentManager = "ROLE_DOCUMENT_MANAGER",
  RoleDriverCodeCreate = "ROLE_DRIVER_CODE_CREATE",
  RoleDriverCodeLink = "ROLE_DRIVER_CODE_LINK",
  RoleDriverCodeManager = "ROLE_DRIVER_CODE_MANAGER",
  RoleDriverCodeUpdate = "ROLE_DRIVER_CODE_UPDATE",
  RoleDriverCodeView = "ROLE_DRIVER_CODE_VIEW",
  RoleLedgers = "ROLE_LEDGERS",
  RoleLedgersLedgerBlock = "ROLE_LEDGERS_LEDGER_BLOCK",
  RoleLedgersLedgerCancelRenewal = "ROLE_LEDGERS_LEDGER_CANCEL_RENEWAL",
  RoleLedgersLedgerCreate = "ROLE_LEDGERS_LEDGER_CREATE",
  RoleLedgersLedgerManager = "ROLE_LEDGERS_LEDGER_MANAGER",
  RoleLedgersLedgerUpdate = "ROLE_LEDGERS_LEDGER_UPDATE",
  RoleLedgersLedgerView = "ROLE_LEDGERS_LEDGER_VIEW",
  RoleLedgersResendPinCode = "ROLE_LEDGERS_RESEND_PIN_CODE",
  RoleLedgersTransactionManager = "ROLE_LEDGERS_TRANSACTION_MANAGER",
  RoleLedgersTransactionView = "ROLE_LEDGERS_TRANSACTION_VIEW",
  RoleLedgersWalletManager = "ROLE_LEDGERS_WALLET_MANAGER",
  RoleLedgersWalletView = "ROLE_LEDGERS_WALLET_VIEW",
  RoleLedgerAssignment = "ROLE_LEDGER_ASSIGNMENT",
  RoleLedgerAssignmentManager = "ROLE_LEDGER_ASSIGNMENT_MANAGER",
  RoleMedia = "ROLE_MEDIA",
  RoleNotification = "ROLE_NOTIFICATION",
  RoleNotificationManager = "ROLE_NOTIFICATION_MANAGER",
  RoleNotificationSubscription = "ROLE_NOTIFICATION_SUBSCRIPTION",
  RoleReporting = "ROLE_REPORTING",
  RoleReportAdvanced = "ROLE_REPORT_ADVANCED",
  RoleReportBasic = "ROLE_REPORT_BASIC",
  RoleReportManager = "ROLE_REPORT_MANAGER",
  RoleRolesProfileCreate = "ROLE_ROLES_PROFILE_CREATE",
  RoleRolesProfileDelete = "ROLE_ROLES_PROFILE_DELETE",
  RoleRolesProfileLink = "ROLE_ROLES_PROFILE_LINK",
  RoleRolesProfileLinker = "ROLE_ROLES_PROFILE_LINKER",
  RoleRolesProfileManager = "ROLE_ROLES_PROFILE_MANAGER",
  RoleRolesProfileUnlink = "ROLE_ROLES_PROFILE_UNLINK",
  RoleRolesProfileUpdate = "ROLE_ROLES_PROFILE_UPDATE",
  RoleRolesProfileView = "ROLE_ROLES_PROFILE_VIEW",
  RoleSectorCreate = "ROLE_SECTOR_CREATE",
  RoleSectorDelete = "ROLE_SECTOR_DELETE",
  RoleSectorManager = "ROLE_SECTOR_MANAGER",
  RoleSectorUpdate = "ROLE_SECTOR_UPDATE",
  RoleSectorView = "ROLE_SECTOR_VIEW",
  RoleUndefined = "ROLE_UNDEFINED",
  RoleUserAccounts = "ROLE_USER_ACCOUNTS",
  RoleUserArchive = "ROLE_USER_ARCHIVE",
  RoleUserCreate = "ROLE_USER_CREATE",
  RoleUserDelete = "ROLE_USER_DELETE",
  RoleUserDuplicate = "ROLE_USER_DUPLICATE",
  RoleUserInvite = "ROLE_USER_INVITE",
  RoleUserManager = "ROLE_USER_MANAGER",
  RoleUserUpdate = "ROLE_USER_UPDATE",
  RoleUserView = "ROLE_USER_VIEW",
  RoleVehicle = "ROLE_VEHICLE",
  RoleVehicleArchive = "ROLE_VEHICLE_ARCHIVE",
  RoleVehicleCreate = "ROLE_VEHICLE_CREATE",
  RoleVehicleDelete = "ROLE_VEHICLE_DELETE",
  RoleVehicleManager = "ROLE_VEHICLE_MANAGER",
  RoleVehicleUpdate = "ROLE_VEHICLE_UPDATE",
  RoleVehicleView = "ROLE_VEHICLE_VIEW",
}

export type AccountsMsMeResponseSupportUser = {
  __typename?: "AccountsMsMeResponseSupportUser";
  /**  The support user's email  */
  email: Scalars["String"]["output"];
  /**  The support user's first name  */
  firstName: Scalars["String"]["output"];
  /**  The support user's igg  */
  igg: Scalars["String"]["output"];
  /**  The support user's last name  */
  lastName: Scalars["String"]["output"];
  /**  The global user rights  */
  rights?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type AccountsMsPagedPagination = {
  __typename?: "AccountsMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type AccountsMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AccountsMsReadRolesProfileRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsReadRolesProfileResponse = {
  __typename?: "AccountsMsReadRolesProfileResponse";
  data: AccountsMsRolesProfile;
  deletable: Scalars["Boolean"]["output"];
};

/**  Resend invitation  */
export type AccountsMsResendInviteUserNotificationRequest = {
  privilegeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsResendInviteUserNotificationResponse = {
  __typename?: "AccountsMsResendInviteUserNotificationResponse";
  /**  After how many seconds the invitation can be sent again, zero if the notification can be sent right away.  */
  retryableInSeconds: Scalars["Int64"]["output"];
  /**  If the invitation has been sent.  */
  sent: Scalars["Boolean"]["output"];
};

export type AccountsMsRolesProfile = {
  __typename?: "AccountsMsRolesProfile";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  ParentIdData?: Maybe<AccountsMsSearchRolesProfilesResponseData>;
  RootIdData?: Maybe<AccountsMsSearchRolesProfilesResponseData>;
  /**  An optional account id when the roles profiles belongs to an account  */
  accountId: Scalars["ID"]["output"];
  /**  The system code  */
  code: Scalars["String"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  The ID of a RolesProfile  */
  id: Scalars["ID"]["output"];
  /**  The roles profiles label  */
  name: Scalars["String"]["output"];
  /**  The parent ID of a RolesProfile  */
  parentId: Scalars["ID"]["output"];
  /**  The roles profiles roles. When the roles profiles is nested, the roles options are narrowed down to the parent roles.  */
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
  /**  The root of a RolesProfile when nested  */
  rootId: Scalars["ID"]["output"];
  updatedTs: Scalars["Int64"]["output"];
};

/**  Get the account information & preferences  */
export type AccountsMsSearchAccountsRequest = {
  filters?: InputMaybe<AccountsMsSearchAccountsRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<AccountsMsSearchAccountsRequestSort>>>;
};

export type AccountsMsSearchAccountsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  number?: InputMaybe<Scalars["String"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsSearchAccountsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchAccountsRequestSortField>;
};

export enum AccountsMsSearchAccountsRequestSortField {
  CreatedTs = "created_ts",
  Name = "name",
  Number = "number",
  PreferredLocale = "preferred_locale",
  RegistrationName = "registration_name",
}

export type AccountsMsSearchAccountsResponse = {
  __typename?: "AccountsMsSearchAccountsResponse";
  data?: Maybe<Array<AccountsMsSearchAccountsResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchAccountsResponseData = {
  __typename?: "AccountsMsSearchAccountsResponseData";
  CurrentContractIdData?: Maybe<AccountsMsSearchContractsResponseData>;
  MainPrivilegeIdData?: Maybe<AccountsMsSearchPrivilegesResponseData>;
  /**  The physical address of the customer  */
  address: Scalars["String"]["output"];
  /**  The physical city of the customer  */
  city: Scalars["String"]["output"];
  /**  The physical country of the customer encoded in ISO 3166-1 standard  */
  country: Scalars["String"]["output"];
  createdTs: Scalars["String"]["output"];
  /**  The current contract  */
  currentContractId: Scalars["ID"]["output"];
  /**  The ID of an account  */
  id: Scalars["ID"]["output"];
  isActive: Scalars["Boolean"]["output"];
  /**  The privilege of the main user, the owner of the account.  */
  mainPrivilegeId: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  /**  The internal customer code inside TotalEnergies  */
  number: Scalars["String"]["output"];
  /**  The pre-filled locale used to create users  */
  preferredLocale: Scalars["String"]["output"];
  /**  The pre-filled timezone used to create users  */
  preferredTimezone: Scalars["String"]["output"];
  /**  Also named "Raison sociale"  */
  registrationName: Scalars["String"]["output"];
  /**  The registration number of the legal entity.  */
  registrationNumber: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  /**  The physical zip code of the customer  */
  zipCode: Scalars["String"]["output"];
};

/**  Search Agencies  */
export type AccountsMsSearchAgenciesRequest = {
  filters?: InputMaybe<AccountsMsSearchAgenciesRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<AccountsMsSearchAgenciesRequestSort>>>;
};

export type AccountsMsSearchAgenciesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsSearchAgenciesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchAgenciesRequestSortField>;
};

export enum AccountsMsSearchAgenciesRequestSortField {
  Name = "name",
}

export type AccountsMsSearchAgenciesResponse = {
  __typename?: "AccountsMsSearchAgenciesResponse";
  data?: Maybe<Array<AccountsMsSearchAgenciesResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchAgenciesResponseData = {
  __typename?: "AccountsMsSearchAgenciesResponseData";
  createdTs: Scalars["Int64"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  subsidiaries?: Maybe<Array<Scalars["String"]["output"]>>;
  updatedTs: Scalars["Int64"]["output"];
};

/**  Search among contract status  */
export type AccountsMsSearchContractStatusesRequest = {
  filters?: InputMaybe<AccountsMsSearchContractStatusesRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AccountsMsSearchContractStatusesRequestSort>>
  >;
};

export type AccountsMsSearchContractStatusesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type AccountsMsSearchContractStatusesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchContractStatusesRequestSortField>;
};

export enum AccountsMsSearchContractStatusesRequestSortField {
  Code = "code",
}

export type AccountsMsSearchContractStatusesResponse = {
  __typename?: "AccountsMsSearchContractStatusesResponse";
  data?: Maybe<Array<AccountsMsSearchContractStatusesResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchContractStatusesResponseData = {
  __typename?: "AccountsMsSearchContractStatusesResponseData";
  /**  The code name  */
  code: Scalars["String"]["output"];
  /**  The ID of the contract status  */
  id: Scalars["ID"]["output"];
};

/**  Search among Contracts  */
export type AccountsMsSearchContractsRequest = {
  filters?: InputMaybe<AccountsMsSearchContractsRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<AccountsMsSearchContractsRequestSort>>>;
};

export type AccountsMsSearchContractsRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  customerAccountNumber?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsSearchContractsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchContractsRequestSortField>;
};

export enum AccountsMsSearchContractsRequestSortField {
  Reference = "reference",
}

export type AccountsMsSearchContractsResponse = {
  __typename?: "AccountsMsSearchContractsResponse";
  data?: Maybe<Array<AccountsMsSearchContractsResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchContractsResponseData = {
  __typename?: "AccountsMsSearchContractsResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  /**  The related account  */
  accountId: Scalars["String"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  The ID of a Contract  */
  id: Scalars["ID"]["output"];
  /**  True if the contract is active  */
  isActive: Scalars["Boolean"]["output"];
  /**  The last date when the contract were active  */
  lastActiveTs: Scalars["Int64"]["output"];
  /**  The reference of the offer  */
  offerCode: Scalars["String"]["output"];
  /**  The name of the offer  */
  offerName: Scalars["String"]["output"];
  /**  The list of products (card, badges or other supports), also name PCOM in MSA  */
  pcomList?: Maybe<Array<Scalars["String"]["output"]>>;
  /**  The reference of a contract (contract number)  */
  reference: Scalars["String"]["output"];
  /**  The reference of the contract in salsa (contract id)  */
  revision: Scalars["String"]["output"];
  /**  The list of commercial services, also name SCOM in MSA  */
  scomList?: Maybe<Array<Scalars["String"]["output"]>>;
  updatedTs: Scalars["Int64"]["output"];
};

/**  Search among offers  */
export type AccountsMsSearchOffersRequest = {
  filters?: InputMaybe<AccountsMsSearchOffersRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<AccountsMsSearchOffersRequestSort>>>;
};

export type AccountsMsSearchOffersRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type AccountsMsSearchOffersRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchOffersRequestSortField>;
};

export enum AccountsMsSearchOffersRequestSortField {
  Name = "name",
}

export type AccountsMsSearchOffersResponse = {
  __typename?: "AccountsMsSearchOffersResponse";
  data?: Maybe<Array<AccountsMsSearchOffersResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchOffersResponseData = {
  __typename?: "AccountsMsSearchOffersResponseData";
  /**  The ID of the offer  */
  id: Scalars["ID"]["output"];
  /**  The offer name  */
  name: Scalars["String"]["output"];
};

/**  Search among current user's privileges  */
export type AccountsMsSearchOwnPrivilegesRequest = {
  filters?: InputMaybe<AccountsMsSearchOwnPrivilegesRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AccountsMsSearchOwnPrivilegesRequestSort>>
  >;
};

export type AccountsMsSearchOwnPrivilegesRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type AccountsMsSearchOwnPrivilegesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchOwnPrivilegesRequestSortField>;
};

export enum AccountsMsSearchOwnPrivilegesRequestSortField {
  AccountId = "account_id",
  Id = "id",
}

export type AccountsMsSearchOwnPrivilegesResponse = {
  __typename?: "AccountsMsSearchOwnPrivilegesResponse";
  data?: Maybe<Array<AccountsMsSearchOwnPrivilegesResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchOwnPrivilegesResponseData = {
  __typename?: "AccountsMsSearchOwnPrivilegesResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  InvitedByIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  Subscriptions?: Maybe<
    Array<Maybe<AccountsMsSearchPrivilegeSubscriptionsResponseData>>
  >;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  /**
   *  When the given privileged has been revoked, used when we want to deactivate a privilege.
   * Gives the info that the privilege is disabled or enabled
   */
  disabledTs: Scalars["Int64"]["output"];
  /**
   *  Set when
   * - we activate a privilege,
   * - the user access an account for the first time after being invited.
   * - the user has been imported
   */
  enabledLastTs: Scalars["Int64"]["output"];
  /**  The ID of a Privilege  */
  id: Scalars["ID"]["output"];
  /**  The user who created this privilege (if there is such)  */
  invitedById: Scalars["ID"]["output"];
  /**  Set when the user has been invited on the account along with the inviter.  */
  invitedTs: Scalars["Int64"]["output"];
  /**  When the privilege has been used for the last time.  */
  lastUsedTs: Scalars["Int64"]["output"];
  owner: Scalars["Boolean"]["output"];
  /**  The factorized privileges  */
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
  status: AccountsMsSearchOwnPrivilegesResponseDataStatus;
};

export enum AccountsMsSearchOwnPrivilegesResponseDataStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Inactive = "INACTIVE",
  Inviting = "INVITING",
  PrivilegeStatusUnknown = "PRIVILEGE_STATUS_UNKNOWN",
  Suspended = "SUSPENDED",
}

/**  Search among user-account privileges  */
export type AccountsMsSearchPlatformAccessesRequest = {
  filters?: InputMaybe<AccountsMsSearchPlatformAccessesRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AccountsMsSearchPlatformAccessesRequestSort>>
  >;
};

export type AccountsMsSearchPlatformAccessesRequestFilters = {
  cognitoIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gigyaIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  iggs?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type AccountsMsSearchPlatformAccessesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchPlatformAccessesRequestSortField>;
};

export enum AccountsMsSearchPlatformAccessesRequestSortField {
  Id = "id",
}

export type AccountsMsSearchPlatformAccessesResponse = {
  __typename?: "AccountsMsSearchPlatformAccessesResponse";
  data?: Maybe<Array<AccountsMsSearchPlatformAccessesResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchPlatformAccessesResponseData = {
  __typename?: "AccountsMsSearchPlatformAccessesResponseData";
  /**  The JWT subject provided by Cognito.  */
  cognitoId: Scalars["String"]["output"];
  /**  TE Connect  */
  gigyaId: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /**  Digital Pass identifier  */
  igg: Scalars["String"]["output"];
  /**  The last time the platform has been accessed with.  */
  lastAccessTs: Scalars["Int64"]["output"];
  /**  When the access has been or will be suspended, 0 when the access is not suspended.  */
  suspendedTs: Scalars["Int64"]["output"];
};

/**  Search privilege restrictions  */
export type AccountsMsSearchPrivilegeRestrictionsRequest = {
  filters?: InputMaybe<AccountsMsSearchPrivilegeRestrictionsRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AccountsMsSearchPrivilegeRestrictionsRequestSort>>
  >;
};

export type AccountsMsSearchPrivilegeRestrictionsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  privilegeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  showAll?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AccountsMsSearchPrivilegeRestrictionsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchPrivilegeRestrictionsRequestSortField>;
};

export enum AccountsMsSearchPrivilegeRestrictionsRequestSortField {
  FromTs = "from_ts",
  Id = "id",
  SectorId = "sector_id",
}

export type AccountsMsSearchPrivilegeRestrictionsResponse = {
  __typename?: "AccountsMsSearchPrivilegeRestrictionsResponse";
  data?: Maybe<Array<AccountsMsSearchPrivilegeRestrictionsResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchPrivilegeRestrictionsResponseData = {
  __typename?: "AccountsMsSearchPrivilegeRestrictionsResponseData";
  PrivilegeIdData?: Maybe<AccountsMsSearchPrivilegesResponseData>;
  SectorIdData?: Maybe<SectorsMsSearchSectorsResponseData>;
  /**  The restriction starting date  */
  fromTs: Scalars["Int64"]["output"];
  /**  The restriction id  */
  id: Scalars["ID"]["output"];
  /**  The related privilege  */
  privilegeId: Scalars["ID"]["output"];
  /**  The related sector  */
  sectorId: Scalars["ID"]["output"];
  /**  When the restriction ended. It is kept empty while the restriction is active.  */
  tillTs: Scalars["Int64"]["output"];
};

/**  Search among privilege subscriptions  */
export type AccountsMsSearchPrivilegeSubscriptionsRequest = {
  filters?: InputMaybe<AccountsMsSearchPrivilegeSubscriptionsRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AccountsMsSearchPrivilegeSubscriptionsRequestSort>>
  >;
};

export type AccountsMsSearchPrivilegeSubscriptionsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  privilegeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  rolesProfileIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /**
   *  Only active subscriptions are retrieved by default (at the current time).
   * This filter allows to get both active and inactive subscriptions
   */
  showAll?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AccountsMsSearchPrivilegeSubscriptionsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchPrivilegeSubscriptionsRequestSortField>;
};

export enum AccountsMsSearchPrivilegeSubscriptionsRequestSortField {
  Id = "id",
  RolesProfile = "roles_profile",
  UpdatedTs = "updated_ts",
}

export type AccountsMsSearchPrivilegeSubscriptionsResponse = {
  __typename?: "AccountsMsSearchPrivilegeSubscriptionsResponse";
  data?: Maybe<Array<AccountsMsSearchPrivilegeSubscriptionsResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchPrivilegeSubscriptionsResponseData = {
  __typename?: "AccountsMsSearchPrivilegeSubscriptionsResponseData";
  PrivilegeIdData?: Maybe<AccountsMsSearchPrivilegesResponseData>;
  RolesProfileIdData?: Maybe<AccountsMsSearchRolesProfilesResponseData>;
  /**  The equivalent of a created date  */
  fromTs: Scalars["Int64"]["output"];
  /**  The subscription id  */
  id: Scalars["ID"]["output"];
  /**  The related privilege  */
  privilegeId: Scalars["ID"]["output"];
  /**  The related roles profile  */
  rolesProfileId: Scalars["ID"]["output"];
  /**  When the subscription ended, empty if the subscription is still active (future dates are not supported).  */
  tillTs: Scalars["Int64"]["output"];
};

/**  Search among user-account privileges  */
export type AccountsMsSearchPrivilegesRequest = {
  filters?: InputMaybe<AccountsMsSearchPrivilegesRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<AccountsMsSearchPrivilegesRequestSort>>>;
};

export type AccountsMsSearchPrivilegesRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  activeRolesProfileIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  ownersOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  query?: InputMaybe<AccountsMsSearchPrivilegesRequestFiltersQuery>;
  showDisabledUsers?: InputMaybe<Scalars["Boolean"]["input"]>;
  showEnabledUsers?: InputMaybe<Scalars["Boolean"]["input"]>;
  showInvitedUsers?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsSearchPrivilegesRequestFiltersQuery = {
  account?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsSearchPrivilegesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchPrivilegesRequestSortField>;
};

export enum AccountsMsSearchPrivilegesRequestSortField {
  AccountId = "account_id",
  Id = "id",
  UserId = "user_id",
}

export type AccountsMsSearchPrivilegesResponse = {
  __typename?: "AccountsMsSearchPrivilegesResponse";
  data?: Maybe<Array<AccountsMsSearchPrivilegesResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchPrivilegesResponseData = {
  __typename?: "AccountsMsSearchPrivilegesResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  InvitedByIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  Subscriptions?: Maybe<
    Array<Maybe<AccountsMsSearchPrivilegeSubscriptionsResponseData>>
  >;
  UserIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  /**
   *  When the given privileged has been revoked, used when we want to deactivate a privilege.
   * Gives the info that the privilege is disabled or enabled
   */
  disabledTs: Scalars["Int64"]["output"];
  /**
   *  Set when
   * - we activate a privilege,
   * - the user access an account for the first time after being invited.
   * - the user has been imported
   */
  enabledLastTs: Scalars["Int64"]["output"];
  /**  The ID of a Privilege  */
  id: Scalars["ID"]["output"];
  /**  The user who created this privilege (if there is such)  */
  invitedById: Scalars["ID"]["output"];
  /**  Set when the user has been invited on the account along with the inviter.  */
  invitedTs: Scalars["Int64"]["output"];
  /**  When the privilege has been used for the last time.  */
  lastUsedTs: Scalars["Int64"]["output"];
  owner: Scalars["Boolean"]["output"];
  /**  The factorized privileges  */
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
  /**  Set when the roles should be computed again  */
  rolesInvalidatedTs: Scalars["Int64"]["output"];
  status: AccountsMsSearchPrivilegesResponseDataStatus;
  /**  The related user  */
  userId: Scalars["ID"]["output"];
};

export enum AccountsMsSearchPrivilegesResponseDataStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Inactive = "INACTIVE",
  Inviting = "INVITING",
  PrivilegeStatusUnknown = "PRIVILEGE_STATUS_UNKNOWN",
  Suspended = "SUSPENDED",
}

/**  Search among roles profiles  */
export type AccountsMsSearchRolesProfilesRequest = {
  filters?: InputMaybe<AccountsMsSearchRolesProfilesRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AccountsMsSearchRolesProfilesRequestSort>>
  >;
};

export type AccountsMsSearchRolesProfilesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type AccountsMsSearchRolesProfilesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchRolesProfilesRequestSortField>;
};

export enum AccountsMsSearchRolesProfilesRequestSortField {
  Name = "name",
}

export type AccountsMsSearchRolesProfilesResponse = {
  __typename?: "AccountsMsSearchRolesProfilesResponse";
  data?: Maybe<Array<AccountsMsSearchRolesProfilesResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchRolesProfilesResponseData = {
  __typename?: "AccountsMsSearchRolesProfilesResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  ParentIdData?: Maybe<AccountsMsSearchRolesProfilesResponseData>;
  RootIdData?: Maybe<AccountsMsSearchRolesProfilesResponseData>;
  Subscriptions?: Maybe<
    Array<Maybe<AccountsMsSearchPrivilegeSubscriptionsResponseData>>
  >;
  /**  An optional account id when the roles profiles belongs to an account  */
  accountId: Scalars["String"]["output"];
  /**
   *  Only present for system roles profile
   * see fixtures to know which value you'll have
   */
  code: Scalars["String"]["output"];
  /**  The ID of a RolesProfile  */
  id: Scalars["ID"]["output"];
  /**  The roles profiles label  */
  name: Scalars["String"]["output"];
  /**
   *  fixme:
   * As we can not bind CountUsersInRolesProfile with a graphql relation
   * we have decided to add this attribute on search
   * @Pierre please, do something
   */
  numberOfUsers: AccountsMsSearchRolesProfilesResponseDataNumberOfUsers;
  /**  The parent ID of a RolesProfile  */
  parentId: Scalars["String"]["output"];
  /**  The roles profiles roles. When the roles profiles is nested, the roles options are narrowed down to the parent roles.  */
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
  /**  The root of a RolesProfile when nested  */
  rootId: Scalars["String"]["output"];
  system: Scalars["Boolean"]["output"];
};

/**
 *  fixme:
 * As we can not bind CountUsersInRolesProfile with a graphql relation
 * we have decided to add this attribute on search
 * @Pierre please, do something
 */
export type AccountsMsSearchRolesProfilesResponseDataNumberOfUsers = {
  __typename?: "AccountsMsSearchRolesProfilesResponseDataNumberOfUsers";
  disabled: Scalars["Int64"]["output"];
  enabled: Scalars["Int64"]["output"];
  inviting: Scalars["Int64"]["output"];
  total: Scalars["Int64"]["output"];
};

/**  Search among support users  */
export type AccountsMsSearchSupportUsersRequest = {
  filters?: InputMaybe<AccountsMsSearchSupportUsersRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AccountsMsSearchSupportUsersRequestSort>>
  >;
};

export type AccountsMsSearchSupportUsersRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  igg?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AccountsMsSearchSupportUsersRequestFiltersStatus>;
};

export enum AccountsMsSearchSupportUsersRequestFiltersStatus {
  StatusUnknown = "STATUS_UNKNOWN",
  SupportUserDisabled = "SUPPORT_USER_DISABLED",
  SupportUserEnabled = "SUPPORT_USER_ENABLED",
  SupportUserInviting = "SUPPORT_USER_INVITING",
}

export type AccountsMsSearchSupportUsersRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchSupportUsersRequestSortField>;
};

export enum AccountsMsSearchSupportUsersRequestSortField {
  Email = "email",
  Id = "id",
  Igg = "igg",
  LastName = "lastName",
  Profile = "profile",
}

export type AccountsMsSearchSupportUsersResponse = {
  __typename?: "AccountsMsSearchSupportUsersResponse";
  data?: Maybe<Array<AccountsMsSearchSupportUsersResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchSupportUsersResponseData = {
  __typename?: "AccountsMsSearchSupportUsersResponseData";
  AccessIdData?: Maybe<AccountsMsSearchPlatformAccessesResponseData>;
  InvitedByData?: Maybe<AccountsMsSearchSupportUsersResponseData>;
  /**  The related access  */
  accessId: Scalars["ID"]["output"];
  createdTs: Scalars["Int64"]["output"];
  disabledTs: Scalars["Int64"]["output"];
  /**  The user email address  */
  email: Scalars["String"]["output"];
  /**  The first name  */
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  invitedBy: Scalars["String"]["output"];
  /**  The functions of the user  */
  jobTitle: Scalars["String"]["output"];
  /**  The last name  */
  lastName: Scalars["String"]["output"];
  profile: AccountsMsSearchSupportUsersResponseDataProfile;
  /**  The global rights of a user, despite of its accounts.  */
  rights?: Maybe<Array<Scalars["String"]["output"]>>;
  status: AccountsMsSearchSupportUsersResponseDataStatus;
};

export enum AccountsMsSearchSupportUsersResponseDataProfile {
  Agent = "AGENT",
  ProfileUnknown = "PROFILE_UNKNOWN",
  SuperAgent = "SUPER_AGENT",
}

export enum AccountsMsSearchSupportUsersResponseDataStatus {
  StatusUnknown = "STATUS_UNKNOWN",
  SupportUserDisabled = "SUPPORT_USER_DISABLED",
  SupportUserEnabled = "SUPPORT_USER_ENABLED",
  SupportUserInviting = "SUPPORT_USER_INVITING",
}

/**  Search among users  */
export type AccountsMsSearchUsersRequest = {
  filters?: InputMaybe<AccountsMsSearchUsersRequestFilters>;
  paging?: InputMaybe<AccountsMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<AccountsMsSearchUsersRequestSort>>>;
};

export type AccountsMsSearchUsersRequestFilters = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /**  Find a user using multiple criteria  */
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsSearchUsersRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AccountsMsSearchUsersRequestSortField>;
};

export enum AccountsMsSearchUsersRequestSortField {
  Email = "email",
  FirstName = "first_name",
  Id = "id",
  LastName = "last_name",
}

export type AccountsMsSearchUsersResponse = {
  __typename?: "AccountsMsSearchUsersResponse";
  data?: Maybe<Array<AccountsMsSearchUsersResponseData>>;
  pagination: AccountsMsPagedPagination;
};

export type AccountsMsSearchUsersResponseData = {
  __typename?: "AccountsMsSearchUsersResponseData";
  AccessIdData?: Maybe<AccountsMsSearchPlatformAccessesResponseData>;
  DefaultAccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  /**  The related access  */
  accessId: Scalars["ID"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  The related account  */
  defaultAccountId: Scalars["String"]["output"];
  /**  When the user received a deletion reminder  */
  deletedReminderSentTs: Scalars["Int64"]["output"];
  /**  When the user is/was scheduled for deletion  */
  deletedScheduledTs: Scalars["Int64"]["output"];
  /**  When the user was deleted  */
  deletedTs: Scalars["Int64"]["output"];
  /**  The user email address  */
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  /**  The ID of a User  */
  id: Scalars["ID"]["output"];
  /**  The functions of the user  */
  jobTitle: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  /**  The user's language-underscore-country following respectively the ISO 639-1 and ISO 3166-1 standards.  */
  locale: Scalars["String"]["output"];
  /**  The phone number of the user, prefixed with the country code (following E164 standard)  */
  phone: Scalars["String"]["output"];
  /**  The alternative phone number of the user, prefixed with the country code (following E164 standard)  */
  phoneAlt: Scalars["String"]["output"];
  /**  The last time of the user's profile shared data were updated.  */
  sharedUserProfileTs: Scalars["Int64"]["output"];
  /**  The user's timezone  */
  timezone: Scalars["String"]["output"];
};

/**  Defines the main account of the current user.  */
export type AccountsMsSetDefaultAccountRequest = {
  /**  An account id among the list of user's accounts  */
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 *  Start a support session
 *
 * Start a session for a support operator, a session id will be created for a defined duration
 */
export type AccountsMsStartSupportRequest = {
  /**  The user id concerned by the support session  */
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AccountsMsStartSupportResponse = {
  __typename?: "AccountsMsStartSupportResponse";
  /**  When the code expires  */
  expiresTs: Scalars["Int64"]["output"];
  /**  The session id  */
  sessionId: Scalars["ID"]["output"];
};

/**  Link the user's privilege to a roles profile  */
export type AccountsMsSubscribePrivilegeRequest = {
  privilegeId?: InputMaybe<Scalars["ID"]["input"]>;
  rolesProfileId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   *  Ask to unsubscribe to all other roles profiles
   * and keep only the one we subscribed for
   */
  unsubscribeAllOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**  Unlink user's privilege from from a roles profile  */
export type AccountsMsUnsubscribePrivilegeRequest = {
  subscriptionId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Update the current user  */
export type AccountsMsUpdateMeRequest = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  /**  The user's language-underscore-country following respectively the ISO 639-1 and ISO 3166-1 standards.  */
  locale?: InputMaybe<Scalars["String"]["input"]>;
  /**  The phone number of the user, prefixed with the country code (following E164 standard)  */
  phone?: InputMaybe<Scalars["String"]["input"]>;
  /**  The alternative phone number of the user, prefixed with the country code (following E164 standard)  */
  phoneAlt?: InputMaybe<Scalars["String"]["input"]>;
  /**  The user's timezone  */
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsUpdateMyPersonalDataRequest = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  /**  The phone number of the user, prefixed with the country code (following E164 standard)  */
  phone?: InputMaybe<Scalars["String"]["input"]>;
  /**  The alternative phone number of the user, prefixed with the country code (following E164 standard)  */
  phoneAlt?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsUpdateMyPersonalPreferencesRequest = {
  /**  The user's language-underscore-country following respectively the ISO 639-1 and ISO 3166-1 standards.  */
  locale?: InputMaybe<Scalars["String"]["input"]>;
  /**  The user's timezone  */
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountsMsUpdateRolesProfileRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  roles?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type AccountsMsUpdateRolesProfileResponse = {
  __typename?: "AccountsMsUpdateRolesProfileResponse";
  data: AccountsMsRolesProfile;
};

/**  Update support user. They must be active in order to be updated.  */
export type AccountsMsUpdateSupportUserRequest = {
  /**  The first name  */
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /**  The id of the support user  */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The functions of the user  */
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  /**  The last name  */
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *  The profile of the user
   * Be aware that a super agent can downgrade themselves
   */
  profile?: InputMaybe<AccountsMsUpdateSupportUserRequestProfile>;
};

/**
 *  The profile of the user
 * Be aware that a super agent can downgrade themselves
 */
export enum AccountsMsUpdateSupportUserRequestProfile {
  Agent = "AGENT",
  ProfileUnknown = "PROFILE_UNKNOWN",
  SuperAgent = "SUPER_AGENT",
}

/**  Assign a collaborator to a ledger  */
export type AssignmentsMsAssignCollaboratorRequest = {
  /**  The collaborator id  */
  collaboratorId?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The timespan of the start of the assignment  */
  fromTs?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  The ledger id  */
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The timespan of the end of the assignment  */
  tillTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type AssignmentsMsAssignCollaboratorResponse = {
  __typename?: "AssignmentsMsAssignCollaboratorResponse";
  /**  The created assignment id  */
  id: Scalars["ID"]["output"];
};

/**  Assign a vehicle to a ledger  */
export type AssignmentsMsAssignVehicleRequest = {
  /**  The timespan of the start of the assignment  */
  fromTs?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  The ledger id  */
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The timespan of the end of the assignment  */
  tillTs?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  The vehicle id  */
  vehicleId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AssignmentsMsAssignVehicleResponse = {
  __typename?: "AssignmentsMsAssignVehicleResponse";
  /**  The created assignment id  */
  id: Scalars["ID"]["output"];
};

/**  Count assignments by status  */
export type AssignmentsMsCountAssignmentsByStatusRequest = {
  /**  The ledger id  */
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AssignmentsMsCountAssignmentsByStatusResponse = {
  __typename?: "AssignmentsMsCountAssignmentsByStatusResponse";
  collaborators: AssignmentsMsCountAssignmentsByStatusResponseTypeAssignmentsCount;
  vehicles: AssignmentsMsCountAssignmentsByStatusResponseTypeAssignmentsCount;
};

export type AssignmentsMsCountAssignmentsByStatusResponseTypeAssignmentsCount =
  {
    __typename?: "AssignmentsMsCountAssignmentsByStatusResponseTypeAssignmentsCount";
    all: Scalars["Int64"]["output"];
    archived: Scalars["Int64"]["output"];
    current: Scalars["Int64"]["output"];
    future: Scalars["Int64"]["output"];
  };

/**  Delete assignment  */
export type AssignmentsMsDeleteAssignmentRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Check if collaborator assignment is available for ledger  */
export type AssignmentsMsIsCollaboratorAssignmentAvailableRequest = {
  /**  The ledger id  */
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AssignmentsMsIsCollaboratorAssignmentAvailableResponse = {
  __typename?: "AssignmentsMsIsCollaboratorAssignmentAvailableResponse";
  available: Scalars["Boolean"]["output"];
};

export type AssignmentsMsPagedPagination = {
  __typename?: "AssignmentsMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type AssignmentsMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Search among collaborator assignments  */
export type AssignmentsMsSearchCollaboratorAssignmentsRequest = {
  filters?: InputMaybe<AssignmentsMsSearchCollaboratorAssignmentsRequestFilters>;
  paging?: InputMaybe<AssignmentsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AssignmentsMsSearchCollaboratorAssignmentsRequestSort>>
  >;
};

export type AssignmentsMsSearchCollaboratorAssignmentsRequestFilters = {
  /**  Find assignments around a period (that might overlap the period)  */
  around?: InputMaybe<AssignmentsMsSearchCollaboratorAssignmentsRequestFiltersAround>;
  collaboratorId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Find assignments around a period (that might overlap the period)  */
export type AssignmentsMsSearchCollaboratorAssignmentsRequestFiltersAround = {
  fromTs?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  When not provided, it is considered infinite  */
  tillTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type AssignmentsMsSearchCollaboratorAssignmentsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AssignmentsMsSearchCollaboratorAssignmentsRequestSortField>;
};

export enum AssignmentsMsSearchCollaboratorAssignmentsRequestSortField {
  CollaboratorId = "collaborator_id",
  FromTs = "from_ts",
  Id = "id",
  LedgerId = "ledger_id",
  TillTs = "till_ts",
  UpdatedTs = "updated_ts",
}

export type AssignmentsMsSearchCollaboratorAssignmentsResponse = {
  __typename?: "AssignmentsMsSearchCollaboratorAssignmentsResponse";
  data?: Maybe<Array<AssignmentsMsSearchCollaboratorAssignmentsResponseData>>;
  pagination: AssignmentsMsPagedPagination;
};

export type AssignmentsMsSearchCollaboratorAssignmentsResponseData = {
  __typename?: "AssignmentsMsSearchCollaboratorAssignmentsResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  CollaboratorIdData?: Maybe<CollaboratorsMsSearchCollaboratorsResponseData>;
  LedgerIdData?: Maybe<LedgersMsSearchLedgersResponseData>;
  accountId: Scalars["ID"]["output"];
  /**  The collaborator id  */
  collaboratorId: Scalars["ID"]["output"];
  /**  The ID of the assignment  */
  id: Scalars["ID"]["output"];
  /**  The ledger id  */
  ledgerId: Scalars["ID"]["output"];
  period: AssignmentsMsSearchCollaboratorAssignmentsResponseDataPeriod;
  status: AssignmentsMsSearchCollaboratorAssignmentsResponseDataStatus;
};

export type AssignmentsMsSearchCollaboratorAssignmentsResponseDataPeriod = {
  __typename?: "AssignmentsMsSearchCollaboratorAssignmentsResponseDataPeriod";
  fromTs: Scalars["Int64"]["output"];
  tillTs: Scalars["Int64"]["output"];
};

export enum AssignmentsMsSearchCollaboratorAssignmentsResponseDataStatus {
  AssignmentStatusUnknown = "ASSIGNMENT_STATUS_UNKNOWN",
  InComing = "IN_COMING",
  InProgress = "IN_PROGRESS",
  Passed = "PASSED",
}

/**  Search among vehicle assignments  */
export type AssignmentsMsSearchVehicleAssignmentsRequest = {
  filters?: InputMaybe<AssignmentsMsSearchVehicleAssignmentsRequestFilters>;
  paging?: InputMaybe<AssignmentsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<AssignmentsMsSearchVehicleAssignmentsRequestSort>>
  >;
};

export type AssignmentsMsSearchVehicleAssignmentsRequestFilters = {
  /**  Find assignments around a period (that might overlap the period)  */
  around?: InputMaybe<AssignmentsMsSearchVehicleAssignmentsRequestFiltersAround>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
  vehicleId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Find assignments around a period (that might overlap the period)  */
export type AssignmentsMsSearchVehicleAssignmentsRequestFiltersAround = {
  fromTs?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  When not provided, it is considered infinite  */
  tillTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type AssignmentsMsSearchVehicleAssignmentsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<AssignmentsMsSearchVehicleAssignmentsRequestSortField>;
};

export enum AssignmentsMsSearchVehicleAssignmentsRequestSortField {
  FromTs = "from_ts",
  Id = "id",
  LedgerId = "ledger_id",
  TillTs = "till_ts",
  UpdatedTs = "updated_ts",
  VehicleId = "vehicle_id",
}

export type AssignmentsMsSearchVehicleAssignmentsResponse = {
  __typename?: "AssignmentsMsSearchVehicleAssignmentsResponse";
  data?: Maybe<Array<AssignmentsMsSearchVehicleAssignmentsResponseData>>;
  pagination: AssignmentsMsPagedPagination;
};

export type AssignmentsMsSearchVehicleAssignmentsResponseData = {
  __typename?: "AssignmentsMsSearchVehicleAssignmentsResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  LedgerIdData?: Maybe<LedgersMsSearchLedgersResponseData>;
  VehicleIdData?: Maybe<VehiclesMsSearchVehiclesResponseData>;
  accountId: Scalars["ID"]["output"];
  /**  The ID of the assignment  */
  id: Scalars["ID"]["output"];
  /**  The ledger id  */
  ledgerId: Scalars["ID"]["output"];
  period: AssignmentsMsSearchVehicleAssignmentsResponseDataPeriod;
  status: AssignmentsMsSearchVehicleAssignmentsResponseDataStatus;
  /**  The vehicle id  */
  vehicleId: Scalars["ID"]["output"];
};

export type AssignmentsMsSearchVehicleAssignmentsResponseDataPeriod = {
  __typename?: "AssignmentsMsSearchVehicleAssignmentsResponseDataPeriod";
  fromTs: Scalars["Int64"]["output"];
  tillTs: Scalars["Int64"]["output"];
};

export enum AssignmentsMsSearchVehicleAssignmentsResponseDataStatus {
  AssignmentStatusUnknown = "ASSIGNMENT_STATUS_UNKNOWN",
  InComing = "IN_COMING",
  InProgress = "IN_PROGRESS",
  Passed = "PASSED",
}

/**  Update assignment dates  */
export type AssignmentsMsUpdatePeriodRequest = {
  /**  The assignment id  */
  assignmentId?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The new timespan of the start of the assignment  */
  fromTs?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  The new timespan of the end of the assignment  */
  tillTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

/**  Commit a payment  */
export type BillingMsCommitPaymentRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Create a guarantee payment  */
export type BillingMsCreateGuaranteePaymentRequest = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type BillingMsCreateGuaranteePaymentResponse = {
  __typename?: "BillingMsCreateGuaranteePaymentResponse";
  amount: Scalars["Float"]["output"];
  creationDateTs: Scalars["Int64"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  monext: BillingMsCreateGuaranteePaymentResponseMonext;
  reference: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type BillingMsCreateGuaranteePaymentResponseMonext = {
  __typename?: "BillingMsCreateGuaranteePaymentResponseMonext";
  code: Scalars["String"]["output"];
  sessionId: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

/**  Create a invoice payment  */
export type BillingMsCreateInvoicePaymentRequest = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  documentList?: InputMaybe<
    Array<InputMaybe<BillingMsCreateInvoicePaymentRequestDocument>>
  >;
  dueDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type BillingMsCreateInvoicePaymentRequestDocument = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  dateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  dueDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  fees?: InputMaybe<Scalars["Float"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
  subDocumentList?: InputMaybe<
    Array<InputMaybe<BillingMsCreateInvoicePaymentRequestDocumentSubDocument>>
  >;
};

export type BillingMsCreateInvoicePaymentRequestDocumentSubDocument = {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  dateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  dueDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  externalId?: InputMaybe<Scalars["String"]["input"]>;
  fees?: InputMaybe<Scalars["Float"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
};

export type BillingMsCreateInvoicePaymentResponse = {
  __typename?: "BillingMsCreateInvoicePaymentResponse";
  amount: Scalars["Float"]["output"];
  creationDateTs: Scalars["Int64"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  monext: BillingMsCreateInvoicePaymentResponseMonext;
  reference: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type BillingMsCreateInvoicePaymentResponseMonext = {
  __typename?: "BillingMsCreateInvoicePaymentResponseMonext";
  code: Scalars["String"]["output"];
  sessionId: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

/**  Create a Tid report.  */
export type BillingMsCreateTidReportRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type BillingMsCreateTidReportResponse = {
  __typename?: "BillingMsCreateTidReportResponse";
  /**  The id of the report  */
  reportId: Scalars["ID"]["output"];
  /**  The id of the task  */
  taskId: Scalars["ID"]["output"];
};

export type BillingMsGetCustomerCreditStateResponse = {
  __typename?: "BillingMsGetCustomerCreditStateResponse";
  CustomerCreditFrequencyIdData?: Maybe<BillingMsSearchCustomerCreditFrequenciesResponseData>;
  customerCreditConsumption: Scalars["Float"]["output"];
  customerCreditFrequencyId: Scalars["ID"]["output"];
  customerCreditLastTs: Scalars["Int64"]["output"];
  customerCreditLimit: Scalars["Float"]["output"];
  customerCreditOutstanding: Scalars["Float"]["output"];
  customerCreditRenewTs: Scalars["Int64"]["output"];
  customerCreditWarning: BillingMsGetCustomerCreditStateResponseCustomerCreditWarning;
  hasBlockingCustomerCreditLimit: Scalars["Boolean"]["output"];
};

export enum BillingMsGetCustomerCreditStateResponseCustomerCreditWarning {
  Alert = "ALERT",
  CustomerCreditWarningUnknown = "CUSTOMER_CREDIT_WARNING_UNKNOWN",
  None = "NONE",
  Warning = "WARNING",
}

export type BillingMsGetLastInvoiceResponse = {
  __typename?: "BillingMsGetLastInvoiceResponse";
  AccountIdData?: Maybe<BillingMsSearchBillingAccountPreferencesResponseData>;
  DocumentIdData?: Maybe<MediaMsSearchDocumentsResponseData>;
  accountId: Scalars["ID"]["output"];
  amountCurrency: Scalars["String"]["output"];
  amountIncludingVat: Scalars["Float"]["output"];
  /**  The document is available if not zero  */
  availableCheckedTs: Scalars["Int64"]["output"];
  dateTs: Scalars["Int64"]["output"];
  documentId: Scalars["ID"]["output"];
  dueDateTs: Scalars["Int64"]["output"];
  hasLastInvoice: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  invoiceCode: BillingMsGetLastInvoiceResponseInvoiceCode;
  number: Scalars["String"]["output"];
  recipientAccountNumber: Scalars["String"]["output"];
};

export enum BillingMsGetLastInvoiceResponseInvoiceCode {
  Fcli = "FCLI",
  Rlvp = "RLVP",
  Unknown = "UNKNOWN",
}

/**  Create a invoice payment  */
export type BillingMsListOpenPaymentDocumentsRequest = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BillingMsListOpenPaymentDocumentsResponse = {
  __typename?: "BillingMsListOpenPaymentDocumentsResponse";
  data?: Maybe<Array<BillingMsListOpenPaymentDocumentsResponseDocument>>;
};

export type BillingMsListOpenPaymentDocumentsResponseDocument = {
  __typename?: "BillingMsListOpenPaymentDocumentsResponseDocument";
  amount: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  dateTs: Scalars["Int64"]["output"];
  dueDateTs: Scalars["Int64"]["output"];
  externalId: Scalars["String"]["output"];
  fees: Scalars["Float"]["output"];
  number: Scalars["String"]["output"];
  subDocumentList?: Maybe<
    Array<BillingMsListOpenPaymentDocumentsResponseDocumentSubDocument>
  >;
};

export type BillingMsListOpenPaymentDocumentsResponseDocumentSubDocument = {
  __typename?: "BillingMsListOpenPaymentDocumentsResponseDocumentSubDocument";
  amount: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  dateTs: Scalars["Int64"]["output"];
  dueDateTs: Scalars["Int64"]["output"];
  externalId: Scalars["String"]["output"];
  fees: Scalars["Float"]["output"];
  number: Scalars["String"]["output"];
};

export type BillingMsPagedPagination = {
  __typename?: "BillingMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type BillingMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Search among invoice addresses  */
export type BillingMsSearchAddressesRequest = {
  filters?: InputMaybe<BillingMsSearchAddressesRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<BillingMsSearchAddressesRequestSort>>>;
};

export type BillingMsSearchAddressesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type BillingMsSearchAddressesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchAddressesRequestSortField>;
};

export enum BillingMsSearchAddressesRequestSortField {
  RegistrationName = "registration_name",
}

export type BillingMsSearchAddressesResponse = {
  __typename?: "BillingMsSearchAddressesResponse";
  data?: Maybe<Array<BillingMsSearchAddressesResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchAddressesResponseData = {
  __typename?: "BillingMsSearchAddressesResponseData";
  /**  The physical address of the customer  */
  address: Scalars["String"]["output"];
  /**  The physical city of the customer  */
  city: Scalars["String"]["output"];
  /**  The email address used as contact  */
  contactEmail: Scalars["String"]["output"];
  /**  The physical country of the customer encoded in ISO 3166-1 standard  */
  country: Scalars["String"]["output"];
  /**  The invoice address id  */
  id: Scalars["ID"]["output"];
  /**  Also named "Raison sociale"  */
  registrationName: Scalars["String"]["output"];
  /**  The registration number of the legal entity.  */
  registrationNumber: Scalars["String"]["output"];
  /**  The physical zip code of the customer  */
  zipCode: Scalars["String"]["output"];
};

/**  Get account preferences related to the billing domain  */
export type BillingMsSearchBillingAccountPreferencesRequest = {
  filters?: InputMaybe<BillingMsSearchBillingAccountPreferencesRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<BillingMsSearchBillingAccountPreferencesRequestSort>>
  >;
};

export type BillingMsSearchBillingAccountPreferencesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type BillingMsSearchBillingAccountPreferencesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchBillingAccountPreferencesRequestSortField>;
};

export enum BillingMsSearchBillingAccountPreferencesRequestSortField {
  Id = "id",
}

export type BillingMsSearchBillingAccountPreferencesResponse = {
  __typename?: "BillingMsSearchBillingAccountPreferencesResponse";
  data?: Maybe<Array<BillingMsSearchBillingAccountPreferencesResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchBillingAccountPreferencesResponseData = {
  __typename?: "BillingMsSearchBillingAccountPreferencesResponseData";
  BillingFrequencyIdData?: Maybe<BillingMsSearchBillingFrequenciesResponseData>;
  CustomerCreditFrequencyIdData?: Maybe<BillingMsSearchCustomerCreditFrequenciesResponseData>;
  IdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  PaymentMethodIdData?: Maybe<BillingMsSearchPaymentMethodsResponseData>;
  /**  The related billing frequency  */
  billingFrequencyId: Scalars["String"]["output"];
  /**  The main currency of the account  */
  currency: Scalars["String"]["output"];
  /**  The total amount of maximum transactions on a given customer credit frequency. Also called PPB in french.  */
  customerCreditConsumption: Scalars["Float"]["output"];
  /**  The customer credit frequency  */
  customerCreditFrequencyId: Scalars["String"]["output"];
  /**  When was the customer credit data updated for the last time  */
  customerCreditLastTs: Scalars["Int64"]["output"];
  /**  The total amount of maximum transactions on a given customer credit frequency. Also called PPB in french.  */
  customerCreditLimit: Scalars["Float"]["output"];
  /**  The account ID  */
  id: Scalars["ID"]["output"];
  /**  The account number (copied data)  */
  number: Scalars["String"]["output"];
  /**  The band address  */
  payerBankDomiciliation: Scalars["String"]["output"];
  /**  True if the SEPA mandate is enabled  */
  payerHasMandate: Scalars["Boolean"]["output"];
  /**  The International Bank Account Number  */
  payerIban: Scalars["String"]["output"];
  /**  The SEPA mandate reference  */
  payerRumReference: Scalars["String"]["output"];
  /**  The Society for Worldwide Interbank Financial Telecommunication or Bank Identification Code  */
  payerSwiftCode: Scalars["String"]["output"];
  /**  The related payment method  */
  paymentMethodId: Scalars["String"]["output"];
  /**  The amount of the security deposit made my the customer  */
  securityDepositAmount: Scalars["Float"]["output"];
  /**  The SEPA mandate signature date  */
  sepaSignatureDate: Scalars["String"]["output"];
  /**  The SEPA mandate signature location  */
  sepaSignatureLocation: Scalars["String"]["output"];
};

/**  Search among billing frequencies  */
export type BillingMsSearchBillingFrequenciesRequest = {
  filters?: InputMaybe<BillingMsSearchBillingFrequenciesRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<BillingMsSearchBillingFrequenciesRequestSort>>
  >;
};

export type BillingMsSearchBillingFrequenciesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type BillingMsSearchBillingFrequenciesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchBillingFrequenciesRequestSortField>;
};

export enum BillingMsSearchBillingFrequenciesRequestSortField {
  Code = "code",
}

export type BillingMsSearchBillingFrequenciesResponse = {
  __typename?: "BillingMsSearchBillingFrequenciesResponse";
  data?: Maybe<Array<BillingMsSearchBillingFrequenciesResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchBillingFrequenciesResponseData = {
  __typename?: "BillingMsSearchBillingFrequenciesResponseData";
  /**  The code name  */
  code: Scalars["String"]["output"];
  /**  The ID of the billing frequency  */
  id: Scalars["ID"]["output"];
  /**  The date of the 1st day of the next billing period  */
  nextDate: Scalars["String"]["output"];
};

/**  Search among customer credit frequencies  */
export type BillingMsSearchCustomerCreditFrequenciesRequest = {
  filters?: InputMaybe<BillingMsSearchCustomerCreditFrequenciesRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<BillingMsSearchCustomerCreditFrequenciesRequestSort>>
  >;
};

export type BillingMsSearchCustomerCreditFrequenciesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type BillingMsSearchCustomerCreditFrequenciesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchCustomerCreditFrequenciesRequestSortField>;
};

export enum BillingMsSearchCustomerCreditFrequenciesRequestSortField {
  Code = "code",
}

export type BillingMsSearchCustomerCreditFrequenciesResponse = {
  __typename?: "BillingMsSearchCustomerCreditFrequenciesResponse";
  data?: Maybe<Array<BillingMsSearchCustomerCreditFrequenciesResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchCustomerCreditFrequenciesResponseData = {
  __typename?: "BillingMsSearchCustomerCreditFrequenciesResponseData";
  /**  The code name  */
  code: Scalars["String"]["output"];
  /**  The ID of the customer credit frequency  */
  id: Scalars["ID"]["output"];
};

/**
 *  Search among invoice lines
 *
 * The invoice lines show the transaction that haven't been paired yet. If you wish to list the transactions of the
 * related invoice, please use the transactions search endpoint.
 */
export type BillingMsSearchInvoiceLinesRequest = {
  filters?: InputMaybe<BillingMsSearchInvoiceLinesRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<BillingMsSearchInvoiceLinesRequestSort>>>;
};

export type BillingMsSearchInvoiceLinesRequestFilters = {
  alsoShowPairedTransactions?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type BillingMsSearchInvoiceLinesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchInvoiceLinesRequestSortField>;
};

export enum BillingMsSearchInvoiceLinesRequestSortField {
  TransactionNumber = "transaction_number",
}

export type BillingMsSearchInvoiceLinesResponse = {
  __typename?: "BillingMsSearchInvoiceLinesResponse";
  data?: Maybe<Array<BillingMsSearchInvoiceLinesResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchInvoiceLinesResponseData = {
  __typename?: "BillingMsSearchInvoiceLinesResponseData";
  InvoiceIdData?: Maybe<BillingMsSearchInvoicesResponseData>;
  createdTs: Scalars["Int64"]["output"];
  /**  The ID of the invoice line  */
  id: Scalars["ID"]["output"];
  /**  The related invoice  */
  invoiceId: Scalars["ID"]["output"];
  /**
   *  The related transaction if there is such. Transactions are paired on a later stage and can be done whenever they
   * are available in the ledgers.
   * The transaction may not be available anymore once it has been archived.
   */
  transactionId: Scalars["String"]["output"];
  /**  The transaction original reference  */
  transactionNumber: Scalars["String"]["output"];
};

/**  Search among invoice types  */
export type BillingMsSearchInvoiceTypesRequest = {
  filters?: InputMaybe<BillingMsSearchInvoiceTypesRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<BillingMsSearchInvoiceTypesRequestSort>>>;
};

export type BillingMsSearchInvoiceTypesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type BillingMsSearchInvoiceTypesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchInvoiceTypesRequestSortField>;
};

export enum BillingMsSearchInvoiceTypesRequestSortField {
  Code = "code",
}

export type BillingMsSearchInvoiceTypesResponse = {
  __typename?: "BillingMsSearchInvoiceTypesResponse";
  data?: Maybe<Array<BillingMsSearchInvoiceTypesResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchInvoiceTypesResponseData = {
  __typename?: "BillingMsSearchInvoiceTypesResponseData";
  /**  The unique code  */
  code: Scalars["String"]["output"];
  /**  The invoice type id  */
  id: Scalars["ID"]["output"];
};

/**  Search among Invoices  */
export type BillingMsSearchInvoicesRequest = {
  filters?: InputMaybe<BillingMsSearchInvoicesRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<BillingMsSearchInvoicesRequestSort>>>;
};

export type BillingMsSearchInvoicesRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  fromDate?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  showAvailable?: InputMaybe<Scalars["Boolean"]["input"]>;
  showNotAvailable?: InputMaybe<Scalars["Boolean"]["input"]>;
  tillDate?: InputMaybe<Scalars["String"]["input"]>;
  typeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type BillingMsSearchInvoicesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchInvoicesRequestSortField>;
};

export enum BillingMsSearchInvoicesRequestSortField {
  AmountExcludingVat = "amount_excluding_vat",
  AmountIncludingVat = "amount_including_vat",
  AmountVat = "amount_vat",
  Date = "date",
  DateTs = "date_ts",
  DueDateTs = "due_date_ts",
  Reference = "reference",
  Type = "type",
}

export type BillingMsSearchInvoicesResponse = {
  __typename?: "BillingMsSearchInvoicesResponse";
  data?: Maybe<Array<BillingMsSearchInvoicesResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchInvoicesResponseData = {
  __typename?: "BillingMsSearchInvoicesResponseData";
  AccountIdData?: Maybe<BillingMsSearchBillingAccountPreferencesResponseData>;
  BilledAddressIdData?: Maybe<BillingMsSearchAddressesResponseData>;
  DocumentIdData?: Maybe<MediaMsSearchDocumentsResponseData>;
  TypeIdData?: Maybe<BillingMsSearchInvoiceTypesResponseData>;
  /**  The account which was billed.  */
  accountId: Scalars["String"]["output"];
  /**  The currency of the related amounts  */
  amountCurrency: Scalars["String"]["output"];
  /**  The total amount without the VAT (normalized in an integer, check the precision related to the currency)  */
  amountExcludingVat: Scalars["Float"]["output"];
  /**  The total amount including the VAT (normalized in an integer, check the precision related to the currency)  */
  amountIncludingVat: Scalars["Float"]["output"];
  /**  The VAT tax amount (normalized in an integer, check the precision related to the currency)  */
  amountVat: Scalars["Float"]["output"];
  /**  The document is available if not zero  */
  availableCheckedTs: Scalars["Int64"]["output"];
  /**  The related document if there is such  */
  billedAddressId: Scalars["String"]["output"];
  /**  The date of the invoice in ISO 8601 format  */
  date: Scalars["String"]["output"];
  /**  The date of the invoice in seconds  */
  dateTs: Scalars["String"]["output"];
  /**  The related document if there is such  */
  documentId: Scalars["String"]["output"];
  /**  The due date of the invoice in seconds  */
  dueDateTs: Scalars["String"]["output"];
  /**  The ID of the invoice  */
  id: Scalars["ID"]["output"];
  /**  The number of the invoice  */
  number: Scalars["String"]["output"];
  /**  The recipient customer account number  */
  recipientAccountNumber: Scalars["String"]["output"];
  /**  The related invoice type  */
  typeId: Scalars["String"]["output"];
};

/**  Search among payment methods  */
export type BillingMsSearchPaymentMethodsRequest = {
  filters?: InputMaybe<BillingMsSearchPaymentMethodsRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<BillingMsSearchPaymentMethodsRequestSort>>
  >;
};

export type BillingMsSearchPaymentMethodsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type BillingMsSearchPaymentMethodsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchPaymentMethodsRequestSortField>;
};

export enum BillingMsSearchPaymentMethodsRequestSortField {
  Code = "code",
}

export type BillingMsSearchPaymentMethodsResponse = {
  __typename?: "BillingMsSearchPaymentMethodsResponse";
  data?: Maybe<Array<BillingMsSearchPaymentMethodsResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchPaymentMethodsResponseData = {
  __typename?: "BillingMsSearchPaymentMethodsResponseData";
  /**  The code name  */
  code: Scalars["String"]["output"];
  /**  The ID of the billing frequency  */
  id: Scalars["ID"]["output"];
};

/**
 *  Search among payments
 *  TODO: Feature ?
 */
export type BillingMsSearchPaymentsRequest = {
  filters?: InputMaybe<BillingMsSearchPaymentsRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<BillingMsSearchPaymentsRequestSort>>>;
};

export type BillingMsSearchPaymentsRequestFilters = {
  fromDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  statuses?: InputMaybe<
    Array<InputMaybe<BillingMsSearchPaymentsRequestFiltersStatuses>>
  >;
  tillDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export enum BillingMsSearchPaymentsRequestFiltersStatuses {
  Cancelled = "CANCELLED",
  Created = "CREATED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Succeeded = "SUCCEEDED",
  UnknownPaymentStatus = "UNKNOWN_PAYMENT_STATUS",
}

export type BillingMsSearchPaymentsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchPaymentsRequestSortField>;
};

export enum BillingMsSearchPaymentsRequestSortField {
  Status = "status",
  Type = "type",
}

export type BillingMsSearchPaymentsResponse = {
  __typename?: "BillingMsSearchPaymentsResponse";
  data?: Maybe<Array<BillingMsSearchPaymentsResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchPaymentsResponseData = {
  __typename?: "BillingMsSearchPaymentsResponseData";
  AccountIdData?: Maybe<BillingMsSearchBillingAccountPreferencesResponseData>;
  UserIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  /**  The Account the Payment belongs to.  */
  accountId: Scalars["String"]["output"];
  /**  The ID of the Payment.  */
  id: Scalars["ID"]["output"];
  /**  The User that requested the Payment.  */
  userId: Scalars["String"]["output"];
};

/**  Search among Tids  */
export type BillingMsSearchTidsRequest = {
  filters?: InputMaybe<BillingMsSearchTidsRequestFilters>;
  paging?: InputMaybe<BillingMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<BillingMsSearchTidsRequestSort>>>;
};

export type BillingMsSearchTidsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type BillingMsSearchTidsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<BillingMsSearchTidsRequestSortField>;
};

export enum BillingMsSearchTidsRequestSortField {
  EndDateTs = "end_date_ts",
  StartDateTs = "start_date_ts",
}

export type BillingMsSearchTidsResponse = {
  __typename?: "BillingMsSearchTidsResponse";
  data?: Maybe<Array<BillingMsSearchTidsResponseData>>;
  pagination: BillingMsPagedPagination;
};

export type BillingMsSearchTidsResponseData = {
  __typename?: "BillingMsSearchTidsResponseData";
  DocumentIdData?: Maybe<MediaMsSearchDocumentsResponseData>;
  /**  The id of the related TXT document.  */
  documentId: Scalars["String"]["output"];
  /**  The end date.  */
  endDateTs: Scalars["Int64"]["output"];
  /**  The ID of the TID.  */
  id: Scalars["ID"]["output"];
  /**  The start date.  */
  startDateTs: Scalars["Int64"]["output"];
};

export type ChargingPointsMsGetChargingPortsAvailabilityRequest = {
  placeId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ChargingPointsMsGetChargingPortsAvailabilityResponse = {
  __typename?: "ChargingPointsMsGetChargingPortsAvailabilityResponse";
  /**  The number of available charging port  */
  available: Scalars["Int64"]["output"];
  /**  The number of occupied charging port  */
  occupied: Scalars["Int64"]["output"];
};

export type ChargingPointsMsPagedPagination = {
  __typename?: "ChargingPointsMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type ChargingPointsMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Get account preferences related to the charging points domain  */
export type ChargingPointsMsSearchChargingPointsAccountPreferencesRequest = {
  filters?: InputMaybe<ChargingPointsMsSearchChargingPointsAccountPreferencesRequestFilters>;
  paging?: InputMaybe<ChargingPointsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<
      InputMaybe<ChargingPointsMsSearchChargingPointsAccountPreferencesRequestSort>
    >
  >;
};

export type ChargingPointsMsSearchChargingPointsAccountPreferencesRequestFilters =
  {
    ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  };

export type ChargingPointsMsSearchChargingPointsAccountPreferencesRequestSort =
  {
    ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
    field?: InputMaybe<ChargingPointsMsSearchChargingPointsAccountPreferencesRequestSortField>;
  };

export enum ChargingPointsMsSearchChargingPointsAccountPreferencesRequestSortField {
  Id = "id",
}

export type ChargingPointsMsSearchChargingPointsAccountPreferencesResponse = {
  __typename?: "ChargingPointsMsSearchChargingPointsAccountPreferencesResponse";
  data?: Maybe<
    Array<ChargingPointsMsSearchChargingPointsAccountPreferencesResponseData>
  >;
  pagination: ChargingPointsMsPagedPagination;
};

export type ChargingPointsMsSearchChargingPointsAccountPreferencesResponseData =
  {
    __typename?: "ChargingPointsMsSearchChargingPointsAccountPreferencesResponseData";
    IdData?: Maybe<AccountsMsSearchAccountsResponseData>;
    /**  The account ID  */
    id: Scalars["ID"]["output"];
  };

/**  Search among places  */
export type ChargingPointsMsSearchPlacesRequest = {
  filters?: InputMaybe<ChargingPointsMsSearchPlacesRequestFilters>;
  paging?: InputMaybe<ChargingPointsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<ChargingPointsMsSearchPlacesRequestSort>>
  >;
};

export type ChargingPointsMsSearchPlacesRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type ChargingPointsMsSearchPlacesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<ChargingPointsMsSearchPlacesRequestSortField>;
};

export enum ChargingPointsMsSearchPlacesRequestSortField {
  UpdatedTs = "updated_ts",
}

export type ChargingPointsMsSearchPlacesResponse = {
  __typename?: "ChargingPointsMsSearchPlacesResponse";
  data?: Maybe<Array<ChargingPointsMsSearchPlacesResponseData>>;
  pagination: ChargingPointsMsPagedPagination;
};

export type ChargingPointsMsSearchPlacesResponseData = {
  __typename?: "ChargingPointsMsSearchPlacesResponseData";
  /**  The Be:Mo reference  */
  bemoReference: Scalars["String"]["output"];
  /**  The place ID  */
  id: Scalars["ID"]["output"];
  /**  The place name  */
  name: Scalars["String"]["output"];
};

/**  Search among charging session states  */
export type ChargingPointsMsSearchSessionStatesRequest = {
  filters?: InputMaybe<ChargingPointsMsSearchSessionStatesRequestFilters>;
  paging?: InputMaybe<ChargingPointsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<ChargingPointsMsSearchSessionStatesRequestSort>>
  >;
};

export type ChargingPointsMsSearchSessionStatesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  state?: InputMaybe<ChargingPointsMsSearchSessionStatesRequestFiltersState>;
  vehicleId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ChargingPointsMsSearchSessionStatesRequestFiltersState {
  Completed = "COMPLETED",
  Error = "ERROR",
  Running = "RUNNING",
  Unknown = "UNKNOWN",
}

export type ChargingPointsMsSearchSessionStatesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<ChargingPointsMsSearchSessionStatesRequestSortField>;
};

export enum ChargingPointsMsSearchSessionStatesRequestSortField {
  Id = "id",
  LastRecordedTs = "last_recorded_ts",
}

export type ChargingPointsMsSearchSessionStatesResponse = {
  __typename?: "ChargingPointsMsSearchSessionStatesResponse";
  data?: Maybe<Array<ChargingPointsMsSearchSessionStatesResponseData>>;
  pagination: ChargingPointsMsPagedPagination;
};

export type ChargingPointsMsSearchSessionStatesResponseData = {
  __typename?: "ChargingPointsMsSearchSessionStatesResponseData";
  PlaceIdData?: Maybe<ChargingPointsMsSearchPlacesResponseData>;
  VehicleIdData?: Maybe<VehiclesMsSearchVehiclesResponseData>;
  /**  session id Be:Mo  */
  bemoSessionId: Scalars["String"]["output"];
  chargingPointId: Scalars["ID"]["output"];
  /**  end date nullable  */
  endTs: Scalars["Int64"]["output"];
  /**  Expressed in Kwh  */
  energyQuantity: Scalars["Float"]["output"];
  /**  The ID of the session state  */
  id: Scalars["ID"]["output"];
  /**  latest recorded  */
  lastRecordedTs: Scalars["Int64"]["output"];
  /**  The related place  */
  placeId: Scalars["ID"]["output"];
  /**  start date  */
  startTs: Scalars["Int64"]["output"];
  /**  state  */
  state: Scalars["String"]["output"];
  /**  id of vehicle in vehicle service  */
  vehicleId: Scalars["ID"]["output"];
};

/**  Archive collaborator  */
export type CollaboratorsMsArchiveCollaboratorRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  leavingDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type CollaboratorsMsCountCollaboratorsByStatusResponse = {
  __typename?: "CollaboratorsMsCountCollaboratorsByStatusResponse";
  /**  All collaborators currently in the company  */
  activated: Scalars["Int64"]["output"];
  /**  All collaborators  */
  all: Scalars["Int64"]["output"];
  /**  All collaborators who left the company  */
  archived: Scalars["Int64"]["output"];
};

/**  Create a collaborator  */
export type CollaboratorsMsCreateCollaboratorRequest = {
  contactEmail?: InputMaybe<Scalars["String"]["input"]>;
  /**
   *  A four-digit code that is used only in account with the feature DRIVER_CODES
   * If the driver code does not exist from our partner, we will request to create it
   * The driver code will be in "creating" state.
   * Is also allowed an orphan driver code (the driver code will be in "active" state).
   */
  driverCode?: InputMaybe<Scalars["String"]["input"]>;
  enteredDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  sectorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CollaboratorsMsCreateCollaboratorResponse = {
  __typename?: "CollaboratorsMsCreateCollaboratorResponse";
  /**  The ID of the Collaborator  */
  id: Scalars["ID"]["output"];
};

/**
 *  Link a driver code to a collaborator. If the driver code does not exist, we will request our partner to create it.
 * The driver code will be in "creating" state.
 * We can also link an orphan driver code. It will be in "active" state.
 * We can link a driver code for a collaborator that has no driver code yet, or their drive code is blocked.
 */
export type CollaboratorsMsLinkDriverCodeRequest = {
  collaboratorId?: InputMaybe<Scalars["ID"]["input"]>;
  driverCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollaboratorsMsPagedPagination = {
  __typename?: "CollaboratorsMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type CollaboratorsMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 *  Our partner has accepted our request, the driver code will have the state "waiting to be blocked"
 * The driver code will be "blocked" when our partner will send us the relative state
 * If the partner does not accept our request (error), the driver code will stay at its previous state
 * Only an active driver code can be blocked.
 */
export type CollaboratorsMsRequestToBlockDriverCodeRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 *  Our partner has accepted our request, the driver code will have the state "waiting to be unblocked"
 * The driver code will be "unblocked" when our partner will send us the relative state
 * If the partner does not accept our request (error), the driver code will stay at its previous state
 * Only a blocked driver code can be unblocked.
 */
export type CollaboratorsMsRequestToUnBlockDriverCodeRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Search among collaborators  */
export type CollaboratorsMsSearchCollaboratorsRequest = {
  filters?: InputMaybe<CollaboratorsMsSearchCollaboratorsRequestFilters>;
  paging?: InputMaybe<CollaboratorsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<CollaboratorsMsSearchCollaboratorsRequestSort>>
  >;
};

export type CollaboratorsMsSearchCollaboratorsRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollaboratorsMsSearchCollaboratorsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<CollaboratorsMsSearchCollaboratorsRequestSortField>;
};

export enum CollaboratorsMsSearchCollaboratorsRequestSortField {
  FirstName = "first_name",
  Id = "id",
  LastName = "last_name",
}

export type CollaboratorsMsSearchCollaboratorsResponse = {
  __typename?: "CollaboratorsMsSearchCollaboratorsResponse";
  data?: Maybe<Array<CollaboratorsMsSearchCollaboratorsResponseData>>;
  pagination: CollaboratorsMsPagedPagination;
};

export type CollaboratorsMsSearchCollaboratorsResponseData = {
  __typename?: "CollaboratorsMsSearchCollaboratorsResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  Allocations?: Maybe<
    Array<Maybe<CollaboratorsMsSearchDriverCodeAllocationsResponseData>>
  >;
  SectorIdData?: Maybe<SectorsMsSearchSectorsResponseData>;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  /**  The informative email address of the collaborator  */
  contactEmail: Scalars["String"]["output"];
  /**  The date the collaborator entered the company  */
  enteredDateTs: Scalars["Int64"]["output"];
  /**  The first name  */
  firstName: Scalars["String"]["output"];
  /**  The ID of a Collaborator  */
  id: Scalars["ID"]["output"];
  /**  The functions of the collaborator  */
  jobTitle: Scalars["String"]["output"];
  /**  The last name  */
  lastName: Scalars["String"]["output"];
  /**  The date the collaborator left the company  */
  leavingDateTs: Scalars["Int64"]["output"];
  /**  The related sector  */
  sectorId: Scalars["ID"]["output"];
  status: CollaboratorsMsSearchCollaboratorsResponseDataStatus;
};

export enum CollaboratorsMsSearchCollaboratorsResponseDataStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  CollaboratorStatusUnknown = "COLLABORATOR_STATUS_UNKNOWN",
}

export type CollaboratorsMsSearchDriverCodeAllocationsRequest = {
  filters?: InputMaybe<CollaboratorsMsSearchDriverCodeAllocationsRequestFilters>;
  paging?: InputMaybe<CollaboratorsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<CollaboratorsMsSearchDriverCodeAllocationsRequestSort>>
  >;
};

export type CollaboratorsMsSearchDriverCodeAllocationsRequestFilters = {
  collaboratorIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  driverCodeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /**
   *  Only active allocations are retrieved by default (based on current time).
   * This filter allows to get both active and inactive allocations
   */
  showAll?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CollaboratorsMsSearchDriverCodeAllocationsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<CollaboratorsMsSearchDriverCodeAllocationsRequestSortField>;
};

export enum CollaboratorsMsSearchDriverCodeAllocationsRequestSortField {
  FromTs = "from_ts",
  Id = "id",
}

export type CollaboratorsMsSearchDriverCodeAllocationsResponse = {
  __typename?: "CollaboratorsMsSearchDriverCodeAllocationsResponse";
  data?: Maybe<Array<CollaboratorsMsSearchDriverCodeAllocationsResponseData>>;
  pagination: CollaboratorsMsPagedPagination;
};

export type CollaboratorsMsSearchDriverCodeAllocationsResponseData = {
  __typename?: "CollaboratorsMsSearchDriverCodeAllocationsResponseData";
  CollaboratorIdData?: Maybe<CollaboratorsMsSearchCollaboratorsResponseData>;
  DriverCodeIdData?: Maybe<CollaboratorsMsSearchDriverCodesResponseData>;
  /**  The related collaborator  */
  collaboratorId: Scalars["ID"]["output"];
  /**  The related driver code  */
  driverCodeId: Scalars["ID"]["output"];
  /**  The allocation start date  */
  fromTs: Scalars["Int64"]["output"];
  id: Scalars["ID"]["output"];
  /**  The allocation end date  */
  tillTs: Scalars["Int64"]["output"];
};

export type CollaboratorsMsSearchDriverCodesRequest = {
  filters?: InputMaybe<CollaboratorsMsSearchDriverCodesRequestFilters>;
  paging?: InputMaybe<CollaboratorsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<CollaboratorsMsSearchDriverCodesRequestSort>>
  >;
};

export type CollaboratorsMsSearchDriverCodesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  sectorIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type CollaboratorsMsSearchDriverCodesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<CollaboratorsMsSearchDriverCodesRequestSortField>;
};

export enum CollaboratorsMsSearchDriverCodesRequestSortField {
  Code = "code",
  Id = "id",
}

export type CollaboratorsMsSearchDriverCodesResponse = {
  __typename?: "CollaboratorsMsSearchDriverCodesResponse";
  data?: Maybe<Array<CollaboratorsMsSearchDriverCodesResponseData>>;
  pagination: CollaboratorsMsPagedPagination;
};

export type CollaboratorsMsSearchDriverCodesResponseData = {
  __typename?: "CollaboratorsMsSearchDriverCodesResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  SectorIdData?: Maybe<SectorsMsSearchSectorsResponseData>;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  /**  The four-digit human-readable code  */
  code: Scalars["String"]["output"];
  collaboratorId: Scalars["ID"]["output"];
  /**  The ID of a Driver Code  */
  id: Scalars["ID"]["output"];
  /**  The related sector  */
  sectorId: Scalars["ID"]["output"];
  /**  The current status  */
  status: CollaboratorsMsSearchDriverCodesResponseDataStatus;
};

export enum CollaboratorsMsSearchDriverCodesResponseDataStatus {
  Creating = "CREATING",
  DriverCodeStatusUnknown = "DRIVER_CODE_STATUS_UNKNOWN",
  IsActive = "IS_ACTIVE",
  IsSuspended = "IS_SUSPENDED",
  Reactivating = "REACTIVATING",
  Suspending = "SUSPENDING",
}

export type CollaboratorsMsSuggestDriverCodeRequest = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  sectorId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CollaboratorsMsSuggestDriverCodeResponse = {
  __typename?: "CollaboratorsMsSuggestDriverCodeResponse";
  code: Scalars["String"]["output"];
};

/**  Unarchive collaborator  */
export type CollaboratorsMsUnarchiveCollaboratorRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Updates a collaborator  */
export type CollaboratorsMsUpdateCollaboratorRequest = {
  /**  The informative email address of the collaborator  */
  contactEmail?: InputMaybe<Scalars["String"]["input"]>;
  /**  The first name  */
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /**  The ID of a Collaborator  */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The functions of the collaborator  */
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  /**  The last name  */
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

/**  Block (oppose) a card or a badge for system partners  */
export type LedgersMsBlockLedgerAsPartnerRequest = {
  /**  The PAN  */
  pan?: InputMaybe<Scalars["String"]["input"]>;
  /**  Why do you wish to block this ledger?  */
  reason?: InputMaybe<LedgersMsBlockLedgerAsPartnerRequestReason>;
  /**  Set to true if you wish to receive a replacement card  */
  renew?: InputMaybe<Scalars["Boolean"]["input"]>;
  who?: InputMaybe<LedgersMsBlockLedgerAsPartnerRequestWho>;
};

/**  Why do you wish to block this ledger?  */
export enum LedgersMsBlockLedgerAsPartnerRequestReason {
  Defective = "DEFECTIVE",
  Lost = "LOST",
  NotReceived = "NOT_RECEIVED",
  Stolen = "STOLEN",
  Undefined = "UNDEFINED",
}

export type LedgersMsBlockLedgerAsPartnerRequestWho = {
  /**  The end user Gigya ID  */
  gigyaId?: InputMaybe<Scalars["String"]["input"]>;
  /**  The requesting service  */
  service?: InputMaybe<LedgersMsBlockLedgerAsPartnerRequestWhoService>;
};

/**  The requesting service  */
export enum LedgersMsBlockLedgerAsPartnerRequestWhoService {
  MyCard = "MY_CARD",
  ServiceUndefined = "SERVICE_UNDEFINED",
}

/**  Block (oppose) a card or a badge  */
export type LedgersMsBlockLedgerRequest = {
  /**  The ledger id  */
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /**  Why do you wish to block this ledger?  */
  reason?: InputMaybe<LedgersMsBlockLedgerRequestReason>;
  /**  Set to true if you wish to receive a replacement card  */
  renew?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**  Why do you wish to block this ledger?  */
export enum LedgersMsBlockLedgerRequestReason {
  Defective = "DEFECTIVE",
  Lost = "LOST",
  NotReceived = "NOT_RECEIVED",
  Stolen = "STOLEN",
  Undefined = "UNDEFINED",
}

/**  Who initiated the request  */
export type LedgersMsBlockLedgerRequestWho = {
  gigyaId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsBlockLedgerResponse = {
  __typename?: "LedgersMsBlockLedgerResponse";
  /**  The partner's http status if not successful  */
  httpStatusCode: Scalars["Int"]["output"];
};

/**  Cancel the renewal of a ledger  */
export type LedgersMsCancelLedgerRenewalRequest = {
  /**  The ledger id  */
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsCancelLedgerRenewalRequestWho = {
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsCancelLedgerRenewalResponse = {
  __typename?: "LedgersMsCancelLedgerRenewalResponse";
  httpStatusCode: Scalars["Int"]["output"];
  invalidEncodedJsonData: Scalars["Boolean"]["output"];
};

/**  Count the ledgers by status  */
export type LedgersMsCountLedgersByStatusRequest = {
  /**  The type of ledger to count by status  */
  pcomType?: InputMaybe<LedgersMsCountLedgersByStatusRequestPcomType>;
  /**  The list of status codes to narrow the result with. All codes will be used if empty.  */
  statusCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/**  The type of ledger to count by status  */
export enum LedgersMsCountLedgersByStatusRequestPcomType {
  Badge = "BADGE",
  Card = "CARD",
  PcomTypeUnknown = "PCOM_TYPE_UNKNOWN",
}

export type LedgersMsCountLedgersByStatusResponse = {
  __typename?: "LedgersMsCountLedgersByStatusResponse";
  metrics?: Maybe<Array<LedgersMsCountLedgersByStatusResponseMetric>>;
};

export type LedgersMsCountLedgersByStatusResponseMetric = {
  __typename?: "LedgersMsCountLedgersByStatusResponseMetric";
  StatusIdData?: Maybe<LedgersMsSearchStatusesResponseData>;
  /**  The ledger status code  */
  statusCode: Scalars["String"]["output"];
  /**  The ledger status id  */
  statusId: Scalars["ID"]["output"];
  /**  The total number of ledgers  */
  total: Scalars["Int64"]["output"];
};

/**  Count the orders by status  */
export type LedgersMsCountOrdersByStatusRequest = {
  /**  The list of order status to narrow the result with. All statuses will be used if empty.  */
  statuses?: InputMaybe<
    Array<InputMaybe<LedgersMsCountOrdersByStatusRequestStatuses>>
  >;
};

/**  The list of order status to narrow the result with. All statuses will be used if empty.  */
export enum LedgersMsCountOrdersByStatusRequestStatuses {
  Failed = "FAILED",
  Initialized = "INITIALIZED",
  OrderStatusUnknown = "ORDER_STATUS_UNKNOWN",
  Processing = "PROCESSING",
  Successful = "SUCCESSFUL",
}

export type LedgersMsCountOrdersByStatusResponse = {
  __typename?: "LedgersMsCountOrdersByStatusResponse";
  metrics?: Maybe<Array<LedgersMsCountOrdersByStatusResponseMetric>>;
};

export type LedgersMsCountOrdersByStatusResponseMetric = {
  __typename?: "LedgersMsCountOrdersByStatusResponseMetric";
  /**  The order status  */
  status: LedgersMsCountOrdersByStatusResponseMetricStatus;
  /**  The total number of orders  */
  total: Scalars["Int64"]["output"];
};

/**  The order status  */
export enum LedgersMsCountOrdersByStatusResponseMetricStatus {
  Failed = "FAILED",
  Initialized = "INITIALIZED",
  OrderStatusUnknown = "ORDER_STATUS_UNKNOWN",
  Processing = "PROCESSING",
  Successful = "SUCCESSFUL",
}

/**  Create a consumption monitoring report.  */
export type LedgersMsCreateConsumptionMonitoringReportRequest = {
  fromDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  tillDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type LedgersMsCreateConsumptionMonitoringReportResponse = {
  __typename?: "LedgersMsCreateConsumptionMonitoringReportResponse";
  /**  The id of the report  */
  reportId: Scalars["ID"]["output"];
  /**  The id of the task  */
  taskId: Scalars["ID"]["output"];
};

/**  Create a cost tracking report.  */
export type LedgersMsCreateCostTrackingReportRequest = {
  fromDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  tillDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type LedgersMsCreateCostTrackingReportResponse = {
  __typename?: "LedgersMsCreateCostTrackingReportResponse";
  /**  The id of the report  */
  reportId: Scalars["ID"]["output"];
  /**  The id of the task  */
  taskId: Scalars["ID"]["output"];
};

/**  Create an order of a ledger.  */
export type LedgersMsCreateLedgerOrderRequest = {
  /**  The type used during the ledger creation data request.  */
  ledgerTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The raw filled up data to be sent to the ledger provider.  */
  rawFormData?: InputMaybe<Scalars["String"]["input"]>;
  /**  The wallet used during the ledger creation data request.  */
  walletId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsCreateLedgerOrderResponse = {
  __typename?: "LedgersMsCreateLedgerOrderResponse";
  orderId: Scalars["ID"]["output"];
};

/**  Create a mileage report.  */
export type LedgersMsCreateMileageReportRequest = {
  fromDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  tillDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type LedgersMsCreateMileageReportResponse = {
  __typename?: "LedgersMsCreateMileageReportResponse";
  /**  The id of the report  */
  reportId: Scalars["ID"]["output"];
  /**  The id of the task  */
  taskId: Scalars["ID"]["output"];
};

/**  Create a price ceiling report.  */
export type LedgersMsCreatePriceCeilingReportRequest = {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  fuelCode?: InputMaybe<Scalars["String"]["input"]>;
  pricingDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type LedgersMsCreatePriceCeilingReportResponse = {
  __typename?: "LedgersMsCreatePriceCeilingReportResponse";
  /**  The id of the report  */
  reportId: Scalars["ID"]["output"];
  /**  The id of the task  */
  taskId: Scalars["ID"]["output"];
};

/**  Create an export of all ledgers.  */
export type LedgersMsExportSupportConfigRequest = {
  fromDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  tillDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type LedgersMsExportSupportConfigResponse = {
  __typename?: "LedgersMsExportSupportConfigResponse";
  /**  The id of the report  */
  reportId: Scalars["ID"]["output"];
  /**  The id of the task  */
  taskId: Scalars["ID"]["output"];
};

/**  Create an export of transactions.  */
export type LedgersMsExportTransactionsRequest = {
  fromDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  productCategoryId?: InputMaybe<Scalars["ID"]["input"]>;
  tillDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type LedgersMsExportTransactionsResponse = {
  __typename?: "LedgersMsExportTransactionsResponse";
  /**  The id of the report  */
  reportId: Scalars["ID"]["output"];
  /**  The id of the task  */
  taskId: Scalars["ID"]["output"];
};

export type LedgersMsField = {
  __typename?: "LedgersMsField";
  data: LedgersMsFieldData;
  path: Scalars["String"]["output"];
};

export type LedgersMsFieldData = {
  __typename?: "LedgersMsFieldData";
  decorator: Scalars["String"]["output"];
  defaultValue: Scalars["String"]["output"];
  hidden: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  options?: Maybe<Array<LedgersMsFormOption>>;
  postName: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
  type: Scalars["String"]["output"];
  validators?: Maybe<Array<LedgersMsFieldValidator>>;
  value: Scalars["String"]["output"];
  writable: Scalars["Boolean"]["output"];
};

export type LedgersMsFieldValidator = {
  __typename?: "LedgersMsFieldValidator";
  name: LedgersMsFieldValidatorName;
  options: LedgersMsValidatorOptions;
};

export enum LedgersMsFieldValidatorName {
  AuthorizedWeekDaysSelection = "AUTHORIZED_WEEK_DAYS_SELECTION",
  Digits = "DIGITS",
  LowerCase = "LOWER_CASE",
  MaxLength = "MAX_LENGTH",
  MinLength = "MIN_LENGTH",
  UpperCase = "UPPER_CASE",
}

/**  The form data structure, used only for the contract generation...  */
export type LedgersMsFormData = {
  __typename?: "LedgersMsFormData";
  fields?: Maybe<Array<LedgersMsField>>;
  firstNodeName: Scalars["String"]["output"];
};

export type LedgersMsFormOption = {
  __typename?: "LedgersMsFormOption";
  id: Scalars["String"]["output"];
  impactedFields?: Maybe<Array<LedgersMsNestedFieldData>>;
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

/**  Provides with the CardPro division structure in order to modify a ledger.  */
export type LedgersMsGetDivisionsRequest = {
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsGetDivisionsResponse = {
  __typename?: "LedgersMsGetDivisionsResponse";
  divisions?: Maybe<Array<LedgersMsGetDivisionsResponseDivision>>;
};

export type LedgersMsGetDivisionsResponseDivision = {
  __typename?: "LedgersMsGetDivisionsResponseDivision";
  label: Scalars["String"]["output"];
  originalLabel: Scalars["String"]["output"];
  walletId: Scalars["ID"]["output"];
};

/**  Get Geographic Areas  */
export type LedgersMsGetGeographicAreasRequest = {
  /**  The geographic area code code used to search its children.  */
  parentCode?: InputMaybe<Scalars["String"]["input"]>;
  /**  The type to search (COUNTRY, LARGE_REGION, REGION, DEPARTMENT)  */
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type LedgersMsGetGeographicAreasResponse = {
  __typename?: "LedgersMsGetGeographicAreasResponse";
  /**  The list of geographic areas  */
  geographicAreas?: Maybe<
    Array<LedgersMsGetGeographicAreasResponseGeographicArea>
  >;
};

/**  The list of geographic areas  */
export type LedgersMsGetGeographicAreasResponseGeographicArea = {
  __typename?: "LedgersMsGetGeographicAreasResponseGeographicArea";
  /**  The geographic area code  */
  code: Scalars["String"]["output"];
  /**  The geographic area label  */
  label: Scalars["String"]["output"];
};

/**  Fetch the creation data used for the ledger creation.  */
export type LedgersMsGetLedgerCreationDataRequest = {
  ledgerTypeId?: InputMaybe<Scalars["ID"]["input"]>;
  walletId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Who initiated the request  */
export type LedgersMsGetLedgerCreationDataRequestWho = {
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsGetLedgerCreationDataResponse = {
  __typename?: "LedgersMsGetLedgerCreationDataResponse";
  /**  True if the partner was unavailable  */
  isUnavailable: Scalars["Boolean"]["output"];
  /**  The raw data information used to fill up the create ledger order.  */
  rawFormData: Scalars["String"]["output"];
};

/**  Fetch the raw data of a ledger.  */
export type LedgersMsGetLedgerDataRequest = {
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Who initiated the request  */
export type LedgersMsGetLedgerDataRequestWho = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsGetLedgerDataResponse = {
  __typename?: "LedgersMsGetLedgerDataResponse";
  /**  True if the partner was unavailable  */
  isUnavailable: Scalars["Boolean"]["output"];
  /**  The ledger information as JSON string  */
  rawData: Scalars["String"]["output"];
  /**  The form data structure, used only for the contract generation...  */
  structure: LedgersMsFormData;
};

/**  Fetch the modification data used for the modification of ledger  */
export type LedgersMsGetLedgerModificationDataRequest = {
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Who initiated the request  */
export type LedgersMsGetLedgerModificationDataRequestWho = {
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsGetLedgerModificationDataResponse = {
  __typename?: "LedgersMsGetLedgerModificationDataResponse";
  /**  True if the partner was unavailable  */
  isUnavailable: Scalars["Boolean"]["output"];
  /**  The raw data information used to fill up the modification ledger order.  */
  rawFormData: Scalars["String"]["output"];
};

/**
 *  Fetch the list of creatable ledger types.
 *
 * List all orderable ledger types for the current user on the given wallet.
 */
export type LedgersMsGetOrderableWalletLedgerTypesRequest = {
  walletId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsGetOrderableWalletLedgerTypesResponse = {
  __typename?: "LedgersMsGetOrderableWalletLedgerTypesResponse";
  data?: Maybe<Array<LedgersMsGetOrderableWalletLedgerTypesResponseData>>;
};

export type LedgersMsGetOrderableWalletLedgerTypesResponseData = {
  __typename?: "LedgersMsGetOrderableWalletLedgerTypesResponseData";
  GroupIdData?: Maybe<LedgersMsSearchGroupsResponseData>;
  /**  The unique code  */
  code: Scalars["String"]["output"];
  /**  The ledger code comment  */
  codeHint: Scalars["String"]["output"];
  /**  The group the type is linked to  */
  groupId: Scalars["ID"]["output"];
  /**  The ledger id  */
  id: Scalars["ID"]["output"];
};

/**  Fetch the PIN type of a ledger.  */
export type LedgersMsGetPinTypeRequest = {
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsGetPinTypeResponse = {
  __typename?: "LedgersMsGetPinTypeResponse";
  code: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type LedgersMsGetSupportsAndDivisionsResponse = {
  __typename?: "LedgersMsGetSupportsAndDivisionsResponse";
  supportAccounts?: Maybe<
    Array<LedgersMsGetSupportsAndDivisionsResponseSupportAccount>
  >;
};

export type LedgersMsGetSupportsAndDivisionsResponseSupportAccount = {
  __typename?: "LedgersMsGetSupportsAndDivisionsResponseSupportAccount";
  divisionAccounts?: Maybe<
    Array<LedgersMsGetSupportsAndDivisionsResponseSupportAccountDivisionAccount>
  >;
  label: Scalars["String"]["output"];
  originalLabel: Scalars["String"]["output"];
  walletId: Scalars["ID"]["output"];
};

export type LedgersMsGetSupportsAndDivisionsResponseSupportAccountDivisionAccount =
  {
    __typename?: "LedgersMsGetSupportsAndDivisionsResponseSupportAccountDivisionAccount";
    label: Scalars["String"]["output"];
    originalLabel: Scalars["String"]["output"];
    walletId: Scalars["ID"]["output"];
  };

/**  Get transaction's amounts stats.  */
export type LedgersMsGetTransactionAmountsRequest = {
  /**  Use "first of the month" when empty  */
  fromDate?: InputMaybe<Scalars["String"]["input"]>;
  /**  An optional ledger id to narrow the stats  */
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**  Use "today" when empty  */
  tillDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type LedgersMsGetTransactionAmountsResponse = {
  __typename?: "LedgersMsGetTransactionAmountsResponse";
  /**  The sum of transaction spending  */
  amounts?: Maybe<Array<LedgersMsGetTransactionAmountsResponseAmount>>;
  /**  The number of all transactions  */
  transactionCount: Scalars["Int64"]["output"];
};

/**  The sum of transaction spending  */
export type LedgersMsGetTransactionAmountsResponseAmount = {
  __typename?: "LedgersMsGetTransactionAmountsResponseAmount";
  amountIncludingVat: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  /**  The number of transactions for the given currency  */
  transactionCount: Scalars["Int64"]["output"];
};

export type LedgersMsGetWalletTreeResponse = {
  __typename?: "LedgersMsGetWalletTreeResponse";
  nodes?: Maybe<Array<LedgersMsGetWalletTreeResponseNode>>;
};

export type LedgersMsGetWalletTreeResponseNode = {
  __typename?: "LedgersMsGetWalletTreeResponseNode";
  SettingsIdData?: Maybe<LedgersMsSearchWalletSettingsResponseData>;
  label: Scalars["String"]["output"];
  level: Scalars["Int"]["output"];
  originalLabel: Scalars["String"]["output"];
  parentId: Scalars["ID"]["output"];
  settingsId: Scalars["ID"]["output"];
  walletId: Scalars["ID"]["output"];
};

export type LedgersMsListCountriesResponse = {
  __typename?: "LedgersMsListCountriesResponse";
  data?: Maybe<Array<LedgersMsListCountriesResponseCountry>>;
};

export type LedgersMsListCountriesResponseCountry = {
  __typename?: "LedgersMsListCountriesResponseCountry";
  code: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type LedgersMsListFuelCodesResponse = {
  __typename?: "LedgersMsListFuelCodesResponse";
  data?: Maybe<Array<LedgersMsListFuelCodesResponseFuelCode>>;
};

export type LedgersMsListFuelCodesResponseFuelCode = {
  __typename?: "LedgersMsListFuelCodesResponseFuelCode";
  code: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type LedgersMsNestedFieldData = {
  __typename?: "LedgersMsNestedFieldData";
  decorator: Scalars["String"]["output"];
  defaultValue: Scalars["String"]["output"];
  hidden: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  options?: Maybe<Array<LedgersMsNestedNestedFormOption>>;
  required: Scalars["Boolean"]["output"];
  type: Scalars["String"]["output"];
  validators?: Maybe<Array<LedgersMsFieldValidator>>;
  value: Scalars["String"]["output"];
  writable: Scalars["Boolean"]["output"];
};

export type LedgersMsNestedNestedFieldData = {
  __typename?: "LedgersMsNestedNestedFieldData";
  decorator: LedgersMsNestedNestedFieldDataDecorator;
  defaultValue: Scalars["String"]["output"];
  hidden: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
  type: Scalars["String"]["output"];
  validators?: Maybe<Array<LedgersMsFieldValidator>>;
  value: Scalars["String"]["output"];
  writable: Scalars["Boolean"]["output"];
};

export enum LedgersMsNestedNestedFieldDataDecorator {
  DaysOfTheWeekPicker = "DAYS_OF_THE_WEEK_PICKER",
  Native = "NATIVE",
}

export type LedgersMsNestedNestedFormOption = {
  __typename?: "LedgersMsNestedNestedFormOption";
  id: Scalars["String"]["output"];
  impactedFields?: Maybe<Array<LedgersMsNestedNestedFieldData>>;
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

/**  Handle the order creation event.  */
export type LedgersMsOnCreateLedgerOrderRequest = {
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Handle the order creation event.  */
export type LedgersMsOnUpdateLedgerOrderRequest = {
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsPagedPagination = {
  __typename?: "LedgersMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type LedgersMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Resend the PIN code for a ledger  */
export type LedgersMsResendPinCodeRequest = {
  /**  The ledger id  */
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsResendPinCodeRequestWho = {
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LedgersMsResendPinCodeResponse = {
  __typename?: "LedgersMsResendPinCodeResponse";
  httpStatusCode: Scalars["Int"]["output"];
};

export type LedgersMsSearchGroupsRequest = {
  filters?: InputMaybe<LedgersMsSearchGroupsRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<LedgersMsSearchGroupsRequestSort>>>;
};

export type LedgersMsSearchGroupsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type LedgersMsSearchGroupsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchGroupsRequestSortField>;
};

export enum LedgersMsSearchGroupsRequestSortField {
  Code = "code",
}

export type LedgersMsSearchGroupsResponse = {
  __typename?: "LedgersMsSearchGroupsResponse";
  data?: Maybe<Array<LedgersMsSearchGroupsResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchGroupsResponseData = {
  __typename?: "LedgersMsSearchGroupsResponseData";
  /**  The unique code  */
  code: Scalars["String"]["output"];
  /**  The product code comment  */
  codeHint: Scalars["String"]["output"];
  /**  The product id  */
  id: Scalars["ID"]["output"];
};

/**  Get account preferences related to the ledgers domain  */
export type LedgersMsSearchLedgersAccountPreferencesRequest = {
  filters?: InputMaybe<LedgersMsSearchLedgersAccountPreferencesRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<LedgersMsSearchLedgersAccountPreferencesRequestSort>>
  >;
};

export type LedgersMsSearchLedgersAccountPreferencesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type LedgersMsSearchLedgersAccountPreferencesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchLedgersAccountPreferencesRequestSortField>;
};

export enum LedgersMsSearchLedgersAccountPreferencesRequestSortField {
  Id = "id",
}

export type LedgersMsSearchLedgersAccountPreferencesResponse = {
  __typename?: "LedgersMsSearchLedgersAccountPreferencesResponse";
  data?: Maybe<Array<LedgersMsSearchLedgersAccountPreferencesResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchLedgersAccountPreferencesResponseData = {
  __typename?: "LedgersMsSearchLedgersAccountPreferencesResponseData";
  DefaultWalletIdData?: Maybe<LedgersMsSearchWalletsResponseData>;
  IdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  canBlockLedger: Scalars["Boolean"]["output"];
  canCancelLedgerOrder: Scalars["Boolean"]["output"];
  canCreateLedger: Scalars["Boolean"]["output"];
  canListTransactions: Scalars["Boolean"]["output"];
  canUpdateLedger: Scalars["Boolean"]["output"];
  /**  The optional default wallet  */
  defaultWalletId: Scalars["ID"]["output"];
  /**  The account ID  */
  id: Scalars["ID"]["output"];
};

/**  Search among ledgers  */
export type LedgersMsSearchLedgersRequest = {
  filters?: InputMaybe<LedgersMsSearchLedgersRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<LedgersMsSearchLedgersRequestSort>>>;
};

export type LedgersMsSearchLedgersRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  panIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  pans?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pcomType?: InputMaybe<LedgersMsSearchLedgersRequestFiltersPcomType>;
  statusIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  supportIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  walletId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum LedgersMsSearchLedgersRequestFiltersPcomType {
  Badge = "BADGE",
  Card = "CARD",
  PcomTypeUnknown = "PCOM_TYPE_UNKNOWN",
}

export type LedgersMsSearchLedgersRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchLedgersRequestSortField>;
};

export enum LedgersMsSearchLedgersRequestSortField {
  Name = "name",
  Rank = "rank",
  RegistrationNumber = "registration_number",
  StatusId = "status_id",
  TypeId = "type_id",
  UpdatedTs = "updated_ts",
}

export type LedgersMsSearchLedgersResponse = {
  __typename?: "LedgersMsSearchLedgersResponse";
  data?: Maybe<Array<LedgersMsSearchLedgersResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchLedgersResponseData = {
  __typename?: "LedgersMsSearchLedgersResponseData";
  LastOpenOrderIdData?: Maybe<LedgersMsSearchOrdersResponseData>;
  StatusIdData?: Maybe<LedgersMsSearchStatusesResponseData>;
  TypeIdData?: Maybe<LedgersMsSearchTypesResponseData>;
  WalletIdData?: Maybe<LedgersMsSearchWalletsResponseData>;
  /**  An optional internal company reference  */
  complementaryInfo: Scalars["String"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  The ledger EV Id  */
  evId: Scalars["String"]["output"];
  /**  When the ledger expires  */
  expiresTs: Scalars["Int64"]["output"];
  /**  Type of fuel that can be bought with that ledger  */
  fuelPackageCode: Scalars["String"]["output"];
  /**  The ledger ID  */
  id: Scalars["ID"]["output"];
  /**  Type of input for security purposes when using card  */
  iptbehaviourCode: Scalars["String"]["output"];
  /**  Is the card for an electric package  */
  isElectricPackage: Scalars["Boolean"]["output"];
  isModifiable: Scalars["Boolean"]["output"];
  isPinRequestActive: Scalars["Boolean"]["output"];
  isPinResendable: Scalars["Boolean"]["output"];
  lastOpenOrderId: Scalars["ID"]["output"];
  /**  The ledger's label  */
  name: Scalars["String"]["output"];
  nextLedgerId: Scalars["ID"]["output"];
  /**  The ledger's label  */
  originalName: Scalars["String"]["output"];
  /**  The obfuscated PAN  */
  pan: Scalars["String"]["output"];
  /**  Target: pcom_type (Card Or Badge)  */
  pcomType: LedgersMsSearchLedgersResponseDataPcomType;
  previousLedgerId: Scalars["ID"]["output"];
  /**  The version number of the ledger, incremented on each update.  */
  rank: Scalars["Int"]["output"];
  /**  An optional custom name on the card  */
  registrationNumber: Scalars["String"]["output"];
  renewalCancellable: Scalars["Boolean"]["output"];
  /**  Service Available for that ledger  */
  servicePackageCode: Scalars["String"]["output"];
  /**  The ledger corresponding group code  */
  shortCode: Scalars["String"]["output"];
  /**  The status  */
  statusId: Scalars["ID"]["output"];
  /**  Toll service  */
  tollPackageCode: Scalars["String"]["output"];
  /**  The type  */
  typeId: Scalars["ID"]["output"];
  updatedTs: Scalars["Int64"]["output"];
  /**  The related wallet  */
  walletId: Scalars["ID"]["output"];
};

export enum LedgersMsSearchLedgersResponseDataPcomType {
  Badge = "BADGE",
  Card = "CARD",
  PcomTypeUnknown = "PCOM_TYPE_UNKNOWN",
}

/**  Search among orders  */
export type LedgersMsSearchOrdersRequest = {
  filters?: InputMaybe<LedgersMsSearchOrdersRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<LedgersMsSearchOrdersRequestSort>>>;
};

export type LedgersMsSearchOrdersRequestFilters = {
  blockedLedgerId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<LedgersMsSearchOrdersRequestFiltersStatus>;
  type?: InputMaybe<LedgersMsSearchOrdersRequestFiltersType>;
};

export enum LedgersMsSearchOrdersRequestFiltersStatus {
  Failed = "FAILED",
  Initialized = "INITIALIZED",
  OrderStatusUnknown = "ORDER_STATUS_UNKNOWN",
  Processing = "PROCESSING",
  Successful = "SUCCESSFUL",
}

export enum LedgersMsSearchOrdersRequestFiltersType {
  Block = "BLOCK",
  Cancel = "CANCEL",
  Create = "CREATE",
  CreateCancel = "CREATE_CANCEL",
  OrderTypeUnknown = "ORDER_TYPE_UNKNOWN",
  Renew = "RENEW",
  ResendPinCode = "RESEND_PIN_CODE",
  Update = "UPDATE",
}

export type LedgersMsSearchOrdersRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchOrdersRequestSortField>;
};

export enum LedgersMsSearchOrdersRequestSortField {
  CreatedTs = "created_ts",
  Status = "status",
  TerminatedTs = "terminated_ts",
  Type = "type",
  UpdatedTs = "updated_ts",
}

export type LedgersMsSearchOrdersResponse = {
  __typename?: "LedgersMsSearchOrdersResponse";
  data?: Maybe<Array<LedgersMsSearchOrdersResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchOrdersResponseData = {
  __typename?: "LedgersMsSearchOrdersResponseData";
  BlockedLedgerIdData?: Maybe<LedgersMsSearchLedgersResponseData>;
  LedgerIdData?: Maybe<LedgersMsSearchLedgersResponseData>;
  LedgerTypeIdData?: Maybe<LedgersMsSearchTypesResponseData>;
  UserIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  WalletIdData?: Maybe<LedgersMsSearchWalletsResponseData>;
  /**  The optional blocked ledger id, used during renewal order to remember the blocked ledger...  */
  blockedLedgerId: Scalars["ID"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  The reason code when the order failed  */
  failedReason: Scalars["String"]["output"];
  /**  The order ID  */
  id: Scalars["ID"]["output"];
  /**  The related ledger, when the order has been successfully terminated.  */
  ledgerId: Scalars["ID"]["output"];
  /**  The requested type  */
  ledgerTypeId: Scalars["ID"]["output"];
  /**  when the ledger will be renewed  */
  renewTs: Scalars["String"]["output"];
  status: LedgersMsSearchOrdersResponseDataStatus;
  /**  When the order has been terminated  */
  terminatedTs: Scalars["Int64"]["output"];
  /**  The kind of order  */
  type: LedgersMsSearchOrdersResponseDataType;
  updatedTs: Scalars["Int64"]["output"];
  /**  Who created the order  */
  userId: Scalars["ID"]["output"];
  /**  The related wallet  */
  walletId: Scalars["ID"]["output"];
};

export enum LedgersMsSearchOrdersResponseDataStatus {
  Failed = "FAILED",
  Initialized = "INITIALIZED",
  OrderStatusUnknown = "ORDER_STATUS_UNKNOWN",
  Processing = "PROCESSING",
  Successful = "SUCCESSFUL",
}

export enum LedgersMsSearchOrdersResponseDataType {
  Block = "BLOCK",
  Cancel = "CANCEL",
  Create = "CREATE",
  CreateCancel = "CREATE_CANCEL",
  OrderTypeUnknown = "ORDER_TYPE_UNKNOWN",
  Renew = "RENEW",
  ResendPinCode = "RESEND_PIN_CODE",
  Update = "UPDATE",
}

/**  Search product categories  */
export type LedgersMsSearchProductCategoriesRequest = {
  filters?: InputMaybe<LedgersMsSearchProductCategoriesRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<LedgersMsSearchProductCategoriesRequestSort>>
  >;
};

export type LedgersMsSearchProductCategoriesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type LedgersMsSearchProductCategoriesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchProductCategoriesRequestSortField>;
};

export enum LedgersMsSearchProductCategoriesRequestSortField {
  Code = "code",
}

export type LedgersMsSearchProductCategoriesResponse = {
  __typename?: "LedgersMsSearchProductCategoriesResponse";
  data?: Maybe<Array<LedgersMsSearchProductCategoriesResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchProductCategoriesResponseData = {
  __typename?: "LedgersMsSearchProductCategoriesResponseData";
  /**  The unique code  */
  code: Scalars["String"]["output"];
  /**  The product category id  */
  id: Scalars["ID"]["output"];
};

/**  Search product  */
export type LedgersMsSearchProductsRequest = {
  filters?: InputMaybe<LedgersMsSearchProductsRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<LedgersMsSearchProductsRequestSort>>>;
};

export type LedgersMsSearchProductsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type LedgersMsSearchProductsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchProductsRequestSortField>;
};

export enum LedgersMsSearchProductsRequestSortField {
  Code = "code",
}

export type LedgersMsSearchProductsResponse = {
  __typename?: "LedgersMsSearchProductsResponse";
  data?: Maybe<Array<LedgersMsSearchProductsResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchProductsResponseData = {
  __typename?: "LedgersMsSearchProductsResponseData";
  /**  The unique code  */
  code: Scalars["String"]["output"];
  /**  The product id  */
  id: Scalars["ID"]["output"];
};

/**  Search ledger statuses  */
export type LedgersMsSearchStatusesRequest = {
  filters?: InputMaybe<LedgersMsSearchStatusesRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<LedgersMsSearchStatusesRequestSort>>>;
};

export type LedgersMsSearchStatusesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type LedgersMsSearchStatusesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchStatusesRequestSortField>;
};

export enum LedgersMsSearchStatusesRequestSortField {
  Code = "code",
}

export type LedgersMsSearchStatusesResponse = {
  __typename?: "LedgersMsSearchStatusesResponse";
  data?: Maybe<Array<LedgersMsSearchStatusesResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchStatusesResponseData = {
  __typename?: "LedgersMsSearchStatusesResponseData";
  /**  True if ledgers of this status can be blocked or renewed (opposable).  */
  blockable: Scalars["Boolean"]["output"];
  /**  The unique code  */
  code: Scalars["String"]["output"];
  /**  The system code, used for internal codes  */
  codeSystem: Scalars["String"]["output"];
  /**  The product id  */
  id: Scalars["ID"]["output"];
};

/**  Search among transactions  */
export type LedgersMsSearchTransactionsRequest = {
  filters?: InputMaybe<LedgersMsSearchTransactionsRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<LedgersMsSearchTransactionsRequestSort>>>;
};

export type LedgersMsSearchTransactionsRequestFilters = {
  fromDate?: InputMaybe<Scalars["String"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  invoiceId?: InputMaybe<Scalars["ID"]["input"]>;
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
  productCategoryId?: InputMaybe<Scalars["ID"]["input"]>;
  tillDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type LedgersMsSearchTransactionsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchTransactionsRequestSortField>;
};

export enum LedgersMsSearchTransactionsRequestSortField {
  AmountIncludingVat = "amount_including_vat",
  Billable = "billable",
  Date = "date",
  DateTs = "date_ts",
  InvoiceId = "invoice_id",
  Label = "label",
  Number = "number",
  ProductCategoryId = "product_category_id",
  UpdatedTs = "updated_ts",
}

export type LedgersMsSearchTransactionsResponse = {
  __typename?: "LedgersMsSearchTransactionsResponse";
  data?: Maybe<Array<LedgersMsSearchTransactionsResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchTransactionsResponseData = {
  __typename?: "LedgersMsSearchTransactionsResponseData";
  InvoiceIdData?: Maybe<BillingMsSearchInvoicesResponseData>;
  LedgerIdData?: Maybe<LedgersMsSearchLedgersResponseData>;
  ProductCategoryIdData?: Maybe<LedgersMsSearchProductCategoriesResponseData>;
  ProductIdData?: Maybe<LedgersMsSearchProductsResponseData>;
  /**  The currency of the related amounts  */
  amountCurrency: Scalars["String"]["output"];
  /**  The total amount including the VAT (normalized in an integer, check the precision related to the currency)  */
  amountIncludingVat: Scalars["Float"]["output"];
  /**  The related toll roads class when the transaction was made with a badge, if there is such.  */
  badgeTollClass: Scalars["String"]["output"];
  /**  Whether or not this transaction should be paired to an invoice.  */
  billable: Scalars["Boolean"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**
   *  The date of the transaction in ISO 8601 format
   * Deprecated
   */
  date: Scalars["String"]["output"];
  /**  The date of the transaction  */
  dateTs: Scalars["Int64"]["output"];
  /**  The transaction ID  */
  id: Scalars["ID"]["output"];
  /**  The related invoice  */
  invoiceId: Scalars["String"]["output"];
  /**  The site name of the transaction  */
  label: Scalars["String"]["output"];
  /**  The related ledger  */
  ledgerId: Scalars["ID"]["output"];
  /**  The transaction unique number (per account)  */
  number: Scalars["String"]["output"];
  /**  The related product category  */
  productCategoryId: Scalars["String"]["output"];
  /**  The related product  */
  productId: Scalars["String"]["output"];
  /**  The quantity of the transaction  */
  quantity: Scalars["Float"]["output"];
  /**  The unit of the transaction  */
  unit: Scalars["String"]["output"];
  /**  The transaction unit price  */
  unitPrice: Scalars["Float"]["output"];
  updatedTs: Scalars["Int64"]["output"];
};

/**  Search ledger types  */
export type LedgersMsSearchTypesRequest = {
  filters?: InputMaybe<LedgersMsSearchTypesRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<LedgersMsSearchTypesRequestSort>>>;
};

export type LedgersMsSearchTypesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type LedgersMsSearchTypesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchTypesRequestSortField>;
};

export enum LedgersMsSearchTypesRequestSortField {
  Code = "code",
}

export type LedgersMsSearchTypesResponse = {
  __typename?: "LedgersMsSearchTypesResponse";
  data?: Maybe<Array<LedgersMsSearchTypesResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchTypesResponseData = {
  __typename?: "LedgersMsSearchTypesResponseData";
  GroupIdData?: Maybe<LedgersMsSearchGroupsResponseData>;
  /**  The unique code  */
  code: Scalars["String"]["output"];
  /**  The ledger code comment  */
  codeHint: Scalars["String"]["output"];
  /**  The group the type is linked to  */
  groupId: Scalars["ID"]["output"];
  /**  The ledger id  */
  id: Scalars["ID"]["output"];
};

/**  Fetch wallet settings  */
export type LedgersMsSearchWalletSettingsRequest = {
  filters?: InputMaybe<LedgersMsSearchWalletSettingsRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<LedgersMsSearchWalletSettingsRequestSort>>
  >;
};

export type LedgersMsSearchWalletSettingsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type LedgersMsSearchWalletSettingsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchWalletSettingsRequestSortField>;
};

export enum LedgersMsSearchWalletSettingsRequestSortField {
  Id = "id",
}

export type LedgersMsSearchWalletSettingsResponse = {
  __typename?: "LedgersMsSearchWalletSettingsResponse";
  data?: Maybe<Array<LedgersMsSearchWalletSettingsResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchWalletSettingsResponseData = {
  __typename?: "LedgersMsSearchWalletSettingsResponseData";
  SectorIdData?: Maybe<SectorsMsSearchSectorsResponseData>;
  accountNumber: Scalars["String"]["output"];
  divisionAccountLabel: Scalars["String"]["output"];
  divisionAccountNumber: Scalars["String"]["output"];
  /**  The wallet settings ID  */
  id: Scalars["ID"]["output"];
  sectorId: Scalars["ID"]["output"];
  supportAccountLabel: Scalars["String"]["output"];
  supportAccountNumber: Scalars["String"]["output"];
};

/**  Search among wallets  */
export type LedgersMsSearchWalletsRequest = {
  filters?: InputMaybe<LedgersMsSearchWalletsRequestFilters>;
  paging?: InputMaybe<LedgersMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<LedgersMsSearchWalletsRequestSort>>>;
};

export type LedgersMsSearchWalletsRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  showOrderableWallets?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LedgersMsSearchWalletsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<LedgersMsSearchWalletsRequestSortField>;
};

export enum LedgersMsSearchWalletsRequestSortField {
  Id = "id",
  Label = "label",
  UpdatedTs = "updated_ts",
}

export type LedgersMsSearchWalletsResponse = {
  __typename?: "LedgersMsSearchWalletsResponse";
  data?: Maybe<Array<LedgersMsSearchWalletsResponseData>>;
  pagination: LedgersMsPagedPagination;
};

export type LedgersMsSearchWalletsResponseData = {
  __typename?: "LedgersMsSearchWalletsResponseData";
  AccountIdData?: Maybe<LedgersMsSearchLedgersAccountPreferencesResponseData>;
  SettingsIdData?: Maybe<LedgersMsSearchWalletSettingsResponseData>;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  /**  The wallet ID  */
  id: Scalars["ID"]["output"];
  /**  The wallet name  */
  label: Scalars["String"]["output"];
  /**  The wallet label provided by CardPro  */
  originalLabel: Scalars["String"]["output"];
  /**  The related settings wallet, root level do not have such  */
  settingsId: Scalars["ID"]["output"];
};

/**  Create an order to modify/update a Ledger  */
export type LedgersMsUpdateLedgerOrderRequest = {
  ledgerId?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The raw filled up data to be sent to the ledger provider.  */
  rawFormData?: InputMaybe<Scalars["String"]["input"]>;
};

export type LedgersMsUpdateLedgerOrderResponse = {
  __typename?: "LedgersMsUpdateLedgerOrderResponse";
  orderId: Scalars["ID"]["output"];
};

export type LedgersMsValidatorOptionAuthorizedWeekDaysSelection = {
  __typename?: "LedgersMsValidatorOptionAuthorizedWeekDaysSelection";
  authorizedWeekDaysRegex: Scalars["String"]["output"];
};

export type LedgersMsValidatorOptionDigits = {
  __typename?: "LedgersMsValidatorOptionDigits";
  digitsRegex: Scalars["String"]["output"];
};

export type LedgersMsValidatorOptionLowerCase = {
  __typename?: "LedgersMsValidatorOptionLowerCase";
  isLowerCase: Scalars["Boolean"]["output"];
};

export type LedgersMsValidatorOptionMaxLength = {
  __typename?: "LedgersMsValidatorOptionMaxLength";
  max: Scalars["Int"]["output"];
};

export type LedgersMsValidatorOptionMinLength = {
  __typename?: "LedgersMsValidatorOptionMinLength";
  min: Scalars["Int"]["output"];
};

export type LedgersMsValidatorOptionUpperCase = {
  __typename?: "LedgersMsValidatorOptionUpperCase";
  isUpperCase: Scalars["Boolean"]["output"];
};

export type LedgersMsValidatorOptions = {
  __typename?: "LedgersMsValidatorOptions";
  authorizedWeekDaysSelection: LedgersMsValidatorOptionAuthorizedWeekDaysSelection;
  digits: LedgersMsValidatorOptionDigits;
  lowerCase: LedgersMsValidatorOptionLowerCase;
  maxLength: LedgersMsValidatorOptionMaxLength;
  minLength: LedgersMsValidatorOptionMinLength;
  upperCase: LedgersMsValidatorOptionUpperCase;
};

export type MediaMsPagedPagination = {
  __typename?: "MediaMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type MediaMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Search among document types  */
export type MediaMsSearchDocumentTypesRequest = {
  filters?: InputMaybe<MediaMsSearchDocumentTypesRequestFilters>;
  paging?: InputMaybe<MediaMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<MediaMsSearchDocumentTypesRequestSort>>>;
};

export type MediaMsSearchDocumentTypesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type MediaMsSearchDocumentTypesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<MediaMsSearchDocumentTypesRequestSortField>;
};

export enum MediaMsSearchDocumentTypesRequestSortField {
  Code = "code",
}

export type MediaMsSearchDocumentTypesResponse = {
  __typename?: "MediaMsSearchDocumentTypesResponse";
  data?: Maybe<Array<MediaMsSearchDocumentTypesResponseData>>;
  pagination: MediaMsPagedPagination;
};

export type MediaMsSearchDocumentTypesResponseData = {
  __typename?: "MediaMsSearchDocumentTypesResponseData";
  /**  The type code  */
  code: Scalars["String"]["output"];
  /**  The ID of the document type  */
  id: Scalars["ID"]["output"];
};

/**  Search among documents  */
export type MediaMsSearchDocumentsRequest = {
  filters?: InputMaybe<MediaMsSearchDocumentsRequestFilters>;
  paging?: InputMaybe<MediaMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<MediaMsSearchDocumentsRequestSort>>>;
};

export type MediaMsSearchDocumentsRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  typeIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type MediaMsSearchDocumentsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<MediaMsSearchDocumentsRequestSortField>;
};

export enum MediaMsSearchDocumentsRequestSortField {
  Filename = "filename",
  UpdatedTs = "updated_ts",
}

export type MediaMsSearchDocumentsResponse = {
  __typename?: "MediaMsSearchDocumentsResponse";
  data?: Maybe<Array<MediaMsSearchDocumentsResponseData>>;
  pagination: MediaMsPagedPagination;
};

export type MediaMsSearchDocumentsResponseData = {
  __typename?: "MediaMsSearchDocumentsResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  TypeIdData?: Maybe<MediaMsSearchDocumentTypesResponseData>;
  UserIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  /**  The content type of the given file  */
  contentType: Scalars["String"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  When the document was downloaded  */
  downloadedTs: Scalars["Int64"]["output"];
  /**  The document expiry date  */
  expiresTs: Scalars["Int64"]["output"];
  /**  The original file name  */
  filename: Scalars["String"]["output"];
  /**  The ID of the document  */
  id: Scalars["ID"]["output"];
  /**  The size in bytes of the file  */
  size: Scalars["Int64"]["output"];
  /**  The document type  */
  typeId: Scalars["ID"]["output"];
  /**  Who created this document if there is such  */
  userId: Scalars["ID"]["output"];
};

/**  Search among capital statements (FR: relevé de capitalisation)  */
export type MediaMsSearchEndOfYearStatementDocumentsRequest = {
  filters?: InputMaybe<MediaMsSearchEndOfYearStatementDocumentsRequestFilters>;
  paging?: InputMaybe<MediaMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<MediaMsSearchEndOfYearStatementDocumentsRequestSort>>
  >;
};

export type MediaMsSearchEndOfYearStatementDocumentsRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type MediaMsSearchEndOfYearStatementDocumentsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<MediaMsSearchEndOfYearStatementDocumentsRequestSortField>;
};

export enum MediaMsSearchEndOfYearStatementDocumentsRequestSortField {
  CreatedTs = "created_ts",
  Filename = "filename",
  UpdatedTs = "updated_ts",
}

export type MediaMsSearchEndOfYearStatementDocumentsResponse = {
  __typename?: "MediaMsSearchEndOfYearStatementDocumentsResponse";
  data?: Maybe<Array<MediaMsSearchEndOfYearStatementDocumentsResponseData>>;
  pagination: MediaMsPagedPagination;
};

export type MediaMsSearchEndOfYearStatementDocumentsResponseData = {
  __typename?: "MediaMsSearchEndOfYearStatementDocumentsResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  TypeIdData?: Maybe<MediaMsSearchDocumentTypesResponseData>;
  UserIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  /**  The content type of the given file  */
  contentType: Scalars["String"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  When the document was downloaded  */
  downloadedTs: Scalars["Int64"]["output"];
  /**  The document expiry date  */
  expiresTs: Scalars["Int64"]["output"];
  /**  The original file name  */
  filename: Scalars["String"]["output"];
  /**  The ID of the document  */
  id: Scalars["ID"]["output"];
  /**  The size in bytes of the file  */
  size: Scalars["Int64"]["output"];
  /**  The document type  */
  typeId: Scalars["ID"]["output"];
  /**  Who created this document if there is such  */
  userId: Scalars["ID"]["output"];
};

/**  Get account preferences related to the media domain  */
export type MediaMsSearchMediaAccountPreferencesRequest = {
  filters?: InputMaybe<MediaMsSearchMediaAccountPreferencesRequestFilters>;
  paging?: InputMaybe<MediaMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<MediaMsSearchMediaAccountPreferencesRequestSort>>
  >;
};

export type MediaMsSearchMediaAccountPreferencesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type MediaMsSearchMediaAccountPreferencesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<MediaMsSearchMediaAccountPreferencesRequestSortField>;
};

export enum MediaMsSearchMediaAccountPreferencesRequestSortField {
  Id = "id",
}

export type MediaMsSearchMediaAccountPreferencesResponse = {
  __typename?: "MediaMsSearchMediaAccountPreferencesResponse";
  data?: Maybe<Array<MediaMsSearchMediaAccountPreferencesResponseData>>;
  pagination: MediaMsPagedPagination;
};

export type MediaMsSearchMediaAccountPreferencesResponseData = {
  __typename?: "MediaMsSearchMediaAccountPreferencesResponseData";
  IdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  /**  The account ID  */
  id: Scalars["ID"]["output"];
};

/**  Search among tokens  */
export type MediaMsSearchTokensRequest = {
  filters?: InputMaybe<MediaMsSearchTokensRequestFilters>;
  paging?: InputMaybe<MediaMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<MediaMsSearchTokensRequestSort>>>;
};

export type MediaMsSearchTokensRequestFilters = {
  documentId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MediaMsSearchTokensRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<MediaMsSearchTokensRequestSortField>;
};

export enum MediaMsSearchTokensRequestSortField {
  Counter = "counter",
}

export type MediaMsSearchTokensResponse = {
  __typename?: "MediaMsSearchTokensResponse";
  data?: Maybe<Array<MediaMsSearchTokensResponseData>>;
  pagination: MediaMsPagedPagination;
};

export type MediaMsSearchTokensResponseData = {
  __typename?: "MediaMsSearchTokensResponseData";
  /**  How may time the token has been used, leading to a file download.  */
  counter: Scalars["Int64"]["output"];
  /**  The id of the token  */
  id: Scalars["ID"]["output"];
};

export type MiddleMsPagedPagination = {
  __typename?: "MiddleMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type MiddleMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Get account preferences related to the middle domain  */
export type MiddleMsSearchMiddleAccountPreferencesRequest = {
  filters?: InputMaybe<MiddleMsSearchMiddleAccountPreferencesRequestFilters>;
  paging?: InputMaybe<MiddleMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<MiddleMsSearchMiddleAccountPreferencesRequestSort>>
  >;
};

export type MiddleMsSearchMiddleAccountPreferencesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type MiddleMsSearchMiddleAccountPreferencesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<MiddleMsSearchMiddleAccountPreferencesRequestSortField>;
};

export enum MiddleMsSearchMiddleAccountPreferencesRequestSortField {
  Id = "id",
}

export type MiddleMsSearchMiddleAccountPreferencesResponse = {
  __typename?: "MiddleMsSearchMiddleAccountPreferencesResponse";
  data?: Maybe<Array<MiddleMsSearchMiddleAccountPreferencesResponseData>>;
  pagination: MiddleMsPagedPagination;
};

export type MiddleMsSearchMiddleAccountPreferencesResponseData = {
  __typename?: "MiddleMsSearchMiddleAccountPreferencesResponseData";
  IdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  /**  The CardPro user login  */
  cardProUserLogin: Scalars["String"]["output"];
  /**  The customer credit consumption  */
  creditConsumption: Scalars["Float"]["output"];
  /**  When we checked the customer credit for the last time  */
  creditLastCheckedTs: Scalars["Int64"]["output"];
  /**  The customer credit limit  */
  creditLimit: Scalars["Float"]["output"];
  /**  The account ID  */
  id: Scalars["ID"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  /**  Create a new agency  */
  AccountsMsCreateAgency: AccountsMsCreateAgencyResponse;
  /**
   *  Creates a roles profile
   *
   * When a roles profile is created under another one, the roles selection is restricted to the parent roles profile roles.
   */
  AccountsMsCreateRolesProfile: AccountsMsCreateRolesProfileResponse;
  /**
   *  Deletes a roles profile
   *
   * Only unused roles profile can be deleted.
   */
  AccountsMsDeleteRolesProfile: AccountsMsDeleteRolesProfileResponse;
  /**
   *  Disable a privilege
   *
   * The related user won't have access to this account anymore. Nothing happens if the privilege is already disabled.
   */
  AccountsMsDisable?: Maybe<Scalars["Void"]["output"]>;
  /**  Disable support user  */
  AccountsMsDisableSupportUser?: Maybe<Scalars["Void"]["output"]>;
  /**
   *  Enable a privilege
   *
   * The related user will be granted back his access to this account. Nothing happens if the privilege is already enabled.
   */
  AccountsMsEnable?: Maybe<Scalars["Void"]["output"]>;
  /**  Enable support user  */
  AccountsMsEnableSupportUser?: Maybe<Scalars["Void"]["output"]>;
  /**
   *  Extend a support session
   *
   * Extend a session for a support operator.
   */
  AccountsMsExtendSupport: AccountsMsExtendSupportResponse;
  /**
   *  Forget a privilege
   *
   * Deletes a privilege forever.
   */
  AccountsMsForget?: Maybe<Scalars["Void"]["output"]>;
  /**  Invite support user  */
  AccountsMsInviteSupportUser: AccountsMsInviteSupportUserResponse;
  /**
   *  Invite a user.
   *
   * Create a privilege for the given user in the current account. A notification is sent to the user.
   * When the user do not exist, we send a welcome notification. Otherwise, we send a classical invitation notification.
   * A new user is created with the same timezone and locale of the current user when the account preferred_locale is
   * not set.
   */
  AccountsMsInviteUser: AccountsMsInviteUserResponse;
  /**  List the supported locales  */
  AccountsMsListLocales: AccountsMsListLocalesResponse;
  /**
   *  Find a user.
   *
   * Search a user in the user book. Multiple results may be returned.
   */
  AccountsMsLookupUser: AccountsMsLookupUserResponse;
  /**  Resend invitation  */
  AccountsMsResendInviteUserNotification: AccountsMsResendInviteUserNotificationResponse;
  /**  Defines the main account of the current user.  */
  AccountsMsSetDefaultAccount?: Maybe<Scalars["Void"]["output"]>;
  /**
   *  Start a support session
   *
   * Start a session for a support operator, a session id will be created for a defined duration
   */
  AccountsMsStartSupport: AccountsMsStartSupportResponse;
  /**  Link the user's privilege to a roles profile  */
  AccountsMsSubscribePrivilege?: Maybe<Scalars["Void"]["output"]>;
  /**  Unlink user's privilege from from a roles profile  */
  AccountsMsUnsubscribePrivilege?: Maybe<Scalars["Void"]["output"]>;
  /**  Update the current user  */
  AccountsMsUpdateMe?: Maybe<Scalars["Void"]["output"]>;
  AccountsMsUpdateMyPersonalData?: Maybe<Scalars["Void"]["output"]>;
  AccountsMsUpdateMyPersonalPreferences?: Maybe<Scalars["Void"]["output"]>;
  AccountsMsUpdateRolesProfile: AccountsMsUpdateRolesProfileResponse;
  /**  Update support user. They must be active in order to be updated.  */
  AccountsMsUpdateSupportUser?: Maybe<Scalars["Void"]["output"]>;
  /**  Assign a collaborator to a ledger  */
  AssignmentsMsAssignCollaborator: AssignmentsMsAssignCollaboratorResponse;
  /**  Assign a vehicle to a ledger  */
  AssignmentsMsAssignVehicle: AssignmentsMsAssignVehicleResponse;
  /**  Delete assignment  */
  AssignmentsMsDeleteAssignment?: Maybe<Scalars["Void"]["output"]>;
  /**  Update assignment dates  */
  AssignmentsMsUpdatePeriod?: Maybe<Scalars["Void"]["output"]>;
  /**  Commit a payment  */
  BillingMsCommitPayment?: Maybe<Scalars["Void"]["output"]>;
  /**  Create a guarantee payment  */
  BillingMsCreateGuaranteePayment: BillingMsCreateGuaranteePaymentResponse;
  /**  Create a invoice payment  */
  BillingMsCreateInvoicePayment: BillingMsCreateInvoicePaymentResponse;
  /**  Create a Tid report.  */
  BillingMsCreateTidReport: BillingMsCreateTidReportResponse;
  /**  Archive collaborator  */
  CollaboratorsMsArchiveCollaborator?: Maybe<Scalars["Void"]["output"]>;
  /**  Create a collaborator  */
  CollaboratorsMsCreateCollaborator: CollaboratorsMsCreateCollaboratorResponse;
  /**
   *  Link a driver code to a collaborator. If the driver code does not exist, we will request our partner to create it.
   * The driver code will be in "creating" state.
   * We can also link an orphan driver code. It will be in "active" state.
   * We can link a driver code for a collaborator that has no driver code yet, or their drive code is blocked.
   */
  CollaboratorsMsLinkDriverCode?: Maybe<Scalars["Void"]["output"]>;
  /**
   *  Reedit all driver codes from the manager perspective.
   * We delegate the call to our partner that will send a paper mail with driver codes and card pin codes.
   * For a restricted manager, the driver codes to reedit are the one from the root sectors of their restricted sectors.
   */
  CollaboratorsMsReeditDriverCodes?: Maybe<Scalars["Void"]["output"]>;
  /**
   *  Our partner has accepted our request, the driver code will have the state "waiting to be blocked"
   * The driver code will be "blocked" when our partner will send us the relative state
   * If the partner does not accept our request (error), the driver code will stay at its previous state
   * Only an active driver code can be blocked.
   */
  CollaboratorsMsRequestToBlockDriverCode?: Maybe<Scalars["Void"]["output"]>;
  /**
   *  Our partner has accepted our request, the driver code will have the state "waiting to be unblocked"
   * The driver code will be "unblocked" when our partner will send us the relative state
   * If the partner does not accept our request (error), the driver code will stay at its previous state
   * Only a blocked driver code can be unblocked.
   */
  CollaboratorsMsRequestToUnBlockDriverCode?: Maybe<Scalars["Void"]["output"]>;
  /**  Unarchive collaborator  */
  CollaboratorsMsUnarchiveCollaborator?: Maybe<Scalars["Void"]["output"]>;
  /**  Updates a collaborator  */
  CollaboratorsMsUpdateCollaborator?: Maybe<Scalars["Void"]["output"]>;
  /**  Block (oppose) a card or a badge  */
  LedgersMsBlockLedger?: Maybe<Scalars["Void"]["output"]>;
  /**  Block (oppose) a card or a badge for system partners  */
  LedgersMsBlockLedgerAsPartner?: Maybe<Scalars["Void"]["output"]>;
  /**  Cancel the renewal of a ledger  */
  LedgersMsCancelLedgerRenewal?: Maybe<Scalars["Void"]["output"]>;
  /**  Create a consumption monitoring report.  */
  LedgersMsCreateConsumptionMonitoringReport: LedgersMsCreateConsumptionMonitoringReportResponse;
  /**  Create a cost tracking report.  */
  LedgersMsCreateCostTrackingReport: LedgersMsCreateCostTrackingReportResponse;
  /**  Create an order of a ledger.  */
  LedgersMsCreateLedgerOrder: LedgersMsCreateLedgerOrderResponse;
  /**  Create a mileage report.  */
  LedgersMsCreateMileageReport: LedgersMsCreateMileageReportResponse;
  /**  Create a price ceiling report.  */
  LedgersMsCreatePriceCeilingReport: LedgersMsCreatePriceCeilingReportResponse;
  /**  Create an export of all ledgers.  */
  LedgersMsExportSupportConfig: LedgersMsExportSupportConfigResponse;
  /**  Create an export of transactions.  */
  LedgersMsExportTransactions: LedgersMsExportTransactionsResponse;
  /**  Handle the order creation event.  */
  LedgersMsOnCreateLedgerOrder?: Maybe<Scalars["Void"]["output"]>;
  /**  Handle the order creation event.  */
  LedgersMsOnUpdateLedgerOrder?: Maybe<Scalars["Void"]["output"]>;
  /**  Resend the PIN code for a ledger  */
  LedgersMsResendPinCode?: Maybe<Scalars["Void"]["output"]>;
  /**  Create an order to modify/update a Ledger  */
  LedgersMsUpdateLedgerOrder: LedgersMsUpdateLedgerOrderResponse;
  NotificationsMsDisableMySubscriptions?: Maybe<Scalars["Void"]["output"]>;
  NotificationsMsEnableMySubscriptions?: Maybe<Scalars["Void"]["output"]>;
  /**  Set custom label of sector  */
  SectorsMsSetLabel?: Maybe<Scalars["Void"]["output"]>;
  /**  Archive vehicle  */
  VehiclesMsArchiveVehicle?: Maybe<Scalars["Void"]["output"]>;
  /**  Create a vehicle  */
  VehiclesMsCreateVehicle: VehiclesMsCreateVehicleResponse;
  /**  Unarchive vehicle  */
  VehiclesMsUnarchiveVehicle?: Maybe<Scalars["Void"]["output"]>;
  dummy?: Maybe<Scalars["String"]["output"]>;
};

export type MutationAccountsMsCreateAgencyArgs = {
  request?: InputMaybe<AccountsMsCreateAgencyRequest>;
};

export type MutationAccountsMsCreateRolesProfileArgs = {
  request?: InputMaybe<AccountsMsCreateRolesProfileRequest>;
};

export type MutationAccountsMsDeleteRolesProfileArgs = {
  request?: InputMaybe<AccountsMsDeleteRolesProfileRequest>;
};

export type MutationAccountsMsDisableArgs = {
  request?: InputMaybe<AccountsMsDisableRequest>;
};

export type MutationAccountsMsDisableSupportUserArgs = {
  request?: InputMaybe<AccountsMsDisableSupportUserRequest>;
};

export type MutationAccountsMsEnableArgs = {
  request?: InputMaybe<AccountsMsEnableRequest>;
};

export type MutationAccountsMsEnableSupportUserArgs = {
  request?: InputMaybe<AccountsMsEnableSupportUserRequest>;
};

export type MutationAccountsMsExtendSupportArgs = {
  request?: InputMaybe<AccountsMsExtendSupportRequest>;
};

export type MutationAccountsMsForgetArgs = {
  request?: InputMaybe<AccountsMsForgetRequest>;
};

export type MutationAccountsMsInviteSupportUserArgs = {
  request?: InputMaybe<AccountsMsInviteSupportUserRequest>;
};

export type MutationAccountsMsInviteUserArgs = {
  request?: InputMaybe<AccountsMsInviteUserRequest>;
};

export type MutationAccountsMsListLocalesArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type MutationAccountsMsLookupUserArgs = {
  request?: InputMaybe<AccountsMsLookupUserRequest>;
};

export type MutationAccountsMsResendInviteUserNotificationArgs = {
  request?: InputMaybe<AccountsMsResendInviteUserNotificationRequest>;
};

export type MutationAccountsMsSetDefaultAccountArgs = {
  request?: InputMaybe<AccountsMsSetDefaultAccountRequest>;
};

export type MutationAccountsMsStartSupportArgs = {
  request?: InputMaybe<AccountsMsStartSupportRequest>;
};

export type MutationAccountsMsSubscribePrivilegeArgs = {
  request?: InputMaybe<AccountsMsSubscribePrivilegeRequest>;
};

export type MutationAccountsMsUnsubscribePrivilegeArgs = {
  request?: InputMaybe<AccountsMsUnsubscribePrivilegeRequest>;
};

export type MutationAccountsMsUpdateMeArgs = {
  request?: InputMaybe<AccountsMsUpdateMeRequest>;
};

export type MutationAccountsMsUpdateMyPersonalDataArgs = {
  request?: InputMaybe<AccountsMsUpdateMyPersonalDataRequest>;
};

export type MutationAccountsMsUpdateMyPersonalPreferencesArgs = {
  request?: InputMaybe<AccountsMsUpdateMyPersonalPreferencesRequest>;
};

export type MutationAccountsMsUpdateRolesProfileArgs = {
  request?: InputMaybe<AccountsMsUpdateRolesProfileRequest>;
};

export type MutationAccountsMsUpdateSupportUserArgs = {
  request?: InputMaybe<AccountsMsUpdateSupportUserRequest>;
};

export type MutationAssignmentsMsAssignCollaboratorArgs = {
  request?: InputMaybe<AssignmentsMsAssignCollaboratorRequest>;
};

export type MutationAssignmentsMsAssignVehicleArgs = {
  request?: InputMaybe<AssignmentsMsAssignVehicleRequest>;
};

export type MutationAssignmentsMsDeleteAssignmentArgs = {
  request?: InputMaybe<AssignmentsMsDeleteAssignmentRequest>;
};

export type MutationAssignmentsMsUpdatePeriodArgs = {
  request?: InputMaybe<AssignmentsMsUpdatePeriodRequest>;
};

export type MutationBillingMsCommitPaymentArgs = {
  request?: InputMaybe<BillingMsCommitPaymentRequest>;
};

export type MutationBillingMsCreateGuaranteePaymentArgs = {
  request?: InputMaybe<BillingMsCreateGuaranteePaymentRequest>;
};

export type MutationBillingMsCreateInvoicePaymentArgs = {
  request?: InputMaybe<BillingMsCreateInvoicePaymentRequest>;
};

export type MutationBillingMsCreateTidReportArgs = {
  request?: InputMaybe<BillingMsCreateTidReportRequest>;
};

export type MutationCollaboratorsMsArchiveCollaboratorArgs = {
  request?: InputMaybe<CollaboratorsMsArchiveCollaboratorRequest>;
};

export type MutationCollaboratorsMsCreateCollaboratorArgs = {
  request?: InputMaybe<CollaboratorsMsCreateCollaboratorRequest>;
};

export type MutationCollaboratorsMsLinkDriverCodeArgs = {
  request?: InputMaybe<CollaboratorsMsLinkDriverCodeRequest>;
};

export type MutationCollaboratorsMsReeditDriverCodesArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type MutationCollaboratorsMsRequestToBlockDriverCodeArgs = {
  request?: InputMaybe<CollaboratorsMsRequestToBlockDriverCodeRequest>;
};

export type MutationCollaboratorsMsRequestToUnBlockDriverCodeArgs = {
  request?: InputMaybe<CollaboratorsMsRequestToUnBlockDriverCodeRequest>;
};

export type MutationCollaboratorsMsUnarchiveCollaboratorArgs = {
  request?: InputMaybe<CollaboratorsMsUnarchiveCollaboratorRequest>;
};

export type MutationCollaboratorsMsUpdateCollaboratorArgs = {
  request?: InputMaybe<CollaboratorsMsUpdateCollaboratorRequest>;
};

export type MutationLedgersMsBlockLedgerArgs = {
  request?: InputMaybe<LedgersMsBlockLedgerRequest>;
};

export type MutationLedgersMsBlockLedgerAsPartnerArgs = {
  request?: InputMaybe<LedgersMsBlockLedgerAsPartnerRequest>;
};

export type MutationLedgersMsCancelLedgerRenewalArgs = {
  request?: InputMaybe<LedgersMsCancelLedgerRenewalRequest>;
};

export type MutationLedgersMsCreateConsumptionMonitoringReportArgs = {
  request?: InputMaybe<LedgersMsCreateConsumptionMonitoringReportRequest>;
};

export type MutationLedgersMsCreateCostTrackingReportArgs = {
  request?: InputMaybe<LedgersMsCreateCostTrackingReportRequest>;
};

export type MutationLedgersMsCreateLedgerOrderArgs = {
  request?: InputMaybe<LedgersMsCreateLedgerOrderRequest>;
};

export type MutationLedgersMsCreateMileageReportArgs = {
  request?: InputMaybe<LedgersMsCreateMileageReportRequest>;
};

export type MutationLedgersMsCreatePriceCeilingReportArgs = {
  request?: InputMaybe<LedgersMsCreatePriceCeilingReportRequest>;
};

export type MutationLedgersMsExportSupportConfigArgs = {
  request?: InputMaybe<LedgersMsExportSupportConfigRequest>;
};

export type MutationLedgersMsExportTransactionsArgs = {
  request?: InputMaybe<LedgersMsExportTransactionsRequest>;
};

export type MutationLedgersMsOnCreateLedgerOrderArgs = {
  request?: InputMaybe<LedgersMsOnCreateLedgerOrderRequest>;
};

export type MutationLedgersMsOnUpdateLedgerOrderArgs = {
  request?: InputMaybe<LedgersMsOnUpdateLedgerOrderRequest>;
};

export type MutationLedgersMsResendPinCodeArgs = {
  request?: InputMaybe<LedgersMsResendPinCodeRequest>;
};

export type MutationLedgersMsUpdateLedgerOrderArgs = {
  request?: InputMaybe<LedgersMsUpdateLedgerOrderRequest>;
};

export type MutationNotificationsMsDisableMySubscriptionsArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type MutationNotificationsMsEnableMySubscriptionsArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type MutationSectorsMsSetLabelArgs = {
  request?: InputMaybe<SectorsMsSetLabelRequest>;
};

export type MutationVehiclesMsArchiveVehicleArgs = {
  request?: InputMaybe<VehiclesMsArchiveVehicleRequest>;
};

export type MutationVehiclesMsCreateVehicleArgs = {
  request?: InputMaybe<VehiclesMsCreateVehicleRequest>;
};

export type MutationVehiclesMsUnarchiveVehicleArgs = {
  request?: InputMaybe<VehiclesMsUnarchiveVehicleRequest>;
};

export type NotificationsMsGetMySubscriptionsResponse = {
  __typename?: "NotificationsMsGetMySubscriptionsResponse";
  notificationSubscriptions?: Maybe<
    Array<NotificationsMsGetMySubscriptionsResponseNotificationSubscription>
  >;
};

export type NotificationsMsGetMySubscriptionsResponseNotificationSubscription =
  {
    __typename?: "NotificationsMsGetMySubscriptionsResponseNotificationSubscription";
    notificationTypeCode: Scalars["String"]["output"];
    notificationTypeId: Scalars["String"]["output"];
    subscribed: Scalars["Boolean"]["output"];
  };

export type NotificationsMsPagedPagination = {
  __typename?: "NotificationsMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type NotificationsMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Search among messages documents  */
export type NotificationsMsSearchNotificationDocumentsRequest = {
  filters?: InputMaybe<NotificationsMsSearchNotificationDocumentsRequestFilters>;
  paging?: InputMaybe<NotificationsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<NotificationsMsSearchNotificationDocumentsRequestSort>>
  >;
};

export type NotificationsMsSearchNotificationDocumentsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type NotificationsMsSearchNotificationDocumentsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<NotificationsMsSearchNotificationDocumentsRequestSortField>;
};

export enum NotificationsMsSearchNotificationDocumentsRequestSortField {
  UpdatedTs = "updatedTs",
}

export type NotificationsMsSearchNotificationDocumentsResponse = {
  __typename?: "NotificationsMsSearchNotificationDocumentsResponse";
  data?: Maybe<Array<NotificationsMsSearchNotificationDocumentsResponseData>>;
  pagination: NotificationsMsPagedPagination;
};

export type NotificationsMsSearchNotificationDocumentsResponseData = {
  __typename?: "NotificationsMsSearchNotificationDocumentsResponseData";
  DocumentIdData?: Maybe<MediaMsSearchDocumentsResponseData>;
  NotificationIdData?: Maybe<NotificationsMsSearchNotificationsResponseData>;
  createdTs: Scalars["Int64"]["output"];
  /**  The related document  */
  documentId: Scalars["ID"]["output"];
  /**  The document id  */
  id: Scalars["ID"]["output"];
  /**  The notification id  */
  notificationId: Scalars["ID"]["output"];
  updatedTs: Scalars["Int64"]["output"];
};

/**  Search among notification types  */
export type NotificationsMsSearchNotificationTypesRequest = {
  filters?: InputMaybe<NotificationsMsSearchNotificationTypesRequestFilters>;
  paging?: InputMaybe<NotificationsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<NotificationsMsSearchNotificationTypesRequestSort>>
  >;
};

export type NotificationsMsSearchNotificationTypesRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type NotificationsMsSearchNotificationTypesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<NotificationsMsSearchNotificationTypesRequestSortField>;
};

export enum NotificationsMsSearchNotificationTypesRequestSortField {
  Code = "code",
  UpdatedTs = "updatedTs",
}

export type NotificationsMsSearchNotificationTypesResponse = {
  __typename?: "NotificationsMsSearchNotificationTypesResponse";
  data?: Maybe<Array<NotificationsMsSearchNotificationTypesResponseData>>;
  pagination: NotificationsMsPagedPagination;
};

export type NotificationsMsSearchNotificationTypesResponseData = {
  __typename?: "NotificationsMsSearchNotificationTypesResponseData";
  /**  The notification code  */
  code: Scalars["String"]["output"];
  /**  The notification id  */
  id: Scalars["ID"]["output"];
};

/**  Get account preferences related to the notifications domain  */
export type NotificationsMsSearchNotificationsAccountPreferencesRequest = {
  filters?: InputMaybe<NotificationsMsSearchNotificationsAccountPreferencesRequestFilters>;
  paging?: InputMaybe<NotificationsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<
      InputMaybe<NotificationsMsSearchNotificationsAccountPreferencesRequestSort>
    >
  >;
};

export type NotificationsMsSearchNotificationsAccountPreferencesRequestFilters =
  {
    ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  };

export type NotificationsMsSearchNotificationsAccountPreferencesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<NotificationsMsSearchNotificationsAccountPreferencesRequestSortField>;
};

export enum NotificationsMsSearchNotificationsAccountPreferencesRequestSortField {
  Id = "id",
}

export type NotificationsMsSearchNotificationsAccountPreferencesResponse = {
  __typename?: "NotificationsMsSearchNotificationsAccountPreferencesResponse";
  data?: Maybe<
    Array<NotificationsMsSearchNotificationsAccountPreferencesResponseData>
  >;
  pagination: NotificationsMsPagedPagination;
};

export type NotificationsMsSearchNotificationsAccountPreferencesResponseData = {
  __typename?: "NotificationsMsSearchNotificationsAccountPreferencesResponseData";
  IdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  /**  The account ID  */
  id: Scalars["ID"]["output"];
};

/**  Search among notifications  */
export type NotificationsMsSearchNotificationsRequest = {
  filters?: InputMaybe<NotificationsMsSearchNotificationsRequestFilters>;
  paging?: InputMaybe<NotificationsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<NotificationsMsSearchNotificationsRequestSort>>
  >;
};

export type NotificationsMsSearchNotificationsRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  typeId?: InputMaybe<Scalars["ID"]["input"]>;
  updatedTsGt?: InputMaybe<Scalars["Int64"]["input"]>;
  updatedTsLt?: InputMaybe<Scalars["Int64"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type NotificationsMsSearchNotificationsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<NotificationsMsSearchNotificationsRequestSortField>;
};

export enum NotificationsMsSearchNotificationsRequestSortField {
  CreatedTs = "createdTs",
  NotificationId = "notification_id",
  UpdatedTs = "updatedTs",
}

export type NotificationsMsSearchNotificationsResponse = {
  __typename?: "NotificationsMsSearchNotificationsResponse";
  data?: Maybe<Array<NotificationsMsSearchNotificationsResponseData>>;
  pagination: NotificationsMsPagedPagination;
};

export type NotificationsMsSearchNotificationsResponseData = {
  __typename?: "NotificationsMsSearchNotificationsResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  TypeIdData?: Maybe<NotificationsMsSearchNotificationTypesResponseData>;
  UserIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  /**  The optional related account  */
  accountId: Scalars["ID"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  The raw data of the user  */
  data: Scalars["String"]["output"];
  failedReason: NotificationsMsSearchNotificationsResponseDataFailedReason;
  hasAttachments: Scalars["Boolean"]["output"];
  /**  The job id  */
  id: Scalars["ID"]["output"];
  /**  The status of the notification  */
  status: NotificationsMsSearchNotificationsResponseDataStatus;
  /**  The notification subject  */
  subject: Scalars["String"]["output"];
  /**  The notification type  */
  typeId: Scalars["ID"]["output"];
  updatedTs: Scalars["Int64"]["output"];
  /**  The related user  */
  userId: Scalars["ID"]["output"];
};

export enum NotificationsMsSearchNotificationsResponseDataFailedReason {
  BlacklistedRecipient = "BLACKLISTED_RECIPIENT",
  DeliveryFailed = "DELIVERY_FAILED",
  FailedReasonUnspecified = "FAILED_REASON_UNSPECIFIED",
  MissingRequiredParameter = "MISSING_REQUIRED_PARAMETER",
  NoLocale = "NO_LOCALE",
  OptedOut = "OPTED_OUT",
  TemplateError = "TEMPLATE_ERROR",
  TriggerError = "TRIGGER_ERROR",
}

export enum NotificationsMsSearchNotificationsResponseDataStatus {
  AbortedAntiFlood = "ABORTED_ANTI_FLOOD",
  AbortedNoRegisteredUser = "ABORTED_NO_REGISTERED_USER",
  AbortedNoSendingSolution = "ABORTED_NO_SENDING_SOLUTION",
  AbortedNoTemplate = "ABORTED_NO_TEMPLATE",
  AbortedNoTrigger = "ABORTED_NO_TRIGGER",
  Handled = "HANDLED",
  New = "NEW",
  NotificationStatusUnspecified = "NOTIFICATION_STATUS_UNSPECIFIED",
}

/**  Get account preferences related to the notifications domain  */
export type NotificationsMsSearchRecipientsRequest = {
  filters?: InputMaybe<NotificationsMsSearchRecipientsRequestFilters>;
  paging?: InputMaybe<NotificationsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<NotificationsMsSearchRecipientsRequestSort>>
  >;
};

export type NotificationsMsSearchRecipientsRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type NotificationsMsSearchRecipientsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<NotificationsMsSearchRecipientsRequestSortField>;
};

export enum NotificationsMsSearchRecipientsRequestSortField {
  Id = "id",
}

export type NotificationsMsSearchRecipientsResponse = {
  __typename?: "NotificationsMsSearchRecipientsResponse";
  data?: Maybe<Array<NotificationsMsSearchRecipientsResponseData>>;
  pagination: NotificationsMsPagedPagination;
};

export type NotificationsMsSearchRecipientsResponseData = {
  __typename?: "NotificationsMsSearchRecipientsResponseData";
  createdTs: Scalars["Int64"]["output"];
  /**  The email id  */
  emailId: Scalars["ID"]["output"];
  /**  The recipient id  */
  id: Scalars["ID"]["output"];
  /**  The list of channels that have been opt out  */
  optOutChannels?: Maybe<
    Array<NotificationsMsSearchRecipientsResponseDataOptOutChannels>
  >;
  updatedTs: Scalars["Int64"]["output"];
};

export enum NotificationsMsSearchRecipientsResponseDataOptOutChannels {
  ChannelUnspecified = "CHANNEL_UNSPECIFIED",
  Email = "EMAIL",
  Prune = "PRUNE",
  PushNotification = "PUSH_NOTIFICATION",
}

/**  Search among triggers  */
export type NotificationsMsSearchTriggersRequest = {
  filters?: InputMaybe<NotificationsMsSearchTriggersRequestFilters>;
  paging?: InputMaybe<NotificationsMsPagedPaging>;
  sorts?: InputMaybe<
    Array<InputMaybe<NotificationsMsSearchTriggersRequestSort>>
  >;
};

export type NotificationsMsSearchTriggersRequestFilters = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
};

export type NotificationsMsSearchTriggersRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<NotificationsMsSearchTriggersRequestSortField>;
};

export enum NotificationsMsSearchTriggersRequestSortField {
  UpdatedTs = "updatedTs",
}

export type NotificationsMsSearchTriggersResponse = {
  __typename?: "NotificationsMsSearchTriggersResponse";
  data?: Maybe<Array<NotificationsMsSearchTriggersResponseData>>;
  pagination: NotificationsMsPagedPagination;
};

export type NotificationsMsSearchTriggersResponseData = {
  __typename?: "NotificationsMsSearchTriggersResponseData";
  NotificationTypeIdData?: Maybe<NotificationsMsSearchNotificationTypesResponseData>;
  /**  In which way the messages will be sent (ie: email, sms, push notification...)  */
  channel: NotificationsMsSearchTriggersResponseDataChannel;
  createdTs: Scalars["Int64"]["output"];
  description: Scalars["String"]["output"];
  /**  The trigger id  */
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  /**  The related notification type  */
  notificationTypeId: Scalars["ID"]["output"];
  status: NotificationsMsSearchTriggersResponseDataStatus;
  updatedTs: Scalars["Int64"]["output"];
};

export enum NotificationsMsSearchTriggersResponseDataChannel {
  ChannelUnspecified = "CHANNEL_UNSPECIFIED",
  Email = "EMAIL",
  Prune = "PRUNE",
  PushNotification = "PUSH_NOTIFICATION",
}

export enum NotificationsMsSearchTriggersResponseDataStatus {
  AbortedAntiFlood = "ABORTED_ANTI_FLOOD",
  AbortedNoRegisteredUser = "ABORTED_NO_REGISTERED_USER",
  AbortedNoSendingSolution = "ABORTED_NO_SENDING_SOLUTION",
  AbortedNoTemplate = "ABORTED_NO_TEMPLATE",
  AbortedNoTrigger = "ABORTED_NO_TRIGGER",
  Handled = "HANDLED",
  New = "NEW",
  NotificationStatusUnspecified = "NOTIFICATION_STATUS_UNSPECIFIED",
}

export type Query = {
  __typename?: "Query";
  /**  Count support user by status  */
  AccountsMsCountSupportUsersByStatus: AccountsMsCountSupportUsersByStatusResponse;
  /**  Count the users by status, scoped to the current account  */
  AccountsMsCountUsersByStatus: AccountsMsCountUsersByStatusResponse;
  /**  Count the users in a roles profile, scoped to the current account  */
  AccountsMsCountUsersInRolesProfile: AccountsMsCountUsersInRolesProfileResponse;
  /**
   *  Serve only for the frontend to forward the identity,
   * Triggering the baff with the id token that will call the LookupPassport
   * and set the cognito ids for the user
   */
  AccountsMsForwardIdentity?: Maybe<Scalars["Void"]["output"]>;
  /**  Get the owner of current account  */
  AccountsMsGetOwner: AccountsMsGetOwnerResponse;
  AccountsMsGetRolesGrid: AccountsMsGetRolesGridResponse;
  /**  List all available roles  */
  AccountsMsListRoles: AccountsMsListRolesResponse;
  /**
   *  The current identity information
   *
   * Returns the current user information, used by the frontend to load partial data about the user profile.
   */
  AccountsMsMe: AccountsMsMeResponse;
  AccountsMsReadRolesProfile: AccountsMsReadRolesProfileResponse;
  /**  Get the account information & preferences  */
  AccountsMsSearchAccounts: AccountsMsSearchAccountsResponse;
  /**  Search Agencies  */
  AccountsMsSearchAgencies: AccountsMsSearchAgenciesResponse;
  /**  Search among contract status  */
  AccountsMsSearchContractStatuses: AccountsMsSearchContractStatusesResponse;
  /**  Search among Contracts  */
  AccountsMsSearchContracts: AccountsMsSearchContractsResponse;
  /**  Search among offers  */
  AccountsMsSearchOffers: AccountsMsSearchOffersResponse;
  /**  Search among current user's privileges  */
  AccountsMsSearchOwnPrivileges: AccountsMsSearchOwnPrivilegesResponse;
  /**  Search among user-account privileges  */
  AccountsMsSearchPlatformAccesses: AccountsMsSearchPlatformAccessesResponse;
  /**  Search privilege restrictions  */
  AccountsMsSearchPrivilegeRestrictions: AccountsMsSearchPrivilegeRestrictionsResponse;
  /**  Search among privilege subscriptions  */
  AccountsMsSearchPrivilegeSubscriptions: AccountsMsSearchPrivilegeSubscriptionsResponse;
  /**  Search among user-account privileges  */
  AccountsMsSearchPrivileges: AccountsMsSearchPrivilegesResponse;
  /**  Search among roles profiles  */
  AccountsMsSearchRolesProfiles: AccountsMsSearchRolesProfilesResponse;
  /**  Search among support users  */
  AccountsMsSearchSupportUsers: AccountsMsSearchSupportUsersResponse;
  /**  Search among users  */
  AccountsMsSearchUsers: AccountsMsSearchUsersResponse;
  /**  Count assignments by status  */
  AssignmentsMsCountAssignmentsByStatus: AssignmentsMsCountAssignmentsByStatusResponse;
  /**  Check if collaborator assignment is available for ledger  */
  AssignmentsMsIsCollaboratorAssignmentAvailable: AssignmentsMsIsCollaboratorAssignmentAvailableResponse;
  /**  Search among collaborator assignments  */
  AssignmentsMsSearchCollaboratorAssignments: AssignmentsMsSearchCollaboratorAssignmentsResponse;
  /**  Search among vehicle assignments  */
  AssignmentsMsSearchVehicleAssignments: AssignmentsMsSearchVehicleAssignmentsResponse;
  BillingMsGetCustomerCreditState: BillingMsGetCustomerCreditStateResponse;
  BillingMsGetLastInvoice: BillingMsGetLastInvoiceResponse;
  /**  Create a invoice payment  */
  BillingMsListOpenPaymentDocuments: BillingMsListOpenPaymentDocumentsResponse;
  /**  Search among invoice addresses  */
  BillingMsSearchAddresses: BillingMsSearchAddressesResponse;
  /**  Get account preferences related to the billing domain  */
  BillingMsSearchBillingAccountPreferences: BillingMsSearchBillingAccountPreferencesResponse;
  /**  Search among billing frequencies  */
  BillingMsSearchBillingFrequencies: BillingMsSearchBillingFrequenciesResponse;
  /**  Search among customer credit frequencies  */
  BillingMsSearchCustomerCreditFrequencies: BillingMsSearchCustomerCreditFrequenciesResponse;
  /**
   *  Search among invoice lines
   *
   * The invoice lines show the transaction that haven't been paired yet. If you wish to list the transactions of the
   * related invoice, please use the transactions search endpoint.
   */
  BillingMsSearchInvoiceLines: BillingMsSearchInvoiceLinesResponse;
  /**  Search among invoice types  */
  BillingMsSearchInvoiceTypes: BillingMsSearchInvoiceTypesResponse;
  /**  Search among Invoices  */
  BillingMsSearchInvoices: BillingMsSearchInvoicesResponse;
  /**  Search among payment methods  */
  BillingMsSearchPaymentMethods: BillingMsSearchPaymentMethodsResponse;
  /**
   *  Search among payments
   *  TODO: Feature ?
   */
  BillingMsSearchPayments: BillingMsSearchPaymentsResponse;
  /**  Search among Tids  */
  BillingMsSearchTids: BillingMsSearchTidsResponse;
  ChargingPointsMsGetChargingPortsAvailability: ChargingPointsMsGetChargingPortsAvailabilityResponse;
  /**  Get account preferences related to the charging points domain  */
  ChargingPointsMsSearchChargingPointsAccountPreferences: ChargingPointsMsSearchChargingPointsAccountPreferencesResponse;
  /**  Search among places  */
  ChargingPointsMsSearchPlaces: ChargingPointsMsSearchPlacesResponse;
  /**  Search among charging session states  */
  ChargingPointsMsSearchSessionStates: ChargingPointsMsSearchSessionStatesResponse;
  /**  Count collaborators by status  */
  CollaboratorsMsCountCollaboratorsByStatus: CollaboratorsMsCountCollaboratorsByStatusResponse;
  /**  Search among collaborators  */
  CollaboratorsMsSearchCollaborators: CollaboratorsMsSearchCollaboratorsResponse;
  CollaboratorsMsSearchDriverCodeAllocations: CollaboratorsMsSearchDriverCodeAllocationsResponse;
  CollaboratorsMsSearchDriverCodes: CollaboratorsMsSearchDriverCodesResponse;
  CollaboratorsMsSuggestDriverCode: CollaboratorsMsSuggestDriverCodeResponse;
  /**  Count the ledgers by status  */
  LedgersMsCountLedgersByStatus: LedgersMsCountLedgersByStatusResponse;
  /**  Count the orders by status  */
  LedgersMsCountOrdersByStatus: LedgersMsCountOrdersByStatusResponse;
  /**  Provides with the CardPro division structure in order to modify a ledger.  */
  LedgersMsGetDivisions: LedgersMsGetDivisionsResponse;
  /**  Get Geographic Areas  */
  LedgersMsGetGeographicAreas: LedgersMsGetGeographicAreasResponse;
  /**  Fetch the creation data used for the ledger creation.  */
  LedgersMsGetLedgerCreationData: LedgersMsGetLedgerCreationDataResponse;
  /**  Fetch the raw data of a ledger.  */
  LedgersMsGetLedgerData: LedgersMsGetLedgerDataResponse;
  /**  Fetch the modification data used for the modification of ledger  */
  LedgersMsGetLedgerModificationData: LedgersMsGetLedgerModificationDataResponse;
  /**
   *  Fetch the list of creatable ledger types.
   *
   * List all orderable ledger types for the current user on the given wallet.
   */
  LedgersMsGetOrderableWalletLedgerTypes: LedgersMsGetOrderableWalletLedgerTypesResponse;
  /**  Fetch the PIN type of a ledger.  */
  LedgersMsGetPinType: LedgersMsGetPinTypeResponse;
  /**  Provides with the CardPro support & division account structure in order to create a ledger.  */
  LedgersMsGetSupportsAndDivisions: LedgersMsGetSupportsAndDivisionsResponse;
  /**  Get transaction's amounts stats.  */
  LedgersMsGetTransactionAmounts: LedgersMsGetTransactionAmountsResponse;
  /**  Return the whole wallet tree of the current account  */
  LedgersMsGetWalletTree: LedgersMsGetWalletTreeResponse;
  /**  List all countries. Required for PriceCeilingReport  */
  LedgersMsListCountries: LedgersMsListCountriesResponse;
  /**  List all fuel codes. Required for PriceCeilingReport  */
  LedgersMsListFuelCodes: LedgersMsListFuelCodesResponse;
  LedgersMsSearchGroups: LedgersMsSearchGroupsResponse;
  /**  Search among ledgers  */
  LedgersMsSearchLedgers: LedgersMsSearchLedgersResponse;
  /**  Get account preferences related to the ledgers domain  */
  LedgersMsSearchLedgersAccountPreferences: LedgersMsSearchLedgersAccountPreferencesResponse;
  /**  Search among orders  */
  LedgersMsSearchOrders: LedgersMsSearchOrdersResponse;
  /**  Search product categories  */
  LedgersMsSearchProductCategories: LedgersMsSearchProductCategoriesResponse;
  /**  Search product  */
  LedgersMsSearchProducts: LedgersMsSearchProductsResponse;
  /**  Search ledger statuses  */
  LedgersMsSearchStatuses: LedgersMsSearchStatusesResponse;
  /**  Search among transactions  */
  LedgersMsSearchTransactions: LedgersMsSearchTransactionsResponse;
  /**  Search ledger types  */
  LedgersMsSearchTypes: LedgersMsSearchTypesResponse;
  /**  Fetch wallet settings  */
  LedgersMsSearchWalletSettings: LedgersMsSearchWalletSettingsResponse;
  /**  Search among wallets  */
  LedgersMsSearchWallets: LedgersMsSearchWalletsResponse;
  /**  Search among document types  */
  MediaMsSearchDocumentTypes: MediaMsSearchDocumentTypesResponse;
  /**  Search among documents  */
  MediaMsSearchDocuments: MediaMsSearchDocumentsResponse;
  /**  Search among capital statements (FR: relevé de capitalisation)  */
  MediaMsSearchEndOfYearStatementDocuments: MediaMsSearchEndOfYearStatementDocumentsResponse;
  /**  Get account preferences related to the media domain  */
  MediaMsSearchMediaAccountPreferences: MediaMsSearchMediaAccountPreferencesResponse;
  /**  Search among tokens  */
  MediaMsSearchTokens: MediaMsSearchTokensResponse;
  /**  Get account preferences related to the middle domain  */
  MiddleMsSearchMiddleAccountPreferences: MiddleMsSearchMiddleAccountPreferencesResponse;
  /**  Get subscriptions related to current user  */
  NotificationsMsGetMySubscriptions: NotificationsMsGetMySubscriptionsResponse;
  /**  Search among messages documents  */
  NotificationsMsSearchNotificationDocuments: NotificationsMsSearchNotificationDocumentsResponse;
  /**  Search among notification types  */
  NotificationsMsSearchNotificationTypes: NotificationsMsSearchNotificationTypesResponse;
  /**  Search among notifications  */
  NotificationsMsSearchNotifications: NotificationsMsSearchNotificationsResponse;
  /**  Get account preferences related to the notifications domain  */
  NotificationsMsSearchNotificationsAccountPreferences: NotificationsMsSearchNotificationsAccountPreferencesResponse;
  /**  Get account preferences related to the notifications domain  */
  NotificationsMsSearchRecipients: NotificationsMsSearchRecipientsResponse;
  /**  Search among triggers  */
  NotificationsMsSearchTriggers: NotificationsMsSearchTriggersResponse;
  /**  Count tasks.  */
  ReportingMsCountTasks: ReportingMsCountTasksResponse;
  /**  Fetch user's KPI  */
  ReportingMsGetKpis: ReportingMsGetKpisResponse;
  /**  Search among tasks.  */
  ReportingMsSearchTasks: ReportingMsSearchTasksResponse;
  /**  Get a sector by id  */
  SectorsMsSearchSectors: SectorsMsSearchSectorsResponse;
  /**  Check the vehicle identification (license plate format or VIN)  */
  VehiclesMsCheckIdentification: VehiclesMsCheckIdentificationResponse;
  /**  Check the format of vehicle license plate in country  */
  VehiclesMsCheckLicensePlate: VehiclesMsCheckLicensePlateResponse;
  /**  Count vehicles by status  */
  VehiclesMsCountVehiclesByStatus: VehiclesMsCountVehiclesByStatusResponse;
  /**  Search among vehicles  */
  VehiclesMsSearchVehicles: VehiclesMsSearchVehiclesResponse;
  dummy?: Maybe<Scalars["String"]["output"]>;
};

export type QueryAccountsMsCountSupportUsersByStatusArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryAccountsMsCountUsersByStatusArgs = {
  request?: InputMaybe<AccountsMsCountUsersByStatusRequest>;
};

export type QueryAccountsMsCountUsersInRolesProfileArgs = {
  request?: InputMaybe<AccountsMsCountUsersInRolesProfileRequest>;
};

export type QueryAccountsMsForwardIdentityArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryAccountsMsGetOwnerArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryAccountsMsGetRolesGridArgs = {
  request?: InputMaybe<AccountsMsGetRolesGridRequest>;
};

export type QueryAccountsMsListRolesArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryAccountsMsMeArgs = {
  request?: InputMaybe<AccountsMsMeRequest>;
};

export type QueryAccountsMsReadRolesProfileArgs = {
  request?: InputMaybe<AccountsMsReadRolesProfileRequest>;
};

export type QueryAccountsMsSearchAccountsArgs = {
  request?: InputMaybe<AccountsMsSearchAccountsRequest>;
};

export type QueryAccountsMsSearchAgenciesArgs = {
  request?: InputMaybe<AccountsMsSearchAgenciesRequest>;
};

export type QueryAccountsMsSearchContractStatusesArgs = {
  request?: InputMaybe<AccountsMsSearchContractStatusesRequest>;
};

export type QueryAccountsMsSearchContractsArgs = {
  request?: InputMaybe<AccountsMsSearchContractsRequest>;
};

export type QueryAccountsMsSearchOffersArgs = {
  request?: InputMaybe<AccountsMsSearchOffersRequest>;
};

export type QueryAccountsMsSearchOwnPrivilegesArgs = {
  request?: InputMaybe<AccountsMsSearchOwnPrivilegesRequest>;
};

export type QueryAccountsMsSearchPlatformAccessesArgs = {
  request?: InputMaybe<AccountsMsSearchPlatformAccessesRequest>;
};

export type QueryAccountsMsSearchPrivilegeRestrictionsArgs = {
  request?: InputMaybe<AccountsMsSearchPrivilegeRestrictionsRequest>;
};

export type QueryAccountsMsSearchPrivilegeSubscriptionsArgs = {
  request?: InputMaybe<AccountsMsSearchPrivilegeSubscriptionsRequest>;
};

export type QueryAccountsMsSearchPrivilegesArgs = {
  request?: InputMaybe<AccountsMsSearchPrivilegesRequest>;
};

export type QueryAccountsMsSearchRolesProfilesArgs = {
  request?: InputMaybe<AccountsMsSearchRolesProfilesRequest>;
};

export type QueryAccountsMsSearchSupportUsersArgs = {
  request?: InputMaybe<AccountsMsSearchSupportUsersRequest>;
};

export type QueryAccountsMsSearchUsersArgs = {
  request?: InputMaybe<AccountsMsSearchUsersRequest>;
};

export type QueryAssignmentsMsCountAssignmentsByStatusArgs = {
  request?: InputMaybe<AssignmentsMsCountAssignmentsByStatusRequest>;
};

export type QueryAssignmentsMsIsCollaboratorAssignmentAvailableArgs = {
  request?: InputMaybe<AssignmentsMsIsCollaboratorAssignmentAvailableRequest>;
};

export type QueryAssignmentsMsSearchCollaboratorAssignmentsArgs = {
  request?: InputMaybe<AssignmentsMsSearchCollaboratorAssignmentsRequest>;
};

export type QueryAssignmentsMsSearchVehicleAssignmentsArgs = {
  request?: InputMaybe<AssignmentsMsSearchVehicleAssignmentsRequest>;
};

export type QueryBillingMsGetCustomerCreditStateArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryBillingMsGetLastInvoiceArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryBillingMsListOpenPaymentDocumentsArgs = {
  request?: InputMaybe<BillingMsListOpenPaymentDocumentsRequest>;
};

export type QueryBillingMsSearchAddressesArgs = {
  request?: InputMaybe<BillingMsSearchAddressesRequest>;
};

export type QueryBillingMsSearchBillingAccountPreferencesArgs = {
  request?: InputMaybe<BillingMsSearchBillingAccountPreferencesRequest>;
};

export type QueryBillingMsSearchBillingFrequenciesArgs = {
  request?: InputMaybe<BillingMsSearchBillingFrequenciesRequest>;
};

export type QueryBillingMsSearchCustomerCreditFrequenciesArgs = {
  request?: InputMaybe<BillingMsSearchCustomerCreditFrequenciesRequest>;
};

export type QueryBillingMsSearchInvoiceLinesArgs = {
  request?: InputMaybe<BillingMsSearchInvoiceLinesRequest>;
};

export type QueryBillingMsSearchInvoiceTypesArgs = {
  request?: InputMaybe<BillingMsSearchInvoiceTypesRequest>;
};

export type QueryBillingMsSearchInvoicesArgs = {
  request?: InputMaybe<BillingMsSearchInvoicesRequest>;
};

export type QueryBillingMsSearchPaymentMethodsArgs = {
  request?: InputMaybe<BillingMsSearchPaymentMethodsRequest>;
};

export type QueryBillingMsSearchPaymentsArgs = {
  request?: InputMaybe<BillingMsSearchPaymentsRequest>;
};

export type QueryBillingMsSearchTidsArgs = {
  request?: InputMaybe<BillingMsSearchTidsRequest>;
};

export type QueryChargingPointsMsGetChargingPortsAvailabilityArgs = {
  request?: InputMaybe<ChargingPointsMsGetChargingPortsAvailabilityRequest>;
};

export type QueryChargingPointsMsSearchChargingPointsAccountPreferencesArgs = {
  request?: InputMaybe<ChargingPointsMsSearchChargingPointsAccountPreferencesRequest>;
};

export type QueryChargingPointsMsSearchPlacesArgs = {
  request?: InputMaybe<ChargingPointsMsSearchPlacesRequest>;
};

export type QueryChargingPointsMsSearchSessionStatesArgs = {
  request?: InputMaybe<ChargingPointsMsSearchSessionStatesRequest>;
};

export type QueryCollaboratorsMsCountCollaboratorsByStatusArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryCollaboratorsMsSearchCollaboratorsArgs = {
  request?: InputMaybe<CollaboratorsMsSearchCollaboratorsRequest>;
};

export type QueryCollaboratorsMsSearchDriverCodeAllocationsArgs = {
  request?: InputMaybe<CollaboratorsMsSearchDriverCodeAllocationsRequest>;
};

export type QueryCollaboratorsMsSearchDriverCodesArgs = {
  request?: InputMaybe<CollaboratorsMsSearchDriverCodesRequest>;
};

export type QueryCollaboratorsMsSuggestDriverCodeArgs = {
  request?: InputMaybe<CollaboratorsMsSuggestDriverCodeRequest>;
};

export type QueryLedgersMsCountLedgersByStatusArgs = {
  request?: InputMaybe<LedgersMsCountLedgersByStatusRequest>;
};

export type QueryLedgersMsCountOrdersByStatusArgs = {
  request?: InputMaybe<LedgersMsCountOrdersByStatusRequest>;
};

export type QueryLedgersMsGetDivisionsArgs = {
  request?: InputMaybe<LedgersMsGetDivisionsRequest>;
};

export type QueryLedgersMsGetGeographicAreasArgs = {
  request?: InputMaybe<LedgersMsGetGeographicAreasRequest>;
};

export type QueryLedgersMsGetLedgerCreationDataArgs = {
  request?: InputMaybe<LedgersMsGetLedgerCreationDataRequest>;
};

export type QueryLedgersMsGetLedgerDataArgs = {
  request?: InputMaybe<LedgersMsGetLedgerDataRequest>;
};

export type QueryLedgersMsGetLedgerModificationDataArgs = {
  request?: InputMaybe<LedgersMsGetLedgerModificationDataRequest>;
};

export type QueryLedgersMsGetOrderableWalletLedgerTypesArgs = {
  request?: InputMaybe<LedgersMsGetOrderableWalletLedgerTypesRequest>;
};

export type QueryLedgersMsGetPinTypeArgs = {
  request?: InputMaybe<LedgersMsGetPinTypeRequest>;
};

export type QueryLedgersMsGetSupportsAndDivisionsArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryLedgersMsGetTransactionAmountsArgs = {
  request?: InputMaybe<LedgersMsGetTransactionAmountsRequest>;
};

export type QueryLedgersMsGetWalletTreeArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryLedgersMsListCountriesArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryLedgersMsListFuelCodesArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryLedgersMsSearchGroupsArgs = {
  request?: InputMaybe<LedgersMsSearchGroupsRequest>;
};

export type QueryLedgersMsSearchLedgersArgs = {
  request?: InputMaybe<LedgersMsSearchLedgersRequest>;
};

export type QueryLedgersMsSearchLedgersAccountPreferencesArgs = {
  request?: InputMaybe<LedgersMsSearchLedgersAccountPreferencesRequest>;
};

export type QueryLedgersMsSearchOrdersArgs = {
  request?: InputMaybe<LedgersMsSearchOrdersRequest>;
};

export type QueryLedgersMsSearchProductCategoriesArgs = {
  request?: InputMaybe<LedgersMsSearchProductCategoriesRequest>;
};

export type QueryLedgersMsSearchProductsArgs = {
  request?: InputMaybe<LedgersMsSearchProductsRequest>;
};

export type QueryLedgersMsSearchStatusesArgs = {
  request?: InputMaybe<LedgersMsSearchStatusesRequest>;
};

export type QueryLedgersMsSearchTransactionsArgs = {
  request?: InputMaybe<LedgersMsSearchTransactionsRequest>;
};

export type QueryLedgersMsSearchTypesArgs = {
  request?: InputMaybe<LedgersMsSearchTypesRequest>;
};

export type QueryLedgersMsSearchWalletSettingsArgs = {
  request?: InputMaybe<LedgersMsSearchWalletSettingsRequest>;
};

export type QueryLedgersMsSearchWalletsArgs = {
  request?: InputMaybe<LedgersMsSearchWalletsRequest>;
};

export type QueryMediaMsSearchDocumentTypesArgs = {
  request?: InputMaybe<MediaMsSearchDocumentTypesRequest>;
};

export type QueryMediaMsSearchDocumentsArgs = {
  request?: InputMaybe<MediaMsSearchDocumentsRequest>;
};

export type QueryMediaMsSearchEndOfYearStatementDocumentsArgs = {
  request?: InputMaybe<MediaMsSearchEndOfYearStatementDocumentsRequest>;
};

export type QueryMediaMsSearchMediaAccountPreferencesArgs = {
  request?: InputMaybe<MediaMsSearchMediaAccountPreferencesRequest>;
};

export type QueryMediaMsSearchTokensArgs = {
  request?: InputMaybe<MediaMsSearchTokensRequest>;
};

export type QueryMiddleMsSearchMiddleAccountPreferencesArgs = {
  request?: InputMaybe<MiddleMsSearchMiddleAccountPreferencesRequest>;
};

export type QueryNotificationsMsGetMySubscriptionsArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryNotificationsMsSearchNotificationDocumentsArgs = {
  request?: InputMaybe<NotificationsMsSearchNotificationDocumentsRequest>;
};

export type QueryNotificationsMsSearchNotificationTypesArgs = {
  request?: InputMaybe<NotificationsMsSearchNotificationTypesRequest>;
};

export type QueryNotificationsMsSearchNotificationsArgs = {
  request?: InputMaybe<NotificationsMsSearchNotificationsRequest>;
};

export type QueryNotificationsMsSearchNotificationsAccountPreferencesArgs = {
  request?: InputMaybe<NotificationsMsSearchNotificationsAccountPreferencesRequest>;
};

export type QueryNotificationsMsSearchRecipientsArgs = {
  request?: InputMaybe<NotificationsMsSearchRecipientsRequest>;
};

export type QueryNotificationsMsSearchTriggersArgs = {
  request?: InputMaybe<NotificationsMsSearchTriggersRequest>;
};

export type QueryReportingMsCountTasksArgs = {
  request?: InputMaybe<ReportingMsCountTasksRequest>;
};

export type QueryReportingMsGetKpisArgs = {
  request?: InputMaybe<ReportingMsGetKpisRequest>;
};

export type QueryReportingMsSearchTasksArgs = {
  request?: InputMaybe<ReportingMsSearchTasksRequest>;
};

export type QuerySectorsMsSearchSectorsArgs = {
  request?: InputMaybe<SectorsMsSearchSectorsRequest>;
};

export type QueryVehiclesMsCheckIdentificationArgs = {
  request?: InputMaybe<VehiclesMsCheckIdentificationRequest>;
};

export type QueryVehiclesMsCheckLicensePlateArgs = {
  request?: InputMaybe<VehiclesMsCheckLicensePlateRequest>;
};

export type QueryVehiclesMsCountVehiclesByStatusArgs = {
  request?: InputMaybe<Scalars["Void"]["input"]>;
};

export type QueryVehiclesMsSearchVehiclesArgs = {
  request?: InputMaybe<VehiclesMsSearchVehiclesRequest>;
};

/**  Count tasks.  */
export type ReportingMsCountTasksRequest = {
  filters?: InputMaybe<ReportingMsCountTasksRequestFilters>;
};

export type ReportingMsCountTasksRequestFilters = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  status?: InputMaybe<
    Array<InputMaybe<ReportingMsCountTasksRequestFiltersStatus>>
  >;
};

export enum ReportingMsCountTasksRequestFiltersStatus {
  Available = "AVAILABLE",
  New = "NEW",
  Pending = "PENDING",
  UnknownStatus = "UNKNOWN_STATUS",
}

export type ReportingMsCountTasksResponse = {
  __typename?: "ReportingMsCountTasksResponse";
  total: Scalars["Int64"]["output"];
};

/**  Fetch user's KPI  */
export type ReportingMsGetKpisRequest = {
  /**  The list of KPI. The number of KPI you can request at once is limited.  */
  kpis?: InputMaybe<Array<InputMaybe<ReportingMsGetKpisRequestKpi>>>;
  /**  The default filters apply to the kpis.  */
  parameters?: InputMaybe<ReportingMsGetKpisRequestParameters>;
};

/**  The list of KPI. The number of KPI you can request at once is limited.  */
export type ReportingMsGetKpisRequestKpi = {
  /**  The KPI code  */
  code?: InputMaybe<ReportingMsGetKpisRequestKpiCode>;
};

/**  The KPI code  */
export enum ReportingMsGetKpisRequestKpiCode {
  EvChargingAverageChargingFrequencyPerDay = "EV_CHARGING_AVERAGE_CHARGING_FREQUENCY_PER_DAY",
  EvChargingAverageChargingFrequencyPerWeek = "EV_CHARGING_AVERAGE_CHARGING_FREQUENCY_PER_WEEK",
  EvChargingAverageConsumptionInMonth = "EV_CHARGING_AVERAGE_CONSUMPTION_IN_MONTH",
  EvChargingAverageConsumptionPerDay = "EV_CHARGING_AVERAGE_CONSUMPTION_PER_DAY",
  EvChargingAverageCostPerDay = "EV_CHARGING_AVERAGE_COST_PER_DAY",
  EvChargingExpensesOfTheMonth = "EV_CHARGING_EXPENSES_OF_THE_MONTH",
  EvChargingNumberOfRechargesInMonth = "EV_CHARGING_NUMBER_OF_RECHARGES_IN_MONTH",
  EvChargingPowerConsumptionInMonth = "EV_CHARGING_POWER_CONSUMPTION_IN_MONTH",
  EvChargingPowerCostInMonth = "EV_CHARGING_POWER_COST_IN_MONTH",
  EvChargingRoamingPowerConsumptionInMonth = "EV_CHARGING_ROAMING_POWER_CONSUMPTION_IN_MONTH",
  EvChargingRoamingPowerCostInMonth = "EV_CHARGING_ROAMING_POWER_COST_IN_MONTH",
  EvChargingTotalCostOfChargingInMonth = "EV_CHARGING_TOTAL_COST_OF_CHARGING_IN_MONTH",
  EvVariationOfConsumptionWithPreviousMonth = "EV_VARIATION_OF_CONSUMPTION_WITH_PREVIOUS_MONTH",
  EvVariationOfCostWithPreviousMonth = "EV_VARIATION_OF_COST_WITH_PREVIOUS_MONTH",
  ExpensesOfTheMonth = "EXPENSES_OF_THE_MONTH",
  KpiPrune = "KPI_PRUNE",
  KpiUnknown = "KPI_UNKNOWN",
  TotalAmountOfTransactionsInMonth = "TOTAL_AMOUNT_OF_TRANSACTIONS_IN_MONTH",
  TotalNumberOfTransactionsInMonth = "TOTAL_NUMBER_OF_TRANSACTIONS_IN_MONTH",
}

/**  The default filters apply to the kpis.  */
export type ReportingMsGetKpisRequestParameters = {
  /**
   *  The list of account ids to filter the kpis. If the list if empty, we fallback to the list of accounts where the
   * user has the capability to get KPIs.
   */
  accountIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /**  The starting unix timestamp, it fallbacks to the 1st of the month.  */
  from?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  The ending unix timestamp, it fallbacks to the current time.  */
  till?: InputMaybe<Scalars["Int64"]["input"]>;
};

export type ReportingMsGetKpisResponse = {
  __typename?: "ReportingMsGetKpisResponse";
  /**  The list of KPI response, indexed by the requested KPI order.  */
  kpis?: Maybe<Array<ReportingMsGetKpisResponseKpi>>;
};

/**  The list of KPI response, indexed by the requested KPI order.  */
export type ReportingMsGetKpisResponseKpi = {
  __typename?: "ReportingMsGetKpisResponseKpi";
  /**  The KPI code  */
  code: ReportingMsGetKpisResponseKpiCode;
  /**  The KPI data  */
  data?: Maybe<Array<ReportingMsGetKpisResponseKpiData>>;
  /**  Set only when the given KPI has failed.  */
  failedReason: ReportingMsGetKpisResponseKpiFailedReason;
};

/**  The KPI code  */
export enum ReportingMsGetKpisResponseKpiCode {
  EvChargingAverageChargingFrequencyPerDay = "EV_CHARGING_AVERAGE_CHARGING_FREQUENCY_PER_DAY",
  EvChargingAverageChargingFrequencyPerWeek = "EV_CHARGING_AVERAGE_CHARGING_FREQUENCY_PER_WEEK",
  EvChargingAverageConsumptionInMonth = "EV_CHARGING_AVERAGE_CONSUMPTION_IN_MONTH",
  EvChargingAverageConsumptionPerDay = "EV_CHARGING_AVERAGE_CONSUMPTION_PER_DAY",
  EvChargingAverageCostPerDay = "EV_CHARGING_AVERAGE_COST_PER_DAY",
  EvChargingExpensesOfTheMonth = "EV_CHARGING_EXPENSES_OF_THE_MONTH",
  EvChargingNumberOfRechargesInMonth = "EV_CHARGING_NUMBER_OF_RECHARGES_IN_MONTH",
  EvChargingPowerConsumptionInMonth = "EV_CHARGING_POWER_CONSUMPTION_IN_MONTH",
  EvChargingPowerCostInMonth = "EV_CHARGING_POWER_COST_IN_MONTH",
  EvChargingRoamingPowerConsumptionInMonth = "EV_CHARGING_ROAMING_POWER_CONSUMPTION_IN_MONTH",
  EvChargingRoamingPowerCostInMonth = "EV_CHARGING_ROAMING_POWER_COST_IN_MONTH",
  EvChargingTotalCostOfChargingInMonth = "EV_CHARGING_TOTAL_COST_OF_CHARGING_IN_MONTH",
  EvVariationOfConsumptionWithPreviousMonth = "EV_VARIATION_OF_CONSUMPTION_WITH_PREVIOUS_MONTH",
  EvVariationOfCostWithPreviousMonth = "EV_VARIATION_OF_COST_WITH_PREVIOUS_MONTH",
  ExpensesOfTheMonth = "EXPENSES_OF_THE_MONTH",
  KpiPrune = "KPI_PRUNE",
  KpiUnknown = "KPI_UNKNOWN",
  TotalAmountOfTransactionsInMonth = "TOTAL_AMOUNT_OF_TRANSACTIONS_IN_MONTH",
  TotalNumberOfTransactionsInMonth = "TOTAL_NUMBER_OF_TRANSACTIONS_IN_MONTH",
}

/**  The KPI data  */
export type ReportingMsGetKpisResponseKpiData = {
  __typename?: "ReportingMsGetKpisResponseKpiData";
  /**  An optional discriminator  */
  axis: Scalars["String"]["output"];
  /**  The unit of the value (ex: ['EUR', 'VAT_INCLUDED'])  */
  unit?: Maybe<Array<Scalars["String"]["output"]>>;
  /**  The final value of the kpi data entry  */
  value: Scalars["String"]["output"];
  /**  The datetime of the kpi entry  */
  when: Scalars["Int64"]["output"];
};

/**  Set only when the given KPI has failed.  */
export enum ReportingMsGetKpisResponseKpiFailedReason {
  InvalidFormat = "INVALID_FORMAT",
  None = "NONE",
  Timeout = "TIMEOUT",
  Unimplemented = "UNIMPLEMENTED",
  UnknownFormat = "UNKNOWN_FORMAT",
}

export type ReportingMsPagedPagination = {
  __typename?: "ReportingMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type ReportingMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Search among tasks.  */
export type ReportingMsSearchTasksRequest = {
  filters?: InputMaybe<ReportingMsSearchTasksRequestFilters>;
  paging?: InputMaybe<ReportingMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<ReportingMsSearchTasksRequestSort>>>;
};

export type ReportingMsSearchTasksRequestFilters = {
  dateTsGt?: InputMaybe<Scalars["Int64"]["input"]>;
  dateTsLt?: InputMaybe<Scalars["Int64"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  status?: InputMaybe<
    Array<InputMaybe<ReportingMsSearchTasksRequestFiltersStatus>>
  >;
};

export enum ReportingMsSearchTasksRequestFiltersStatus {
  Available = "AVAILABLE",
  New = "NEW",
  Pending = "PENDING",
  UnknownStatus = "UNKNOWN_STATUS",
}

export type ReportingMsSearchTasksRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<ReportingMsSearchTasksRequestSortField>;
};

export enum ReportingMsSearchTasksRequestSortField {
  DocumentCreationTs = "document_creation_ts",
  DocumentExpirationTs = "document_expiration_ts",
  DocumentName = "document_name",
  Id = "id",
  Status = "status",
}

export type ReportingMsSearchTasksResponse = {
  __typename?: "ReportingMsSearchTasksResponse";
  data?: Maybe<Array<ReportingMsSearchTasksResponseData>>;
  pagination: ReportingMsPagedPagination;
};

export type ReportingMsSearchTasksResponseData = {
  __typename?: "ReportingMsSearchTasksResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  DocumentIdData?: Maybe<MediaMsSearchDocumentsResponseData>;
  UserIdData?: Maybe<AccountsMsSearchUsersResponseData>;
  /**  The Account the report belongs to.  */
  accountId: Scalars["ID"]["output"];
  documentCreationTs: Scalars["Int64"]["output"];
  documentExpirationTs: Scalars["Int64"]["output"];
  /**  The id of the related document. Should be used to download it via the Media service.  */
  documentId: Scalars["ID"]["output"];
  documentName: Scalars["String"]["output"];
  /**  The message that describes the error occurred.  */
  failedMessage: Scalars["String"]["output"];
  /**  The reason code of the failing task.  */
  failedReason: Scalars["String"]["output"];
  /**  The id of the task.  */
  id: Scalars["ID"]["output"];
  /**  The current status of the task.  */
  state: ReportingMsSearchTasksResponseDataState;
  /**  The current status of the task.  */
  status: ReportingMsSearchTasksResponseDataStatus;
  /**  Who requested the report  */
  userId: Scalars["ID"]["output"];
};

export enum ReportingMsSearchTasksResponseDataState {
  Created = "CREATED",
  DataFetched = "DATA_FETCHED",
  DocumentGenerated = "DOCUMENT_GENERATED",
  Done = "DONE",
  Downloaded = "DOWNLOADED",
  Failed = "FAILED",
  UnknownState = "UNKNOWN_STATE",
  WaitingForData = "WAITING_FOR_DATA",
  WaitingForDocument = "WAITING_FOR_DOCUMENT",
}

export enum ReportingMsSearchTasksResponseDataStatus {
  Available = "AVAILABLE",
  New = "NEW",
  Pending = "PENDING",
  UnknownStatus = "UNKNOWN_STATUS",
}

export type SectorsMsPagedPagination = {
  __typename?: "SectorsMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type SectorsMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Get a sector by id  */
export type SectorsMsSearchSectorsRequest = {
  filters?: InputMaybe<SectorsMsSearchSectorsRequestFilters>;
  paging?: InputMaybe<SectorsMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<SectorsMsSearchSectorsRequestSort>>>;
};

export type SectorsMsSearchSectorsRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  parentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  partnerReferences?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /**  Get only the top level sectors from the organization  */
  topLevelSectors?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SectorsMsSearchSectorsRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<SectorsMsSearchSectorsRequestSortField>;
};

export enum SectorsMsSearchSectorsRequestSortField {
  Id = "id",
  PartnerReference = "partner_reference",
}

export type SectorsMsSearchSectorsResponse = {
  __typename?: "SectorsMsSearchSectorsResponse";
  data?: Maybe<Array<SectorsMsSearchSectorsResponseData>>;
  pagination: SectorsMsPagedPagination;
};

export type SectorsMsSearchSectorsResponseData = {
  __typename?: "SectorsMsSearchSectorsResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  Children?: Maybe<Array<Maybe<SectorsMsSearchSectorsResponseData>>>;
  ParentIdData?: Maybe<SectorsMsSearchSectorsResponseData>;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  createdTs: Scalars["Int64"]["output"];
  /**  the sector disable timestamp  */
  disabledTs: Scalars["Int64"]["output"];
  /**  The Sector ID  */
  id: Scalars["ID"]["output"];
  isActive: Scalars["Boolean"]["output"];
  /**  The sector label  */
  label: Scalars["String"]["output"];
  /**  The parent sector id  */
  parentId: Scalars["ID"]["output"];
  /**  The sector label from partner  */
  partnerLabel: Scalars["String"]["output"];
  /**  The sector partner reference for lvl 1 and 2  */
  partnerReference: Scalars["String"]["output"];
  /**  the time the sector has been created/updated from our partner  */
  recordedTs: Scalars["Int64"]["output"];
  updatedTs: Scalars["Int64"]["output"];
};

/**  Set custom label of sector  */
export type SectorsMsSetLabelRequest = {
  label?: InputMaybe<Scalars["String"]["input"]>;
  sectorId?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Archive vehicle  */
export type VehiclesMsArchiveVehicleRequest = {
  fleetExitDateTs?: InputMaybe<Scalars["Int64"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**  Check the vehicle identification (license plate format or VIN)  */
export type VehiclesMsCheckIdentificationRequest = {
  /**  The country where we would check the identification (iso code 2)  */
  country?: InputMaybe<Scalars["String"]["input"]>;
  /**  The identification type  */
  type?: InputMaybe<VehiclesMsCheckIdentificationRequestType>;
  /**  The identification value  */
  value?: InputMaybe<Scalars["String"]["input"]>;
};

/**  The identification type  */
export enum VehiclesMsCheckIdentificationRequestType {
  IdentificationTypeUnknown = "IDENTIFICATION_TYPE_UNKNOWN",
  LicensePlate = "LICENSE_PLATE",
  Vin = "VIN",
}

export type VehiclesMsCheckIdentificationResponse = {
  __typename?: "VehiclesMsCheckIdentificationResponse";
  /**  true when the identification is valid  */
  valid: Scalars["Boolean"]["output"];
};

/**  Check the format of vehicle license plate in country  */
export type VehiclesMsCheckLicensePlateRequest = {
  /**  The country where we would check the vehicle license plate, in ISO CODE 2  */
  country?: InputMaybe<Scalars["String"]["input"]>;
  /**  The vehicle license plate  */
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
};

export type VehiclesMsCheckLicensePlateResponse = {
  __typename?: "VehiclesMsCheckLicensePlateResponse";
  /**  Response of license plate validation  */
  valid: Scalars["Boolean"]["output"];
};

export type VehiclesMsCountVehiclesByStatusResponse = {
  __typename?: "VehiclesMsCountVehiclesByStatusResponse";
  /**  All vehicles without the fleet exit date  */
  activated: Scalars["Int64"]["output"];
  /**  All vehicles  */
  all: Scalars["Int64"]["output"];
  /**  All vehicles with the fleet exit date  */
  archived: Scalars["Int64"]["output"];
};

/**  Create a vehicle  */
export type VehiclesMsCreateVehicleRequest = {
  /**  The vehicle fleet entry date  */
  fleetEntryDate?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  The vehicle fleet exit date  */
  fleetExitDate?: InputMaybe<Scalars["Int64"]["input"]>;
  /**  The vehicle license plate  */
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  /**  The sector in which the vehicle will be created  */
  sectorId?: InputMaybe<Scalars["ID"]["input"]>;
  /**  The vehicle official identification number  */
  vin?: InputMaybe<Scalars["String"]["input"]>;
};

export type VehiclesMsCreateVehicleResponse = {
  __typename?: "VehiclesMsCreateVehicleResponse";
  /**  The created vehicle id  */
  id: Scalars["ID"]["output"];
};

export type VehiclesMsPagedPagination = {
  __typename?: "VehiclesMsPagedPagination";
  /**  The page number we wished to access  */
  page: Scalars["Int"]["output"];
  /**  The number of items per page  */
  size: Scalars["Int"]["output"];
  /**  The total number of items in the database for the given implicit filters (count query)  */
  total: Scalars["Int64"]["output"];
};

export type VehiclesMsPagedPaging = {
  /**  The page number we wish to access  */
  page?: InputMaybe<Scalars["Int"]["input"]>;
  /**  The number of items per page  */
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

/**  Search among vehicles  */
export type VehiclesMsSearchVehiclesRequest = {
  filters?: InputMaybe<VehiclesMsSearchVehiclesRequestFilters>;
  paging?: InputMaybe<VehiclesMsPagedPaging>;
  sorts?: InputMaybe<Array<InputMaybe<VehiclesMsSearchVehiclesRequestSort>>>;
};

export type VehiclesMsSearchVehiclesRequestFilters = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  query?: InputMaybe<Scalars["String"]["input"]>;
};

export type VehiclesMsSearchVehiclesRequestSort = {
  ascending?: InputMaybe<Scalars["Boolean"]["input"]>;
  field?: InputMaybe<VehiclesMsSearchVehiclesRequestSortField>;
};

export enum VehiclesMsSearchVehiclesRequestSortField {
  Id = "id",
  LicensePlate = "license_plate",
  UpdatedTs = "updated_ts",
}

export type VehiclesMsSearchVehiclesResponse = {
  __typename?: "VehiclesMsSearchVehiclesResponse";
  data?: Maybe<Array<VehiclesMsSearchVehiclesResponseData>>;
  pagination: VehiclesMsPagedPagination;
};

export type VehiclesMsSearchVehiclesResponseData = {
  __typename?: "VehiclesMsSearchVehiclesResponseData";
  AccountIdData?: Maybe<AccountsMsSearchAccountsResponseData>;
  SectorIdData?: Maybe<SectorsMsSearchSectorsResponseData>;
  /**  The related account  */
  accountId: Scalars["ID"]["output"];
  /**  The vehicle fleet entry date  */
  fleetEntryDateTs: Scalars["Int64"]["output"];
  /**  The vehicle fleet exit date  */
  fleetExitDateTs: Scalars["Int64"]["output"];
  /**  The ID of the vehicle  */
  id: Scalars["ID"]["output"];
  /**  The vehicle license plate  */
  licensePlate: Scalars["String"]["output"];
  /**  The related sector  */
  sectorId: Scalars["ID"]["output"];
  status: VehiclesMsSearchVehiclesResponseDataStatus;
  /**  The vehicle official identification number  */
  vin: Scalars["String"]["output"];
};

export enum VehiclesMsSearchVehiclesResponseDataStatus {
  Active = "ACTIVE",
  Archived = "ARCHIVED",
  VehicleStatusUnknown = "VEHICLE_STATUS_UNKNOWN",
}

/**  Unarchive vehicle  */
export type VehiclesMsUnarchiveVehicleRequest = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GetAccountDetailQueryVariables = Exact<{
  request: AccountsMsSearchAccountsRequest;
}>;

export type GetAccountDetailQuery = {
  __typename?: "Query";
  AccountsMsSearchAccounts: {
    __typename?: "AccountsMsSearchAccountsResponse";
    data?: Array<{
      __typename?: "AccountsMsSearchAccountsResponseData";
      name: string;
      zipCode: string;
      city: string;
      country: string;
      number: string;
      address: string;
      MainPrivilegeIdData?: {
        __typename?: "AccountsMsSearchPrivilegesResponseData";
        UserIdData?: {
          __typename?: "AccountsMsSearchUsersResponseData";
          firstName: string;
          lastName: string;
          email: string;
        } | null;
      } | null;
    }> | null;
  };
};

export type GetAccountUsersQueryVariables = Exact<{
  request?: InputMaybe<AccountsMsSearchPrivilegesRequest>;
}>;

export type GetAccountUsersQuery = {
  __typename?: "Query";
  AccountsMsSearchPrivileges: {
    __typename?: "AccountsMsSearchPrivilegesResponse";
    data?: Array<{
      __typename?: "AccountsMsSearchPrivilegesResponseData";
      id: string;
      enabledLastTs: number;
      status: AccountsMsSearchPrivilegesResponseDataStatus;
      UserIdData?: {
        __typename?: "AccountsMsSearchUsersResponseData";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        createdTs: number;
      } | null;
      Subscriptions?: Array<{
        __typename?: "AccountsMsSearchPrivilegeSubscriptionsResponseData";
        RolesProfileIdData?: {
          __typename?: "AccountsMsSearchRolesProfilesResponseData";
          name: string;
          code: string;
        } | null;
      } | null> | null;
    }> | null;
    pagination: {
      __typename?: "AccountsMsPagedPagination";
      page: number;
      size: number;
      total: number;
    };
  };
};

export type GetAccountQueryVariables = Exact<{
  request: AccountsMsSearchAccountsRequest;
}>;

export type GetAccountQuery = {
  __typename?: "Query";
  AccountsMsSearchAccounts: {
    __typename?: "AccountsMsSearchAccountsResponse";
    data?: Array<{
      __typename?: "AccountsMsSearchAccountsResponseData";
      id: string;
      number: string;
      name: string;
      registrationNumber: string;
      registrationName: string;
      country: string;
      city: string;
      zipCode: string;
      address: string;
      createdTs: string;
      MainPrivilegeIdData?: {
        __typename?: "AccountsMsSearchPrivilegesResponseData";
        id: string;
        UserIdData?: {
          __typename?: "AccountsMsSearchUsersResponseData";
          email: string;
          id: string;
          firstName: string;
          lastName: string;
        } | null;
      } | null;
    }> | null;
  };
};

export type GetUserQueryVariables = Exact<{
  request: AccountsMsSearchUsersRequest;
}>;

export type GetUserQuery = {
  __typename?: "Query";
  AccountsMsSearchUsers: {
    __typename?: "AccountsMsSearchUsersResponse";
    data?: Array<{
      __typename?: "AccountsMsSearchUsersResponseData";
      id: string;
      email: string;
      locale: string;
      timezone: string;
      firstName: string;
      lastName: string;
      phone: string;
      phoneAlt: string;
      jobTitle: string;
    }> | null;
  };
};

export type AccountsMsSearchPrivilegesQueryVariables = Exact<{
  request?: InputMaybe<AccountsMsSearchPrivilegesRequest>;
}>;

export type AccountsMsSearchPrivilegesQuery = {
  __typename?: "Query";
  AccountsMsSearchPrivileges: {
    __typename?: "AccountsMsSearchPrivilegesResponse";
    data?: Array<{
      __typename?: "AccountsMsSearchPrivilegesResponseData";
      id: string;
      roles?: Array<string> | null;
      rolesInvalidatedTs: number;
      disabledTs: number;
      lastUsedTs: number;
      invitedTs: number;
      enabledLastTs: number;
      userId: string;
      accountId: string;
      owner: boolean;
      status: AccountsMsSearchPrivilegesResponseDataStatus;
      AccountIdData?: {
        __typename?: "AccountsMsSearchAccountsResponseData";
        id: string;
        name: string;
        number: string;
        registrationName: string;
        registrationNumber: string;
        isActive: boolean;
        createdTs: string;
        mainPrivilegeId: string;
      } | null;
      Subscriptions?: Array<{
        __typename?: "AccountsMsSearchPrivilegeSubscriptionsResponseData";
        id: string;
        fromTs: number;
        tillTs: number;
        privilegeId: string;
        rolesProfileId: string;
        RolesProfileIdData?: {
          __typename?: "AccountsMsSearchRolesProfilesResponseData";
          id: string;
          name: string;
          roles?: Array<string> | null;
          code: string;
          accountId: string;
          parentId: string;
          rootId: string;
          system: boolean;
        } | null;
      } | null> | null;
    }> | null;
  };
};

export type InviteAgentMutationVariables = Exact<{
  request?: InputMaybe<AccountsMsInviteSupportUserRequest>;
}>;

export type InviteAgentMutation = {
  __typename?: "Mutation";
  AccountsMsInviteSupportUser: {
    __typename?: "AccountsMsInviteSupportUserResponse";
    id: string;
  };
};

export type DisableAgentMutationVariables = Exact<{
  request?: InputMaybe<AccountsMsDisableSupportUserRequest>;
}>;

export type DisableAgentMutation = {
  __typename?: "Mutation";
  AccountsMsDisableSupportUser?: any | null;
};

export type EnableAgentMutationVariables = Exact<{
  request?: InputMaybe<AccountsMsEnableSupportUserRequest>;
}>;

export type EnableAgentMutation = {
  __typename?: "Mutation";
  AccountsMsEnableSupportUser?: any | null;
};

export type AccountsMsSearchSupportUsersQueryVariables = Exact<{
  request?: InputMaybe<AccountsMsSearchSupportUsersRequest>;
}>;

export type AccountsMsSearchSupportUsersQuery = {
  __typename?: "Query";
  AccountsMsSearchSupportUsers: {
    __typename?: "AccountsMsSearchSupportUsersResponse";
    data?: Array<{
      __typename?: "AccountsMsSearchSupportUsersResponseData";
      id: string;
      accessId: string;
      rights?: Array<string> | null;
      firstName: string;
      lastName: string;
      jobTitle: string;
      email: string;
      profile: AccountsMsSearchSupportUsersResponseDataProfile;
      status: AccountsMsSearchSupportUsersResponseDataStatus;
      invitedBy: string;
      createdTs: number;
      AccessIdData?: {
        __typename?: "AccountsMsSearchPlatformAccessesResponseData";
        igg: string;
        lastAccessTs: number;
      } | null;
    }> | null;
    pagination: {
      __typename?: "AccountsMsPagedPagination";
      page: number;
      total: number;
      size: number;
    };
  };
};

export type AccountsMsCountSupportUsersByStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AccountsMsCountSupportUsersByStatusQuery = {
  __typename?: "Query";
  AccountsMsCountSupportUsersByStatus: {
    __typename?: "AccountsMsCountSupportUsersByStatusResponse";
    all: number;
    enabled: number;
    disabled: number;
    inviting: number;
  };
};

export type UpdateAgentMutationVariables = Exact<{
  request?: InputMaybe<AccountsMsUpdateSupportUserRequest>;
}>;

export type UpdateAgentMutation = {
  __typename?: "Mutation";
  AccountsMsUpdateSupportUser?: any | null;
};

export type SearchAccountsMsMeQueryVariables = Exact<{
  request?: InputMaybe<AccountsMsMeRequest>;
}>;

export type SearchAccountsMsMeQuery = {
  __typename?: "Query";
  AccountsMsMe: {
    __typename?: "AccountsMsMeResponse";
    userId: string;
    sharedUserProfileTs: number;
    systemUserInfo?: any | null;
    supportUserInfo: {
      __typename?: "AccountsMsMeResponseSupportUser";
      igg: string;
      email: string;
      firstName: string;
      lastName: string;
      rights?: Array<string> | null;
    };
  };
};

export type GlobalSearchQueryVariables = Exact<{
  searchQuery: Scalars["String"]["input"];
  limitAccounts: Scalars["Int"]["input"];
  limitUsers: Scalars["Int"]["input"];
}>;

export type GlobalSearchQuery = {
  __typename: "Query";
  AccountsMsSearchAccounts: {
    __typename?: "AccountsMsSearchAccountsResponse";
    data?: Array<{
      __typename?: "AccountsMsSearchAccountsResponseData";
      id: string;
      number: string;
      name: string;
    }> | null;
    pagination: { __typename?: "AccountsMsPagedPagination"; total: number };
  };
  AccountsMsSearchUsers: {
    __typename?: "AccountsMsSearchUsersResponse";
    data?: Array<{
      __typename?: "AccountsMsSearchUsersResponseData";
      id: string;
      email: string;
      firstName: string;
      lastName: string;
    }> | null;
    pagination: { __typename?: "AccountsMsPagedPagination"; total: number };
  };
};

export type AccountsMsStartSupportMutationVariables = Exact<{
  request?: InputMaybe<AccountsMsStartSupportRequest>;
}>;

export type AccountsMsStartSupportMutation = {
  __typename?: "Mutation";
  AccountsMsStartSupport: {
    __typename?: "AccountsMsStartSupportResponse";
    expiresTs: number;
    sessionId: string;
  };
};

export type GetNotificationsByUserQueryQueryVariables = Exact<{
  request?: InputMaybe<NotificationsMsSearchNotificationsRequest>;
}>;

export type GetNotificationsByUserQueryQuery = {
  __typename?: "Query";
  NotificationsMsSearchNotifications: {
    __typename?: "NotificationsMsSearchNotificationsResponse";
    data?: Array<{
      __typename?: "NotificationsMsSearchNotificationsResponseData";
      id: string;
      subject: string;
      hasAttachments: boolean;
      createdTs: number;
      status: NotificationsMsSearchNotificationsResponseDataStatus;
      failedReason: NotificationsMsSearchNotificationsResponseDataFailedReason;
      AccountIdData?: {
        __typename?: "AccountsMsSearchAccountsResponseData";
        registrationName: string;
        number: string;
      } | null;
      UserIdData?: {
        __typename?: "AccountsMsSearchUsersResponseData";
        firstName: string;
        lastName: string;
        email: string;
      } | null;
    }> | null;
    pagination: {
      __typename?: "NotificationsMsPagedPagination";
      size: number;
      page: number;
      total: number;
    };
  };
};

export const GetAccountDetailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAccountDetail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AccountsMsSearchAccountsRequest" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsSearchAccounts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "zipCode" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "MainPrivilegeIdData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "UserIdData" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountDetailQuery,
  GetAccountDetailQueryVariables
>;
export const GetAccountUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAccountUsers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountsMsSearchPrivilegesRequest" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsSearchPrivileges" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enabledLastTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "UserIdData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdTs" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Subscriptions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "RolesProfileIdData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      { kind: "Field", name: { kind: "Name", value: "size" } },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAccountUsersQuery,
  GetAccountUsersQueryVariables
>;
export const GetAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAccount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AccountsMsSearchAccountsRequest" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsSearchAccounts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "registrationNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "registrationName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "city" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "zipCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "address" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "MainPrivilegeIdData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "UserIdData" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAccountQuery, GetAccountQueryVariables>;
export const GetUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AccountsMsSearchUsersRequest" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsSearchUsers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locale" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timezone" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneAlt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobTitle" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const AccountsMsSearchPrivilegesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountsMsSearchPrivileges" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountsMsSearchPrivilegesRequest" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsSearchPrivileges" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "roles" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rolesInvalidatedTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "disabledTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastUsedTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invitedTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enabledLastTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accountId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "owner" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "AccountIdData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "number" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "registrationName" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "registrationNumber",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isActive" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdTs" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mainPrivilegeId" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Subscriptions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fromTs" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tillTs" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "privilegeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rolesProfileId" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "RolesProfileIdData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "roles" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "code" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "accountId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "parentId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "rootId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "system" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountsMsSearchPrivilegesQuery,
  AccountsMsSearchPrivilegesQueryVariables
>;
export const InviteAgentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "InviteAgent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountsMsInviteSupportUserRequest" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsInviteSupportUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InviteAgentMutation, InviteAgentMutationVariables>;
export const DisableAgentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DisableAgent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "AccountsMsDisableSupportUserRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsDisableSupportUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DisableAgentMutation,
  DisableAgentMutationVariables
>;
export const EnableAgentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EnableAgent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountsMsEnableSupportUserRequest" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsEnableSupportUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnableAgentMutation, EnableAgentMutationVariables>;
export const AccountsMsSearchSupportUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountsMsSearchSupportUsers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "AccountsMsSearchSupportUsersRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsSearchSupportUsers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rights" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobTitle" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "profile" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invitedBy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "AccessIdData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "igg" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastAccessTs" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                      { kind: "Field", name: { kind: "Name", value: "size" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountsMsSearchSupportUsersQuery,
  AccountsMsSearchSupportUsersQueryVariables
>;
export const AccountsMsCountSupportUsersByStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AccountsMsCountSupportUsersByStatus" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "AccountsMsCountSupportUsersByStatus",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "all" } },
                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                { kind: "Field", name: { kind: "Name", value: "disabled" } },
                { kind: "Field", name: { kind: "Name", value: "inviting" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountsMsCountSupportUsersByStatusQuery,
  AccountsMsCountSupportUsersByStatusQueryVariables
>;
export const UpdateAgentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateAgent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountsMsUpdateSupportUserRequest" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsUpdateSupportUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAgentMutation, UpdateAgentMutationVariables>;
export const SearchAccountsMsMeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchAccountsMsMe" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountsMsMeRequest" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsMe" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "userId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sharedUserProfileTs" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "systemUserInfo" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "supportUserInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "igg" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rights" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchAccountsMsMeQuery,
  SearchAccountsMsMeQueryVariables
>;
export const GlobalSearchDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GlobalSearch" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchQuery" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limitAccounts" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limitUsers" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsSearchAccounts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "filters" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "query" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "searchQuery" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "paging" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "size" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limitAccounts" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "number" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsSearchUsers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "filters" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "query" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "searchQuery" },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "paging" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "size" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "limitUsers" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalSearchQuery, GlobalSearchQueryVariables>;
export const AccountsMsStartSupportDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AccountsMsStartSupport" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AccountsMsStartSupportRequest" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "AccountsMsStartSupport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "expiresTs" } },
                { kind: "Field", name: { kind: "Name", value: "sessionId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountsMsStartSupportMutation,
  AccountsMsStartSupportMutationVariables
>;
export const GetNotificationsByUserQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetNotificationsByUserQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "request" },
          },
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "NotificationsMsSearchNotificationsRequest",
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "NotificationsMsSearchNotifications" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "request" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "request" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "subject" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasAttachments" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdTs" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "failedReason" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "AccountIdData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "registrationName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "number" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "UserIdData" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pagination" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "size" } },
                      { kind: "Field", name: { kind: "Name", value: "page" } },
                      { kind: "Field", name: { kind: "Name", value: "total" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationsByUserQueryQuery,
  GetNotificationsByUserQueryQueryVariables
>;
export const namedOperations = {
  Query: {
    GetAccountDetail: "GetAccountDetail",
    GetAccountUsers: "GetAccountUsers",
    GetAccount: "GetAccount",
    GetUser: "GetUser",
    AccountsMsSearchPrivileges: "AccountsMsSearchPrivileges",
    AccountsMsSearchSupportUsers: "AccountsMsSearchSupportUsers",
    AccountsMsCountSupportUsersByStatus: "AccountsMsCountSupportUsersByStatus",
    SearchAccountsMsMe: "SearchAccountsMsMe",
    GlobalSearch: "GlobalSearch",
    GetNotificationsByUserQuery: "GetNotificationsByUserQuery",
  },
  Mutation: {
    InviteAgent: "InviteAgent",
    DisableAgent: "DisableAgent",
    EnableAgent: "EnableAgent",
    UpdateAgent: "UpdateAgent",
    AccountsMsStartSupport: "AccountsMsStartSupport",
  },
};
