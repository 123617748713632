import {
  GetCountAgentsByStatus,
  GetSearchAgentsQuery,
} from "@/api/graphql/agents/search/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client";
import { AccountsMsSearchSupportUsersRequest } from "@/api/graphql/types/graphql.ts";

export const getSearchAgents = async (
  request: AccountsMsSearchSupportUsersRequest,
) => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(GetSearchAgentsQuery, {
    request,
  });
};

export const getCountAgentsByStatus = async () => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(GetCountAgentsByStatus);
};
