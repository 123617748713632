export const appLocales = {
  "fr-FR": "fr-FR",
  "en-US": "en-US",
} as const;

export type AppLocale = keyof typeof appLocales;

export const APP_LOCALES = Object.keys(appLocales) as Array<AppLocale>;

export type CountryCode = AppLocale extends `${infer _}-${infer Country}`
  ? Country
  : never;

export const countryCodes: Record<CountryCode, CountryCode> = {
  FR: "FR",
  US: "US",
};

export const MULTIPLE_LOCALES_COUNTRIES: Partial<
  Record<CountryCode, Array<AppLocale>>
> = {
  US: ["en-US", "fr-FR"],
};

export const COUNTRY_CODES = Object.keys(countryCodes) as Array<CountryCode>;

// TODO - add default locale as constant in .env
export const DEFAULT_LOCALE = "en-US" as AppLocale;

export const isAppLocale = (locale: string | undefined): locale is AppLocale =>
  APP_LOCALES.includes(locale as AppLocale);

export const isCountryCode = (
  country: string | undefined,
): country is CountryCode => COUNTRY_CODES.includes(country as CountryCode);
