import { InMemoryWebStorage, User, WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderNoUserManagerProps } from "react-oidc-context";

import { env } from "@/env";

export type OidcConfig = Omit<AuthProviderNoUserManagerProps, "userStore">;
export const getOidcConfig = (
  configOverrides?: Partial<OidcConfig>,
): OidcConfig => ({
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  silent_redirect_uri: env.OIDC_REDIRECT_URI,
  authority: env.OIDC_AUTHORITY,
  client_id: env.OIDC_CLIENT_ID,
  redirect_uri: env.OIDC_REDIRECT_URI,
  automaticSilentRenew: true,
  scope: "phone openid profile email",
  metadata: {
    issuer: env.OIDC_ISSUER,
    authorization_endpoint: env.OIDC_ISSUER + "/oauth2/authorize",
    token_endpoint: env.OIDC_ISSUER + "/oauth2/token",
    userinfo_endpoint: env.OIDC_ISSUER + "/oauth2/userInfo",
    end_session_endpoint: `${env.OIDC_ISSUER}${
      env.OIDC_SIGN_OUT_PATH
    }?client_id=${env.OIDC_CLIENT_ID}&logout_uri=${
      window.location.origin + env.OIDC_SIGN_OUT_PATH
    }`,
  },
  ...configOverrides,
});

export const getUserStore = () =>
  new WebStorageStateStore({
    store: globalThis?.window?.localStorage ?? InMemoryWebStorage,
  });
