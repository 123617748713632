import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { getAccountUsers } from "@/api/graphql/accounts/getAccountUsers/api.ts";
import { toAccountUsersBO } from "@/api/graphql/accounts/getAccountUsers/mappers.ts";
import { UseGetAccountUsersProps } from "@/api/graphql/accounts/getAccountUsers/types.ts";
import { queries } from "@/api/graphql/constants.ts";
import {
  AccountsMsPagedPagination,
  AccountsMsSearchPrivilegesResponseData,
} from "@/api/graphql/types/graphql.ts";

export const useGetAccountUsers = ({
  accountId,
  searchTerm,
  currentPage,
}: UseGetAccountUsersProps) => {
  const { data, isLoading } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [queries.GET_ACCOUNT_USERS, accountId, searchTerm, currentPage],
    queryFn: () => {
      try {
        return getAccountUsers({
          filters: {
            accountId,
            query: {
              user: searchTerm.length > 2 ? searchTerm : "",
            },
          },
        });
      } catch {
        return;
      }
    },
  });
  const accountUsers = data?.AccountsMsSearchPrivileges.data as
    | AccountsMsSearchPrivilegesResponseData[]
    | null;
  const pagination = data?.AccountsMsSearchPrivileges
    .pagination as AccountsMsPagedPagination;

  return {
    accountUsers: toAccountUsersBO(accountUsers),
    pagination,
    isLoading,
  };
};
