import { BubbleTextProps } from "@totalenergiescode/mobility-business-rev-design-system";
import { match } from "ts-pattern";

import { NotificationsMsSearchNotificationsResponseDataStatus } from "@/api/graphql/types/graphql.ts";

export const getColorFromNotificationStatus = (
  status: NotificationsMsSearchNotificationsResponseDataStatus,
): BubbleTextProps["variant"] =>
  match(status)
    // @ts-expect-error first iteration
    .with("", () => "orange" as const)
    .with(
      NotificationsMsSearchNotificationsResponseDataStatus.Handled,
      () => "green" as const,
    )
    .otherwise(() => "grey" as const);
