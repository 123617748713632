import { createRouter, RouterProvider } from "@tanstack/react-router";
import { useAuth } from "react-oidc-context";

import { routeTree } from "@/routeTree.gen.ts";

const router = createRouter({
  routeTree,
  context: {
    auth: undefined!,
    roles: undefined!,
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const RouterProviderWithContext = () => {
  const auth = useAuth();

  //TODO: Obtain the loged user roles here to pass them in the context
  return (
    <RouterProvider
      router={router}
      context={{ auth, roles: ["agent", "super-agent"] }}
    />
  );
};

export { RouterProviderWithContext };
