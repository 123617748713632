import { styled } from "@mui/material";
import {
  Avatar,
  Identity,
  PopOver,
  Search,
} from "@totalenergiescode/mobility-business-rev-design-system";

export const StyledDiv = styled("div")``;

export const StyledNavbarContainerBackground = styled("div")`
  background-color: #0ea3fb;
`;

export const StyledNavbarContainer = styled("div")`
  display: flex;
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const StyledItemsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding-left: 24px;
`;

export const StyledSearchFieldContainer = styled("div")`
  flex: 0 1 60%;
  min-width: 200px;
`;

export const StyledSearchIcon = styled(Search)`
  color: white;
`;

export const StyledPopOver = styled(PopOver)`
  .MuiPopover-paper {
    overflow: visible;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background-color: #50e3c2;
  .MuiTypography-title3 {
    color: white;
  }
`;

export const StyledIdentity = styled(Identity)<{
  igg: string;
}>`
  .MuiAvatar-circular {
    background-color: #50e3c2;
    * {
      color: white;
    }
  }

  .MuiTypography-text1 {
    color: #86959b;
    &:after {
      content: "IGG:${({ igg }) => igg}";
      display: block;
      width: 100%;
      color: #86959b;
    }
  }
`;

export const StyledIconOuterContainer = styled("div")`
  display: flex;
  align-items: center;
`;

export const StyledIconContainer = styled("div")``;

export const StyledPopOverContainer = styled("div")`
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #f1f4f6;
  border-radius: 20px 0px 20px 20px;
  box-shadow: 0px 10px 40px 0px rgba(36, 41, 42, 0.1);
  display: flex;
  flex-direction: column;
  padding-inline: 5px;
  padding-top: 1rem;
`;

export const StyledImage = styled("img")`
  width: 34px;
  height: 34px;
`;

export const StyledIconTriggersContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
