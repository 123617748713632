import type { SVGProps } from "react";
import { memo } from "react";
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 40 40'
    color={props.color || "#0077FB"}
  >
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M21.414 11.598a1.97 1.97 0 0 0-2.828 0l-8.293 8.469a1.036 1.036 0 0 0 0 1.444.985.985 0 0 0 1.414 0L13 20.19V27.5a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7.31l1.293 1.32a.985.985 0 0 0 1.414 0c.39-.398.39-1.045 0-1.444zM22 23.125c0-.345-.224-.625-.5-.625h-3c-.276 0-.5.28-.5.625v3.75c0 .345.224.625.5.625h3c.276 0 .5-.28.5-.625z'
      clipRule='evenodd'
    />
  </svg>
);
const Memo = memo(SvgHome);

export default Memo;
