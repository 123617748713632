import { LOCAL_STORAGE_KEYS } from "@/types/localStorage";

export const getLocalStorageItem = <T = unknown>(
  key: LOCAL_STORAGE_KEYS | string,
): T | null => {
  const item = localStorage.getItem(key);

  if (item) {
    try {
      const parsed = JSON.parse(item);

      return parsed as T;
    } catch (error) {
      console.error(error);

      return item as T | null;
    }
  }

  return null;
};

export const removeLocalStorageItem = (
  key: LOCAL_STORAGE_KEYS | string,
): void => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = (): void => {
  localStorage.clear();
};

export const setLocalStorageItem = <T = unknown>(
  key: LOCAL_STORAGE_KEYS | string,
  value: T,
): void => {
  try {
    const stringifiedItem = JSON.stringify(value);

    if (typeof stringifiedItem === "undefined") {
      console.error("stringifiedItem is undefined");

      return;
    }

    localStorage.setItem(key, stringifiedItem);
  } catch (error) {
    console.error(error);
  }
};
