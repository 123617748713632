export const toFullName = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
}) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

return firstName || lastName || "";
};
