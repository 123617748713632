import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import {
  Button,
  Text,
  TickS,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AccountsMsInviteSupportUserRequestProfile } from "@/api/graphql/types/graphql.ts";
import { useBreadcrumbItems, Utils } from "@/components/Agents/Add/hooks.ts";
import { RoundedTopLeftContainer } from "@/components/containers/RoundedTopLeftContainer";
import { ControlledSelect } from "@/components/shared/ControlledSelect";
import { ControlledTextField } from "@/components/shared/ControlledTextField";
import { PageHeader } from "@/components/shared/PageHeader";
import { useAddSnackbar } from "@/contexts/snackbar";
import { useInviteSupportUser } from "@/hooks/agent/invite/hooks.tsx";
import { inviteAgent } from "@/hooks/agent/invite/types.ts";

import {
  StyledButtonContainer,
  StyledContainer,
  StyledDivider,
  StyledForm,
  StyledInputContainer,
  StyledLightGreyText,
  StyledSection,
  StyledSelectContainer,
  StyledTopContainer,
} from "../styles";
import { userFormSchema, type UserFormType } from "../types";

const InviteAgentForm = () => {
  const { t: tUsers } = useTranslation("users");
  const { t: tAgents } = useTranslation("agents");
  const addSnackbar = useAddSnackbar();
  const { mutateAsync } = useInviteSupportUser();
  const navigate = useNavigate({ from: "/backoffice-users/add" });
  const { control, handleSubmit } = useForm<UserFormType>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      iGG: "",
      email: "",
      jobTitle: "",
      profile: undefined,
    },
  });
  const tempOptions = Utils();
  const breadcrumbItems = useBreadcrumbItems();
  const handleSubmitMutation = async (values: UserFormType) => {
    const inviteAgentMapped: inviteAgent = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      igg: values.iGG,
      jobTitle: values.jobTitle || "",
      profile:
        values.profile === "AGENT"
          ? AccountsMsInviteSupportUserRequestProfile.Agent
          : AccountsMsInviteSupportUserRequestProfile.SuperAgent,
    };

    mutateAsync(inviteAgentMapped)
      .then(() => {
        addSnackbar({
          type: "success",
          title: tUsers("users.forms.invitationSent"),
          message: `${tUsers("users.forms.invitationSentDescription")}: ${values.email}`,
          isAutoClose: true,
        });
        navigate({
          to: "/backoffice-users",
          params: { isSuccess: true, email: values.email },
        });
      })
      .catch(() => {
        addSnackbar({
          type: "error",
          title: tUsers("users.forms.fillAllFieldsMandatory"),
          message: ``,
          isAutoClose: true,
        });
      });
  };

  return (
    <>
      <PageHeader
        breadcrumbItems={breadcrumbItems}
        title={tUsers("users.forms.inviteAgent")}
        href={""}
      />
      <RoundedTopLeftContainer>
        <StyledForm onSubmit={handleSubmit((values) => handleSubmitMutation(values))}>
          <StyledTopContainer>
            <StyledSection>
              <Text variant='title3' fontWeight='medium'>
                {tUsers("users.forms.addUser")}
              </Text>
              <StyledDivider />
              <StyledContainer>
                <Text variant='title2' fontWeight='medium'>
                  {tUsers("users.forms.mailAddress")}
                </Text>
                <StyledLightGreyText
                  variant='sub3'
                  fontStyle='italic'
                  fontWeight='light'
                >
                  {tUsers("users.forms.emailDescription")}
                </StyledLightGreyText>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='email'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.email"),
                      dataTestId: "agent__invite-agent-form_first-name-input",
                    }}
                  />
                  <StyledLightGreyText
                    variant='sub3'
                    fontStyle='italic'
                    fontWeight='light'
                    sx={{ paddingTop: "5px" }}
                  >
                    {tUsers("users.forms.emailSecondDescription")}
                  </StyledLightGreyText>
                </StyledInputContainer>
              </StyledContainer>
            </StyledSection>
            <StyledSection>
              <Text variant='title3' fontWeight='medium'>
                {tUsers("users.forms.agent")}
              </Text>
              <StyledDivider />
              <StyledContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='firstName'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.firstName"),
                      dataTestId: "agent__invite-agent-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='lastName'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.lastName"),
                      dataTestId: "agent__invite-agent-form_last-name-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='iGG'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: "IGG",
                      dataTestId: "agent__invite-agent-form_igg-input",
                    }}
                  />
                </StyledInputContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledTextField
                    name='jobTitle'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.jobTitleOptional"),
                      dataTestId: "agent__invite-agent-form_function-input",
                    }}
                  />
                </StyledInputContainer>
              </StyledContainer>
            </StyledSection>
            <StyledSection>
              <Text variant='title3' fontWeight='medium'>
                {tUsers("users.forms.agentProfile")}
              </Text>
              <StyledDivider />

              <StyledContainer>
                <Text variant='title2' fontWeight='medium'>
                  {tUsers("users.forms.invitationByEmailAddress")}
                </Text>
                <StyledLightGreyText
                  variant='sub3'
                  fontStyle='italic'
                  fontWeight='light'
                >
                  {tAgents("agents.emailDescription")}
                </StyledLightGreyText>
              </StyledContainer>
              <StyledSelectContainer>
                <StyledInputContainer fixedWidth>
                  <ControlledSelect
                    control={control}
                    name='profile'
                    namespace='users'
                    selectProps={{
                      className: "test-select",
                      type: "options",
                      options: tempOptions,
                      label: tUsers("users.forms.chooseProfile"),
                      dataTestId:
                        "agent__invite-agent-form_profile-name-select",
                    }}
                  />
                </StyledInputContainer>
              </StyledSelectContainer>
            </StyledSection>
            <StyledButtonContainer>
              <Button
                variant='secondary'
                isLowercase
                buttonText={tUsers("users.forms.cancel")}
                onClick={() => {}}
              />
              <Button
                leftIcon={<TickS width={25} height={25} color='white' />}
                variant='primary'
                type='submit'
                isLowercase
                buttonText={tAgents("agents.createNewAgent")}
                onClick={() => {}}
              />
            </StyledButtonContainer>
          </StyledTopContainer>
        </StyledForm>
      </RoundedTopLeftContainer>
    </>
  );
};

export default InviteAgentForm;
