import {
  AccountsMsSearchSupportUsersRequestFilters,
  AccountsMsSearchSupportUsersRequestFiltersStatus,
  AccountsMsSearchSupportUsersRequestSortField,
} from "@/api/graphql/types/graphql.ts";
import { useSortedFields } from "@/hooks/api/useSortedFields";

export const DEFAULT_SORTS: Parameters<
  typeof useSortedFields<AccountsMsSearchSupportUsersRequestSortField>
>[1] = {
  id: null,
  email: null,
  profile: null,
  lastName: null,
};

export const DEFAULT_SEARCH_AGENTS_FILTERS: AccountsMsSearchSupportUsersRequestFilters =
  {
    ids: null,
    igg: null,
    status:
      AccountsMsSearchSupportUsersRequestFiltersStatus.StatusUnknown &&
      AccountsMsSearchSupportUsersRequestFiltersStatus.StatusUnknown &&
      AccountsMsSearchSupportUsersRequestFiltersStatus.SupportUserDisabled &&
      AccountsMsSearchSupportUsersRequestFiltersStatus.SupportUserEnabled &&
      AccountsMsSearchSupportUsersRequestFiltersStatus.SupportUserInviting,
    query: null,
  };

export const USERS_PER_PAGE = 20;
export const DEFAULT_FIRST_PAGE = 1;
