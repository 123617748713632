import styled from "@emotion/styled";

export const StyledLeftHighlight = styled.div``;
export const StyledBorder = styled.div``;
export const StyledContainer = styled("div", {
  shouldForwardProp: (prop) =>
    !["$highlightColor", "$borderRadius"].includes(prop),
})<{ $highlightColor: string; $borderRadius?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ $borderRadius }) => $borderRadius || "10px"};
  box-shadow: 5px 5px 10px 0px rgba(36, 41, 42, 0.05);
  overflow: hidden;

  ${StyledBorder} {
    position: absolute;
    inset: 0;
    border: 1px solid ${({ theme }) => theme.palette.grey[100]};
    border-radius: ${({ $borderRadius }) => $borderRadius || "10px"};
  }

  ${StyledLeftHighlight} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    background-color: ${({ $highlightColor }) => $highlightColor};
  }
`;
