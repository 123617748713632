import { graphql } from "../../types";

export const StartSupportSessionMutation = graphql(`
  mutation AccountsMsStartSupport($request: AccountsMsStartSupportRequest) {
    AccountsMsStartSupport(request: $request) {
      expiresTs
      sessionId
    }
  }
`);
