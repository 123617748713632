import styled from "@emotion/styled";

export const StyledContainer = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 40px;
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.palette.grey[100]} 0px, ${theme.palette.background.default} 300px)`};
  padding: ${({ theme }) => theme.spacing(3)};
  padding-top: ${({ theme }) => theme.spacing(4)};
`;
