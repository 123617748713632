import { useContext, useEffect, useState } from "react";

import { IsSsrMobileContext } from "@/hooks/deviceInformations/useIsMobile/context.tsx";
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@/utils/styles/constants";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Don't forget to remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const useIsMobile = (options?: { hasCustomDesktopWidth?: boolean }) => {
  const isSsrMobile = useContext(IsSsrMobileContext);
  const { width: windowWidth } = useWindowSize();
  const isBrowserMobile =
    !!windowWidth && windowWidth < Number(MOBILE_BREAKPOINT.replace("px", ""));
  const isPersonalizedMobile =
    !!windowWidth && windowWidth < Number(DESKTOP_BREAKPOINT.replace("px", ""));

  return options?.hasCustomDesktopWidth
    ? isPersonalizedMobile
    : isSsrMobile || isBrowserMobile;
};
