import styled from "@emotion/styled";
import {
  Button,
  More,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";

export const StyledTableHeader = styled("div")``;
export const StyledTable = styled("div")``;
export const StyledCell = styled("div")``;
export const StyledCellContextualMenu = styled("div")``;
export const StyledRightIcon = styled(More)``;
export const StyledRowContainerLink = styled("div")``;
export const StyledCellHeaderText = styled(Text)``;
export const StyledCellText = styled(Text)``;

export const StyledCustomTableContainer = styled("div")`
  padding-inline: 34px;

  ${StyledTableHeader} {
    display: grid;
    grid-template-columns: repeat(7, 1fr) 30px;
    width: 100%;
    min-width: 100%;
    border-radius: 10px 10px 0px 0px;
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
  ${StyledTable} {
  }

  ${StyledRowContainerLink} {
    display: grid;
    grid-template-columns: repeat(7, 1fr) 30px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  }

  ${StyledCell} {
    padding-left: 14px;
    min-height: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-block: 14px;
    word-break: break-all;
    inline-size: 150px;
    overflow-wrap: break-word;

    ${StyledCellHeaderText} {
      color: ${({ theme }) => theme.palette.grey[700]};
      font-weight: 700;
    }

    ${StyledCellText} {
      color: ${({ theme }) => theme.palette.grey[900]};
    }
  }

  ${StyledCellContextualMenu} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${StyledRightIcon} {
      color: ${({ theme }) => theme.palette.primary[500]};
      width: 26px;
      height: auto;
    }
  }
`;

export const StyledButton = styled(Button)``;

export const StyledButtonContainer = styled("div")`
  display: flex;
  margin-top: 14px;
  margin-inline: 34px;
  justify-content: center;
  ${StyledButton} {
    width: 100%;
  }
`;

export const StyledCurrentCountOnTotalText = styled(Text)`
  color: ${({ theme }) => theme.palette.grey[500]};
  text-align: center;
`;

export const StyledCurrentCountOnTotalContainer = styled("div")`
  padding-block: 14px;
`;
