import styled from "@emotion/styled";

import { DIVIDED_BY_IMAGE_LAYOUT_IMAGE_MOBILE_MAX_HEIGHT } from "@/utils/styles/constants";

export const StyledImage = styled("img")``;
export const StyledChildren = styled("div")``;

export const StyledContainer = styled("div")<{ $isMobile: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.$isMobile ? "start" : "space-between")};
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  flex-direction: ${(props) => (props.$isMobile ? "column-reverse" : "row")};

  ${StyledImage} {
    height: 100vh;
    object-fit: cover;
    object-position: center;
    max-width: 60vw;
    border-radius: 30px 0px 0px 30px;
  }

  ${StyledChildren} {
    flex-grow: 1;
    flex-shrink: 0;
  }

  ${(props) =>
    props.$isMobile &&
    `
    ${StyledImage} {
      max-width: 100%;
      width: 100%;
      max-height:${DIVIDED_BY_IMAGE_LAYOUT_IMAGE_MOBILE_MAX_HEIGHT};
      border-radius: 0px 0px 30px 30px;
      margin-bottom: 
    }
  `}
`;
