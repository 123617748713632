import { useState } from "react";

import {
  ApiSort,
  SortDirection,
  SortDirections,
  UseSortedFieldsReturnType,
} from "@/hooks/api/useSortedFields";
import { setValueAtFirstPlaceInANewMap } from "@/utils/sort/setValueAtFirstPlaceInANewMap";

const getOppositeDirection = (ascending: SortDirection | undefined): boolean =>
  !ascending ? true : false;

export const useSortedFields = <T extends string>(
  initialFields: T[],
  initiallySortedFields?: Partial<Record<T, SortDirection>>,
): UseSortedFieldsReturnType<T> => {
  const getInitialState = (): Map<T, SortDirection> => {
    const initialMap = new Map<T, SortDirection>();

    initialFields.forEach((property) => {
      const defaultValueForProperty = initiallySortedFields?.[property];
      const initialDefaultValueForProperty =
        typeof defaultValueForProperty === "boolean"
          ? defaultValueForProperty
          : null;

      initialMap.set(property, initialDefaultValueForProperty);
    });

    return initialMap;
  };
  const [sortedFields, setSortedFields] =
    useState<SortDirections<T>>(getInitialState());
  const updateSortDirection: UseSortedFieldsReturnType<T>["updateSortDirection"] =
    (property: T, direction?: SortDirection) => {
      const updatedDirection =
        direction === false
          ? direction
          : getOppositeDirection(sortedFields.get(property));
      const updatedMap = setValueAtFirstPlaceInANewMap<T, SortDirection>(
        sortedFields,
        property,
        updatedDirection,
      );

      updatedMap.set(property as T, updatedDirection);

      setSortedFields(updatedMap);
    };
  const removeSortDirection: UseSortedFieldsReturnType<T>["removeSortDirection"] =
    (property: string) => {
      const updatedMap = new Map<T, SortDirection>(sortedFields);

      updatedMap.delete(property as T);
      setSortedFields(updatedMap);
    };
  const getFieldsToSortAsArrayOfString: UseSortedFieldsReturnType<T>["getFieldsToSortAsArrayOfString"] =
    () => {
      const result = [] as ApiSort<T>;

      sortedFields.forEach((value, currentKey) => {
        if (typeof value === "boolean") {
          result.push({ field: currentKey, ascending: value });
        }
      });

      return result;
    };

  return {
    sortedFields,
    updateSortDirection,
    removeSortDirection,
    getFieldsToSortAsArrayOfString,
  };
};
