import { graphql } from "@/api/graphql/types/gql";

export const GetSearchAgentsQuery = graphql(`
  query AccountsMsSearchSupportUsers(
    $request: AccountsMsSearchSupportUsersRequest
  ) {
    AccountsMsSearchSupportUsers(request: $request) {
      data {
        id
        accessId
        rights
        firstName
        lastName
        jobTitle
        email
        profile
        status
        invitedBy
        createdTs
        AccessIdData {
          igg
          lastAccessTs
        }
        status
      }
      pagination {
        page
        total
        size
      }
    }
  }
`);

export const GetCountAgentsByStatus = graphql(`
  query AccountsMsCountSupportUsersByStatus {
    AccountsMsCountSupportUsersByStatus {
      all
      enabled
      disabled
      inviting
    }
  }
`);
