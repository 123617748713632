export const setValueAtFirstPlaceInANewMap = <K extends string, V>(
  map: Map<K, V>,
  property: string,
  value: unknown,
): Map<K, V> => {
  const updatedMap = new Map();

  updatedMap.set(property, value);

  map.forEach((value, key) => {
    if (key !== property) {
      // Update other values by incrementing their position
      updatedMap.set(key, value);
    }
  });

  return updatedMap;
};
