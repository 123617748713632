import styled from "@emotion/styled";
import {
  Divider,
  Text,
  TextField,
} from "@totalenergiescode/mobility-business-rev-design-system";

import { PageHeader } from "@/components/shared/PageHeader";

export const StyledPageHeader = styled(PageHeader)`
  justify-content: space-between;
  padding: 0 40px 20px 40px;
`;

export const StyledTitle = styled(Text)`
  padding: 24px 0px 24px 34px;
`;

export const StyledDivider = styled(Divider)`
  margin-inline: 34px;
`;
export const StyledTabMenuContainer = styled("div")`
  padding-inline: 34px;
  display: flex;
  justify-content: space-between;
`;

export const StyledSearchFieldContainer = styled("div")`
  width: 60%;
  min-width: 200px;
  padding-left: 24px;
  margin: 14px;
`;
export const StyledSearchField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 50px 50px 0px 50px;
  *.MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.grey[200]};
    border-radius: 50px 50px 0px 50px;
    border-width: 1;
  }
  &:focus {
    /* Styles for the focused input field */
    /* Add your styles for the selected input field here */
    border-color: #007bff; /* Example: change border color when focused */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Example: add a box shadow when focused */
  }
  .MuiOutlinedInput-root {
    padding-left: 8px;
  }
  .MuiInputAdornment-root > div {
    padding: 8px;
  }
`;
