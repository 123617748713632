import {
  Box,
  CSSObject,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 200;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 40px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 40px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      border: "none",
      background: "none",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      border: "none",
      background: "none",
    },
  }),
}));

export const StyledBox = styled(Box)`
  display: flex;
`;

export const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 6rem;
  padding-bottom: 1rem;
`;

export const StyledListItem = styled(ListItem)`
  display: block;
  padding: 0;
`;

export const StyledListItemButton = styled(ListItemButton)<{
  open: boolean;
}>`
  min-height: 48px;
  padding-inline: 24px;
  justify-content: ${({ open }) => (open ? "initial" : "center")};
`;

export const StyledListItemIcon = styled(ListItemIcon)<{
  open: boolean;
}>`
  min-width: 0;
  margin-right: ${({ open }) => (open ? "24px" : "auto")};
  justify-content: center;
`;

export const StyledListItemText = styled(ListItemText)<{
  open: boolean;
}>`
  color: white;
  opacity: ${({ open }) => (open ? 1 : 0)};
`;
