import { Button } from "@totalenergiescode/mobility-business-rev-design-system";
import { useTranslation } from "react-i18next";

import DesktopIllustration from "@/assets/images/bo-sign-in-desktop.jpg";
import MobileIllustration from "@/assets/images/bo-sign-in-mobile.jpg";
import { useLoginUser } from "@/hooks/auth/loginUser/hooks";
import { useIsMobile } from "@/hooks/deviceInformations/useIsMobile";
import { DividedByImage } from "@/layouts/common/DividedByImage";

import {
  StyledContainer,
  StyledContent,
  StyledHintText,
  StyledInfoText,
  StyledLogo,
  StyledTitleText,
} from "./styles";

export const SignIn = () => {
  const { t: tSignIn } = useTranslation("signIn");
  const isMobile = useIsMobile();
  const { loginUser } = useLoginUser();
  const handleOnSignInClick = async (): Promise<void> => {
    await loginUser();
  };

  return (
    <DividedByImage
      mobileSrc={MobileIllustration}
      desktopSrc={DesktopIllustration}
    >
      <StyledContainer $isMobile={isMobile}>
        <StyledLogo />
        <StyledContent>
          <StyledTitleText variant='title4' fontWeight='regular'>
            {tSignIn("signIn.pleaseSignIn")}
          </StyledTitleText>
          <StyledHintText variant='sub3'>
            {tSignIn("signIn.hint")}
          </StyledHintText>
          <Button
            onClick={handleOnSignInClick}
            buttonText={tSignIn("signIn.cta")}
            variant='primary'
          />
          <StyledInfoText variant='sub3'>
            {tSignIn("signIn.redirectInfo")}
          </StyledInfoText>
        </StyledContent>
      </StyledContainer>
    </DividedByImage>
  );
};
