import styled from "@emotion/styled";
import { Select } from "@totalenergiescode/mobility-business-rev-design-system";

export const StyledSelect = styled(Select)`
  width: 150px;

  .MuiInputBase-input {
    padding: 12px 14px;
  }
`;
