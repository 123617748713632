import { useDsTheme } from "@totalenergiescode/mobility-business-rev-design-system";
import React, { Fragment } from "react";

import {
  StyledBreadcrumbUrl,
  StyledContainer,
  StyledContent,
  StyledDivider,
  StyledItem,
} from "@/components/shared/Breadcrumb/styles";
import { Props } from "@/components/shared/Breadcrumb/types";
import { useIsMobile } from "@/hooks/deviceInformations/useIsMobile";

const Breadcrumb: React.FC<Props> = ({ breadcrumbItems, forceTextColor }) => {
  const isMobile = useIsMobile();
  const theme = useDsTheme();

  return breadcrumbItems ? (
    <StyledContainer color={forceTextColor || theme.palette.grey[900]}>
      <StyledContent>
        {breadcrumbItems.map((breadcrumbItem, index) => (
          <Fragment key={index}>
            <StyledBreadcrumbUrl href={breadcrumbItem.path || ""}>
              <StyledItem variant={isMobile ? "text2" : "text3"}>
                {breadcrumbItem.title}
              </StyledItem>
            </StyledBreadcrumbUrl>
            &nbsp;
            {breadcrumbItems.length !== index + 1 ? <StyledDivider /> : null}
            &nbsp;
          </Fragment>
        ))}
      </StyledContent>
    </StyledContainer>
  ) : null;
};

export const MemoizedBreadcrumb = React.memo(Breadcrumb);
MemoizedBreadcrumb.displayName = "Breadcrumb";
