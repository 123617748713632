import styled from "@emotion/styled";
import { Link } from "@tanstack/react-router";
import { Text } from "@totalenergiescode/mobility-business-rev-design-system";
export const StyledContent = styled("div")``;
export const StyledItem = styled(Text)``;
export const StyledBreadcrumbUrl = styled(Link)``;
export const StyledSvg = styled("svg")``;
export const StyledTitle = styled(Text)``;
export const StyledDivider = styled("div")``;
export const StyledContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>`
  margin-top: 10px;

  ${StyledContent} {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    ${StyledBreadcrumbUrl} {
      padding-right: 10px;
      padding-left: 10px;

      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
        cursor: unset;

        ${StyledItem} {
          /* custom color since light's mode grey[400] (i.e rgba(158, 176, 183, 1)) is used whether the theme is dark or light*/
          color: rgba(158, 176, 183, 1);
        }
      }

      ${StyledItem} {
        color: ${({ color }) => color};
      }
    }

    ${StyledDivider} {
      background-color: ${({ color }) => color};
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }

    ${StyledTitle} {
      margin-bottom: 4px;
    }
  }
`;
