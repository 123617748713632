import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Drawer,
  Text,
  TickS,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUpdateAgent } from "@/api/graphql/agents/update/hook.ts";
import { UpdateAgent } from "@/api/graphql/agents/update/type.ts";
import { queries } from "@/api/graphql/constants.ts";
import { AccountsMsUpdateSupportUserRequestProfile } from "@/api/graphql/types/graphql.ts";
import { Utils } from "@/components/Agents/Add/hooks.ts";
import { Agent } from "@/components/Agents/Edit/type.ts";
import { userFormSchema, UserFormType } from "@/components/Agents/types.ts";
import { ControlledSelect } from "@/components/shared/ControlledSelect";
import { ControlledTextField } from "@/components/shared/ControlledTextField";
import { useAddSnackbar } from "@/contexts/snackbar";

import {
  StyledButtonContainer,
  StyledDrawerInnerContainer,
  StyledForm,
  StyledInputContainer,
  StyledRoundedTopLeftContainer,
  StyledSection,
} from "../styles";

interface EditAgentProps {
  isOpen: boolean;
  onClose: () => void;
  agent: Agent;
}

const EditAgent: FC<EditAgentProps> = ({ isOpen, onClose, agent }) => {
  const { t: tUsers } = useTranslation("users");
  const addSnackbar = useAddSnackbar();
  const { mutateAsync } = useUpdateAgent();
  const tempOptions = Utils();
  const queryClient = useQueryClient();
  const { control, handleSubmit } = useForm<UserFormType>({
    resolver: zodResolver(userFormSchema),
    defaultValues: {
      firstName: agent?.firstName || "",
      lastName: agent?.lastName || "",
      iGG: agent?.iGG,
      email: agent?.email,
      jobTitle: agent?.jobTitle || "",
      profile: agent?.profile || "",
    },
  });
  const onSubmit = handleSubmit(async (formData) => {
    const agentMapped: UpdateAgent = {
      id: agent.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      jobTitle: formData.jobTitle || "",
      profile:
        formData.profile === "AGENT"
          ? AccountsMsUpdateSupportUserRequestProfile.Agent
          : AccountsMsUpdateSupportUserRequestProfile.SuperAgent,
    };

    try {
      await mutateAsync(agentMapped);
      addSnackbar({
        type: "success",
        title: tUsers("users.forms.modifiedAgent"),
        message: ``,
        isAutoClose: true,
      });
      await queryClient.invalidateQueries({
        queryKey: [queries.GET_SEARCH_AGENTS],
      });
      onClose();
    } catch {
      addSnackbar({
        type: "error",
        title: tUsers("users.forms.fillAllFieldsMandatory"),
        message: ``,
        isAutoClose: true,
      });
    }
  });

  return (
    <>
      <Drawer
        width={"520px"}
        dataTestId={"agent___edit-agent-drawer"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text sx={{ margin: "20px 20px 0 20px" }} variant='title5'>
          {tUsers("users.forms.agentModification")}
        </Text>
        <StyledForm onSubmit={onSubmit}>
          <StyledRoundedTopLeftContainer>
            <StyledDrawerInnerContainer>
              <StyledSection>
                <Text sx={{ marginBottom: "20px" }} variant='title3'>
                  {tUsers("users.forms.personalInformation")}
                </Text>
                <StyledInputContainer>
                  <ControlledTextField
                    name='firstName'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.firstName"),
                      dataTestId: "agent__edit-agent-form_first-name-input",
                    }}
                  />
                </StyledInputContainer>

                <StyledInputContainer>
                  <ControlledTextField
                    name='lastName'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.lastName"),
                      dataTestId: "agent__edit-agent-form_family-name-input",
                    }}
                  />
                </StyledInputContainer>

                <StyledInputContainer>
                  <ControlledTextField
                    name='iGG'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: "IGG",
                      dataTestId: "users__edit-agent-form_igg-input",
                      isDisabled: true,
                    }}
                  />
                </StyledInputContainer>

                <StyledInputContainer>
                  <ControlledTextField
                    name='email'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.email"),
                      dataTestId: "agent__edit-agent-form_email-input",
                      isDisabled: true,
                    }}
                  />
                </StyledInputContainer>

                <StyledInputContainer>
                  <ControlledTextField
                    name='jobTitle'
                    control={control}
                    namespace='users'
                    textFieldProps={{
                      label: tUsers("users.forms.jobTitleOptional"),
                      dataTestId: "agent__edit-agent-form_jobTitle-input",
                    }}
                  />
                </StyledInputContainer>
              </StyledSection>

              <StyledSection>
                <Text sx={{ marginBlock: "35px" }} variant='title3'>
                  {tUsers("users.forms.agentProfile")}
                </Text>
                <ControlledSelect
                  control={control}
                  name='profile'
                  namespace='users'
                  selectProps={{
                    className: "test-select",
                    type: "options",
                    options: tempOptions,
                    label: tUsers("users.forms.chooseProfile"),
                    dataTestId: "agent__edit-agent-form_profile-select",
                  }}
                />
              </StyledSection>

              <StyledButtonContainer
                sx={{ marginTop: "40px", justifyContent: "space-between" }}
              >
                <Button
                  variant='secondary'
                  isLowercase
                  buttonText={tUsers("users.forms.cancel")}
                  onClick={onClose}
                />
                <Button
                  leftIcon={<TickS width={25} height={25} color='white' />}
                  variant='primary'
                  isLowercase
                  type='submit'
                  buttonText={tUsers("users.forms.saveChanges")}
                  onClick={() => {}}
                />
              </StyledButtonContainer>
            </StyledDrawerInnerContainer>
          </StyledRoundedTopLeftContainer>
        </StyledForm>
      </Drawer>
    </>
  );
};

export default EditAgent;
