import { Text } from "@totalenergiescode/mobility-business-rev-design-system";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { HighlightTextProps } from "@/components/Search/types.ts";

import { StyledDiv, StyledFlexContainer } from "./styles";

export const HighlightText: FC<HighlightTextProps> = ({
  fullText,
  textToHighlight,
}) => {
  const regex = new RegExp(textToHighlight, "gi");
  const highlightedText = fullText.replace(
    regex,
    (match) => `<strong>${match}</strong>`,
  );

  return (
    <Text fontWeight='regular' variant='title1'>
      <span
        dangerouslySetInnerHTML={{
          __html: highlightedText,
        }}
      />
    </Text>
  );
};

export const SearchExample = ({
  isNoOptionsVersion,
}: {
  isNoOptionsVersion?: boolean;
}) => {
  const { t: tSearch } = useTranslation("search");

  return (
    <>
      {isNoOptionsVersion && (
        <StyledDiv sx={{ marginBottom: "30px" }}>
          <Text
            sx={{ marginBottom: "10px", color: "#0077FB" }}
            variant='title3'
          >
            {tSearch("search.noSuggestions")}
          </Text>
          <Text variant='sub3'>{tSearch("search.noSuggestions2")}</Text>
        </StyledDiv>
      )}
      <Text
        sx={{
          marginBottom: "20px",
          color: isNoOptionsVersion ? "#86959B" : "#0077FB",
        }}
        variant='title3'
      >
        {tSearch("search.searchExample")}
      </Text>
      <StyledDiv sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <StyledFlexContainer style={{ display: "flex" }}>
          <Text sx={{ width: "50%" }} fontWeight='regular' variant='title1'>
            TotalEnergies
          </Text>
          <Text
            sx={{ width: "50%", color: "#86959B" }}
            fontWeight='regular'
            variant='title1'
          >
            {tSearch("search.companyName")}
          </Text>
        </StyledFlexContainer>
        <StyledFlexContainer>
          <Text sx={{ width: "50%" }} fontWeight='regular' variant='title1'>
            87123456
          </Text>
          <Text
            sx={{ width: "50%", color: "#86959B" }}
            fontWeight='regular'
            variant='title1'
          >
            {tSearch("search.customerAccount")}
          </Text>
        </StyledFlexContainer>
        <StyledFlexContainer>
          <Text sx={{ width: "50%" }} fontWeight='regular' variant='title1'>
            Durant
          </Text>
          <Text
            sx={{ width: "50%", color: "#86959B" }}
            fontWeight='regular'
            variant='title1'
          >
            {tSearch("search.lastName")}
          </Text>
        </StyledFlexContainer>
        <StyledFlexContainer>
          <Text sx={{ width: "50%" }} fontWeight='regular' variant='title1'>
            Durant.nicolas@entreprise.com
          </Text>
          <Text
            sx={{ width: "50%", color: "#86959B" }}
            fontWeight='regular'
            variant='title1'
          >
            {tSearch("search.email")}
          </Text>
        </StyledFlexContainer>
      </StyledDiv>
    </>
  );
};
