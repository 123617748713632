import { useAuth } from "react-oidc-context";

import { UseLogoutUserReturnType } from "@/hooks/auth/logoutUser";

export const useLogoutUser = (): UseLogoutUserReturnType => {
  const { signoutRedirect, removeUser } = useAuth();
  const logoutUser = () => {
    removeUser();
    signoutRedirect();
  };

  return { logoutUser };
};
