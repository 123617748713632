import { QueryClient } from "@tanstack/react-query";
import { GraphQLClient } from "graphql-request";

import { env } from "@/env";

import { requestMiddlewares } from "./config";

let graphqlClient: GraphQLClient;

export const getGraphqlClient = () => {
  if (!graphqlClient) {
    graphqlClient = new GraphQLClient(env.API_URL, {
      requestMiddleware: requestMiddlewares,
    });

    return graphqlClient;
  }

  return graphqlClient;
};

export const getQueryClient = () => {
  let queryClient;

  if (!queryClient) {
    queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 1,
        },
      },
    });

    return queryClient;
  }

  return queryClient;
};
