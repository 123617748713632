import { useMutation } from "@tanstack/react-query";

import { enableSupportUserQuery } from "@/api/graphql/agents/enable/api.ts";

const useEnableSupportUser = () => {
  const {
    mutateAsync: enableSupportUser,
    isSuccess,
    error,
  } = useMutation({
    mutationFn: async (id: string) => await enableSupportUserQuery({ id: id }),
    onSuccess: () => "success",
    onError: () => "error",
  });

  return { enableSupportUser, isSuccess, error };
};

export { useEnableSupportUser };
