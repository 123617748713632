import { useAuth } from "react-oidc-context";

import { UseRedirectUserToSignInReturnType } from "@/hooks/session/useRedirectUserToSignIn";

export const useRedirectUserToSignIn =
  (): UseRedirectUserToSignInReturnType => {
    const { signinRedirect } = useAuth();

    return { redirectUserToSignIn: signinRedirect };
  };
