import {
  Agent,
  SuperAgent,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { ReactElement } from "react";

import { FeSupportUserProfile } from "@/types/agents";

export const AGENT_PROFILE_ICON: Record<FeSupportUserProfile, ReactElement> = {
  AGENT: <Agent width={28} height={28} />,
  SUPER_AGENT: <SuperAgent width={28} height={28} />,
  PROFILE_UNKNOWN: <></>,
};
