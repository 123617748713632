import styled from "@emotion/styled";
import { Player } from "@lottiefiles/react-lottie-player";
import { Text } from "@totalenergiescode/mobility-business-rev-design-system";

import { HighlightLeftCard } from "@/components/containers/HighlightLeftCard";

export const StyledPrincipalText = styled(Text)``;
export const StyledPlayer = styled(Player)``;

export const StyledSecondText = styled(Text)`
  color: ${({ theme }) => theme.palette.grey[400]};
`;
export const StyledLottie = styled("div")`
  width: 100%;
  height: 200px;

  ${StyledPlayer} {
    height: 200px;
    width: 100%;
  }
`;
export const StyledContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  gap: ${({ theme }) => theme.spacing(0.5)};
  margin: 16px;
`;

export const StyledHighlightLeftCard = styled(HighlightLeftCard)`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    gap: ${theme.spacing(0.5)};
    margin: 16px ;
  `}
`;
