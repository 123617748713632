import { type GetUserRoleDisplayedName } from "./types";

export const getUserRoleDisplayedName: GetUserRoleDisplayedName = ({
  code,
  name,
  t,
}) => {
  // system profile role
  if (code) {
    return t(`profiles.codes.${code}`);
  }

  // custom profile role
  if (name) {
    return name;
  }

  return "-";
};
