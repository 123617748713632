import { AccountsMsSearchSupportUsersRequestFiltersStatus } from "@/api/graphql/types/graphql.ts";
import { AgentsListProps } from "@/components/Agents/List/types.ts";

const getStatusFilter = ({
  showUnknownUsers,
  showDisabledUsers,
  showInvitedUsers,
  showEnabledUsers,
}: Partial<AgentsListProps>) => {
  if (
    showDisabledUsers &&
    showEnabledUsers &&
    showInvitedUsers &&
    showUnknownUsers
  ) {
    return undefined;
  }
  if (showDisabledUsers) {
    return AccountsMsSearchSupportUsersRequestFiltersStatus.SupportUserDisabled;
  }
  if (showEnabledUsers) {
    return AccountsMsSearchSupportUsersRequestFiltersStatus.SupportUserEnabled;
  }
  if (showInvitedUsers) {
    return AccountsMsSearchSupportUsersRequestFiltersStatus.SupportUserInviting;
  }
  if (showUnknownUsers) {
    return AccountsMsSearchSupportUsersRequestFiltersStatus.StatusUnknown;
  }
};

export const getFilters = (props: Partial<AgentsListProps>) => ({
  status: getStatusFilter(props),
  query: props?.queryString,
});
