import { GenericPaginatedQuery } from "@/helpers/api/getNextPageParam";

export const getNextPageParam = <T extends string>({
  lastPage,
  allPages,
  key,
}: {
  lastPage: GenericPaginatedQuery<T> | undefined;
  allPages: Array<GenericPaginatedQuery<T> | undefined>;
  key: T;
}): number | undefined => {
  const allPaginations = allPages.map((page) => page?.[key].pagination);
  const currentElements = allPaginations.reduce(
    (acc, page) => acc + (page?.size || 0),
    0,
  );
  const lastPagination = lastPage?.[key].pagination;
  const totalElements =
    typeof lastPagination?.total === "number"
      ? lastPagination.total
      : typeof lastPagination?.total === "string"
        ? parseInt(lastPagination.total, 10)
        : undefined;

  if (totalElements && currentElements < totalElements) {
    return (lastPagination?.page || 0) + 1;
  }

  return;
};
