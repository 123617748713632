import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

import BackOffice from "@/components/BackOffice/component";
import { Loading } from "@/components/shared/Loading";
import { useMe } from "@/hooks/session/useMe";

const BackOfficeUsers = () => {
  const { data: meData, isLoading } = useMe();
  const router = useNavigate();

  useEffect(() => {
    if (
      !isLoading &&
      !meData?.AccountsMsMe.supportUserInfo.rights?.includes("SUPER_AGENT")
    ) {
      router({ to: "/" });
    }
  }, [meData, isLoading, router]);

  return (
    <div>
      <Loading isLoading={isLoading}>
        <BackOffice />
      </Loading>
    </div>
  );
};

export const Route = createFileRoute("/backoffice-users/")({
  component: () => <BackOfficeUsers />,
});
