import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { APP_LOCALES, isCountryCode } from "@/types/i18n";
import { SelectOptionWithStringLabel } from "@/types/overrides";

import { countryFlagSources, FLAG_IMAGE_SIZE } from "./constants";

export const useLanguageSelectorOptions = () => {
  const { t: tCommon } = useTranslation("common");

  return useMemo(() => {
    const options: Array<SelectOptionWithStringLabel<string>> = APP_LOCALES.map(
      (locale) => {
        const [_, country] = locale.split("-");
        const leftIcon = isCountryCode(country)
          ? countryFlagSources[country]
          : undefined;
        const label = tCommon(`common.locale.${locale}`);

        return {
          label,
          value: locale,
          leftIcon: leftIcon ? (
            <img
              alt={label}
              src={leftIcon}
              width={FLAG_IMAGE_SIZE}
              height={FLAG_IMAGE_SIZE}
            />
          ) : null,
        };
      },
    );

    return options.sort((a, b) => {
      if (
        a.label &&
        b.label &&
        typeof a.label === "string" &&
        typeof b.label === "string"
      ) {
        return a.label.localeCompare(b.label);
      }

      return 0;
    });
  }, [tCommon]);
};
