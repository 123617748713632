import { useTranslation } from "react-i18next";

import { AccountsMsCountSupportUsersByStatusResponse } from "@/api/graphql/types/graphql.ts";
import { useTabMenuContentProps } from "@/components/BackOffice/types.ts";

export const useBreadcrumbItems = (
  dashboard: string,
  backOfficeAgents: string,
) => [
  {
    title: dashboard,
    path: "/" as const,
  },
  {
    title: backOfficeAgents,
    path: "/backoffice-users" as const,
  },
];

export const useTabMenuContent = (
  props: useTabMenuContentProps & {
    countStatus?: AccountsMsCountSupportUsersByStatusResponse;
  },
) => {
  const { t: tAgents } = useTranslation("agents");
  const countStatus = {
    all: props.countStatus?.all || "0",
    enabled: props.countStatus?.enabled || "0",
    disabled: props.countStatus?.disabled || "0",
    inviting: props.countStatus?.inviting || "0",
  };

  return [
    {
      label: tAgents("agents.filters.all"),
      dataTestId: "tab-menu-button",
      hasBubbleText: true,
      isBadge: false,
      text: countStatus.all,
      variant: "grey",
      onClick: () => {
        props.setAgentListFilters((state) => ({
          ...state,
          showEnabledUsers: undefined,
          showInvitedUsers: undefined,
          showDisabledUsers: undefined,
          showUnknownUsers: undefined,
        }));
      },
    },
    {
      label: tAgents("agents.filters.activated"),
      dataTestId: "tab-menu-button",
      hasBubbleText: true,
      isBadge: false,
      text: countStatus.enabled,
      variant: "green",
      onClick: () => {
        props.setAgentListFilters((state) => ({
          ...state,
          showEnabledUsers: true,
          showInvitedUsers: false,
          showDisabledUsers: false,
          showUnknownUsers: false,
        }));
      },
    },
    {
      label: tAgents("agents.filters.waiting"),
      dataTestId: "tab-menu-button",
      hasBubbleText: true,
      isBadge: false,
      text: countStatus.inviting,
      variant: "orange",
      onClick: () => {
        props.setAgentListFilters((state) => ({
          ...state,
          showEnabledUsers: false,
          showInvitedUsers: true,
          showDisabledUsers: false,
          showUnknownUsers: false,
        }));
      },
    },
    {
      label: tAgents("agents.filters.disabled"),
      dataTestId: "tab-menu-button",
      hasBubbleText: true,
      isBadge: false,
      text: countStatus.disabled,
      variant: "grey",
      onClick: () => {
        props.setAgentListFilters((state) => ({
          ...state,
          showEnabledUsers: false,
          showInvitedUsers: false,
          showDisabledUsers: true,
          showUnknownUsers: false,
        }));
      },
    },
  ];
};
