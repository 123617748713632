import { CircularProgress } from "@totalenergiescode/mobility-business-rev-design-system";
import { RFC } from "react";

import { StyledLoadingContainer } from "@/components/shared/Loading/styles";

export const Loading: RFC<{ isLoading: boolean }> = ({
  children,
  isLoading,
}) =>
  isLoading ? (
    <StyledLoadingContainer>
      <CircularProgress dataTestId='loader' />
    </StyledLoadingContainer>
  ) : (
    children
  );
