import { graphql } from "@/api/graphql/types/gql";

export const AccountsMsSearchPrivilegesQuery = graphql(`
  query AccountsMsSearchPrivileges(
    $request: AccountsMsSearchPrivilegesRequest
  ) {
    AccountsMsSearchPrivileges(request: $request) {
      data {
        id
        roles
        rolesInvalidatedTs
        disabledTs
        lastUsedTs
        invitedTs
        enabledLastTs
        userId
        accountId
        owner
        status
        AccountIdData {
          id
          name
          number
          registrationName
          registrationNumber
          isActive
          createdTs
          mainPrivilegeId
        }
        Subscriptions {
          id
          fromTs
          tillTs
          privilegeId
          rolesProfileId
          RolesProfileIdData {
            id
            name
            roles
            code
            accountId
            parentId
            rootId
            system
          }
        }
      }
    }
  }
`);
