import { RFC } from "react";

import { useIsMobile } from "@/hooks/deviceInformations/useIsMobile";

import { StyledChildren, StyledContainer, StyledImage } from "./styles";

export const DividedByImage: RFC<{
  mobileSrc: string;
  desktopSrc: string;
}> = ({ mobileSrc, desktopSrc, children }) => {
  const isMobile = useIsMobile();

  return (
    <StyledContainer $isMobile={isMobile}>
      <StyledChildren>{children}</StyledChildren>
      <StyledImage src={isMobile ? mobileSrc : desktopSrc} alt='' />
    </StyledContainer>
  );
};
