import { AccountUsersBO } from "@/api/graphql/accounts/getAccountUsers/types.ts";
import { AccountsMsSearchPrivilegesResponseData } from "@/api/graphql/types/graphql.ts";

export const toAccountUsersBO = (
  accountUsers: AccountsMsSearchPrivilegesResponseData[] | null,
): AccountUsersBO => {
  if (!accountUsers) return [];

  return accountUsers.map((accountUser) => ({
    id: accountUser.id,
    enabledLastTs: accountUser.enabledLastTs,
    status: accountUser.status,
    userId: accountUser.userId,
    userFirstName: accountUser.UserIdData?.firstName || "",
    userLastName: accountUser.UserIdData?.lastName || "",
    userEmail: accountUser.UserIdData?.email || "",
    userCreatedTs: accountUser.UserIdData?.createdTs || 0,
    Subscriptions:
      accountUser.Subscriptions?.map((subscription) => ({
        roleName: subscription?.RolesProfileIdData?.name || "",
        roleCode: subscription?.RolesProfileIdData?.code || "",
      })) || [],
  }));
};
