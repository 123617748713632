import "./i18n/index";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/nunito/200-italic.css";
import "@fontsource/nunito/200.css";
import "@fontsource/nunito/300-italic.css";
import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400-italic.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/500-italic.css";
import "@fontsource/nunito/500.css";
import "@fontsource/nunito/600-italic.css";
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700-italic.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/800-italic.css";
import "@fontsource/nunito/800.css";
import "@fontsource/nunito/900-italic.css";
import "@fontsource/nunito/900.css";
import "@fontsource-variable/nunito";
import "@/utils/styles/global.css";

import { LicenseInfo } from "@mui/x-license";
import { QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { RouterProviderWithContext } from "@/contexts/router";
import { ThemeContextProvider } from "@/contexts/styles/theme";

import { getQueryClient } from "./api/graphql/client";
import { AuthenticationContext } from "./contexts/authentication";
import { SnackbarContextProvider } from "./contexts/snackbar";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);

// Render the app
const rootElement = document.getElementById("app")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  const queryClient = getQueryClient();

  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthenticationContext>
          <ThemeContextProvider>
            <SnackbarContextProvider>
              <RouterProviderWithContext />
            </SnackbarContextProvider>
          </ThemeContextProvider>
        </AuthenticationContext>
      </QueryClientProvider>
    </StrictMode>,
  );
}
