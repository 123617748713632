import { Link } from "@tanstack/react-router";
import {
  ArrowLeft,
  IconButton,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React from "react";

import { GO_BACK_ARROW_BUTTON_SIZE } from "@/components/shared/GoBackArrowButton/constants";

import { type GoBackArrowButtonProps } from "./types";

export const GoBackArrowButton: React.FC<GoBackArrowButtonProps> = ({
  href,
  dataTestId,
  className,
}) => (
  <Link to={href}>
    <IconButton
      svg={
        <ArrowLeft
          width={GO_BACK_ARROW_BUTTON_SIZE}
          height={GO_BACK_ARROW_BUTTON_SIZE}
        />
      }
      width={`${GO_BACK_ARROW_BUTTON_SIZE}px`}
      height={`${GO_BACK_ARROW_BUTTON_SIZE}px`}
      onClick={() => {}}
      className={className}
      dataTestId={dataTestId}
    />
  </Link>
);
