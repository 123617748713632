import {
  GetNotificationsByUserReturnType,
  NotificationBO,
} from "@/api/graphql/notifications/types.ts";

const mapNotificationFromGraphQL = (
  rawNotification: GetNotificationsByUserReturnType,
): NotificationBO => ({
  id: rawNotification.id,
  subject: rawNotification.subject,
  hasAttachments: rawNotification.hasAttachments,
  createdTs: rawNotification.createdTs,
  accountNumber: rawNotification.AccountIdData?.number,
  accountName: rawNotification.AccountIdData?.registrationName,
  userFirstName: rawNotification.UserIdData?.firstName,
  userLastName: rawNotification.UserIdData?.lastName,
  userEmail: rawNotification.UserIdData?.email,
  status: rawNotification.status,
  failedReason: rawNotification.failedReason,
});

export const mapNotificationsFromGraphQL = (
  rawNotifications: GetNotificationsByUserReturnType[] | undefined,
): NotificationBO[] => {
  if (!rawNotifications) {
    return [];
  }

  return rawNotifications.map(mapNotificationFromGraphQL);
};
