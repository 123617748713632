import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";

import { COOKIES_KEYS } from "@/types/cookies";
import { AppLocale, isAppLocale } from "@/types/i18n";
import { SelectChangeEvent } from "@/types/temporary";

import { useLanguageSelectorOptions } from "./hooks";
import { StyledSelect } from "./styles";
import { LanguageSelectorProps } from "./types";

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  dataTestId,
}) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const { t: _tCommon } = useTranslation("common");
  const selectOptions = useLanguageSelectorOptions();
  const handleUpdateLocale = async (newValue: AppLocale) => {
    if (newValue === currentLocale) {
      return;
    }
    try {
      Cookies.set(COOKIES_KEYS.SELECTED_LOCALE, newValue);
      i18n.changeLanguage(newValue);
      localStorage.setItem("lng", newValue);
    } catch (_error) {
      return;
    }
  };

  return (
    <StyledSelect
      type='options'
      dataTestId={dataTestId}
      options={selectOptions}
      value={currentLocale}
      onChange={(event: SelectChangeEvent) => {
        const value = event.target.value;

        if (typeof value !== "string" || !isAppLocale(value)) {
          return;
        }
        handleUpdateLocale(value);
      }}
    />
  );
};
