import { createFileRoute } from "@tanstack/react-router";

import { AccountDetails } from "@/components/Accounts/details";

const AccountProfileView = () => {
  // eslint-disable-next-line no-use-before-define
  const { id } = Route.useParams();

  return <AccountDetails id={id} />;
};

export const Route = createFileRoute("/accounts/$id")({
  component: AccountProfileView,
});
