import { UseLoginUserReturnType } from "@/hooks/auth/loginUser";
import { useRedirectUserToSignIn } from "@/hooks/session/useRedirectUserToSignIn";

const useLoginUser = (): UseLoginUserReturnType => {
  const { redirectUserToSignIn } = useRedirectUserToSignIn();
  const loginUser: UseLoginUserReturnType["loginUser"] = async (args) => {
    await redirectUserToSignIn(args);
  };

  return { loginUser };
};

export { useLoginUser };
