import { User } from "oidc-client-ts";

import { env } from "@/env";

export const getUser = () => {
  const oidcLocalStorageItem = `oidc.user:${env.OIDC_AUTHORITY}:${env.OIDC_CLIENT_ID}`;
  const oidcStorage = localStorage.getItem(oidcLocalStorageItem);

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const getBearer = () => {
  const user = getUser();

  if (!user) {
    throw new Error("missing user informations");
  }

  return user.access_token;
};

export const getIdToken = () => {
  const user = getUser();

  if (!user) {
    throw new Error("missing user informations");
  }

  return user.id_token;
};
