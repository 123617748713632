enum EntityType {
  Company = "Company",
  User = "User",
}

type UserDataType = {
  id: string;
  type: EntityType.User;
  email: string;
  firstName: string;
  lastName: string;
};

type CompanyDataType = {
  id: string;
  type: EntityType.Company;
  number: string;
  name: string;
};

type CombinedData = {
  id: string;
  type: EntityType;
} & (UserDataType | CompanyDataType);

type HighlightTextProps = { fullText: string; textToHighlight: string };

export type { CombinedData, CompanyDataType, HighlightTextProps, UserDataType };

export { EntityType };
