import { useEffect, useState } from "react";

import { type UseThemePreferencesReturnType } from "./types";

export const useThemePreferences = (): UseThemePreferencesReturnType => {
  const getInitialPreferedTheme = () => {
    return "light";
    if (typeof window !== "undefined") {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
      }
      return "light";
    }
    return "light";
  };
  const [preferedTheme, setPreferedTheme] = useState<"light" | "dark">(
    getInitialPreferedTheme(),
  );
  const handleBrowserPreferedThemeChange = ({
    matches,
  }: MediaQueryListEvent) => {
    if (matches) {
      setPreferedTheme("dark");
    } else {
      setPreferedTheme("light");
    }
  };

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleBrowserPreferedThemeChange);
    () =>
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", handleBrowserPreferedThemeChange);
  }, []);

  return { preferedTheme };
};
