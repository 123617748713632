import { graphql } from "@/api/graphql/types/gql";

export const GetAccountUsersQuery = graphql(`
  query GetAccountUsers($request: AccountsMsSearchPrivilegesRequest) {
    AccountsMsSearchPrivileges(request: $request) {
      data {
        id
        enabledLastTs
        status
        UserIdData {
          id
          firstName
          lastName
          email
          createdTs
        }
        Subscriptions {
          RolesProfileIdData {
            name
            code
          }
        }
      }
      pagination {
        page
        size
        total
      }
    }
  }
`);
