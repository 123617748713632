import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { useAddSnackbar } from "@/contexts/snackbar";
import { useDisableSupportUser } from "@/hooks/agent/disable";
import { useEnableSupportUser } from "@/hooks/agent/enable";
import { FeSupportUser } from "@/types/agents";

export const useAgentLinks = ({
  setSelectedAgent,
  refetch,
  refetchCountStatus,
}: {
  setSelectedAgent: (agent: FeSupportUser | null) => void;
  refetch: () => void;
  refetchCountStatus: () => void;
}) => {
  const { t: tUsers } = useTranslation("users");
  const addSnackbar = useAddSnackbar();
  const { disableSupportUser } = useDisableSupportUser();
  const { enableSupportUser } = useEnableSupportUser();

  return useCallback(
    (agent: FeSupportUser) =>
      match(agent.status)
        .with("SUPPORT_USER_ENABLED", () => [
          {
            text: tUsers("users.actions.edit"),
            size: "s",
            textAlign: "left",
            className: "customMenuItem",
            onClick: () => {
              setSelectedAgent(agent);
            },
          },
          {
            text: tUsers("users.actions.deactivate"),
            size: "s",
            color: "red",
            textAlign: "left",
            onClick: () => {
              disableSupportUser(agent.id)
                .then(() => {
                  addSnackbar({
                    type: "success",
                    title: tUsers("users.actions.deactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .catch(() => {
                  addSnackbar({
                    type: "error",
                    title: tUsers("users.actions.deactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .finally(() => {
                  refetch();
                  refetchCountStatus();
                });
            },
          },
        ])
        .with("SUPPORT_USER_DISABLED", () => [
          {
            text: tUsers("users.actions.reactivate"),
            size: "s",
            textAlign: "left",
            onClick: () => {
              enableSupportUser(agent.id)
                .then(() => {
                  addSnackbar({
                    type: "success",
                    title: tUsers("users.actions.reactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .catch(() => {
                  addSnackbar({
                    type: "error",
                    title: tUsers("users.actions.reactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .finally(() => {
                  refetch();
                  refetchCountStatus();
                });
            },
          },
        ])
        .with("SUPPORT_USER_INVITING", () => [
          {
            text: tUsers("users.actions.deactivate"),
            size: "s",
            textAlign: "left",
            color: "red",
            onClick: () => {
              addSnackbar({
                type: "success",
                title: tUsers("users.actions.cancelInvitation"),
                message: tUsers("users.actions.cancelInvitationDescription", {
                  email: agent.email,
                }),
                isAutoClose: true,
              });
            },
          },
        ])
        .otherwise(() => []),
    [
      tUsers,
      setSelectedAgent,
      addSnackbar,
      disableSupportUser,
      enableSupportUser,
      refetch,
      refetchCountStatus,
    ],
  );
};
