import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useMemo, useRef } from "react";

import {
  DEFAULT_FIRST_PAGE,
  DEFAULT_SEARCH_AGENTS_FILTERS,
  DEFAULT_SORTS,
  fromApiSupportUsersToFeSupportUsers,
  getApiSearchSupportUsersRequestSortField,
  getApiSearchSupportUsersRequestSortFields,
  getCountAgentsByStatus,
  getSearchAgents,
  UsePaginatedGetSearchAgents,
  USERS_PER_PAGE,
} from "@/api/graphql/agents/search";
import { queries } from "@/api/graphql/constants.ts";
import {
  AccountsMsSearchSupportUsersRequest,
  AccountsMsSearchSupportUsersRequestSortField,
} from "@/api/graphql/types/graphql.ts";
import { getNextPageParam } from "@/helpers/api/getNextPageParam";
import { useSortedFields } from "@/hooks/api/useSortedFields";
import {
  DEFAULT_SORTABLE_SUPPORT_USER_FIELDS,
  FeSupportUser,
} from "@/types/agents.ts";

export const usePaginatedGetSearchAgents: UsePaginatedGetSearchAgents = (
  args: AccountsMsSearchSupportUsersRequest,
) => {
  const pagingParams =
    useRef<AccountsMsSearchSupportUsersRequest["paging"]>(undefined);
  const defaultFieldsToSort: Array<AccountsMsSearchSupportUsersRequestSortField> =
    useMemo(
      () =>
        getApiSearchSupportUsersRequestSortFields(
          DEFAULT_SORTABLE_SUPPORT_USER_FIELDS,
        ),
      [],
    );
  const { updateSortDirection, getFieldsToSortAsArrayOfString, sortedFields } =
    useSortedFields<AccountsMsSearchSupportUsersRequestSortField>(
      defaultFieldsToSort,
      DEFAULT_SORTS,
    );
  const customSorts = useMemo(
    () => getFieldsToSortAsArrayOfString(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedFields],
  );
  const customFilters: Partial<
    Parameters<typeof getSearchAgents>[0]["filters"]
  > = {
    ids: args?.filters?.ids,
    igg: args?.filters?.igg,
    status: args?.filters?.status,
    query: args?.filters?.query,
  };
  const filters: Parameters<typeof getSearchAgents>[0]["filters"] = {
    ...DEFAULT_SEARCH_AGENTS_FILTERS,
    ...customFilters,
  };
  const customPaging = pagingParams.current || undefined;
  const sorts = customSorts.filter((s) => s.ascending !== null);
  const response = useInfiniteQuery({
    queryKey: [queries.GET_SEARCH_AGENTS, { customSorts, filters }],
    initialPageParam: DEFAULT_FIRST_PAGE,
    queryFn: async ({ pageParam: page }) => {
      try {
        return await getSearchAgents({
          filters,
          sorts,
          paging: customPaging
            ? customPaging
            : {
                page,
                size: USERS_PER_PAGE,
              },
        });
      } catch (_err) {
        return;
      }
    },
    getNextPageParam: (lastPage, allPages) =>
      getNextPageParam({
        lastPage,
        allPages,
        key: "AccountsMsSearchSupportUsers",
      }),
  });
  const sortField = (field: keyof FeSupportUser) => {
    const mappedField = getApiSearchSupportUsersRequestSortField(field);

    if (mappedField) {
      const totalElementsAlreadyFetched = response.data?.pages.reduce(
        (acc: number, page) =>
          acc + Number(page?.AccountsMsSearchSupportUsers?.data?.length || 0),
        0,
      );

      if (totalElementsAlreadyFetched) {
        pagingParams.current = {
          page: 1,
          size: totalElementsAlreadyFetched as number,
        };
      }

      updateSortDirection(mappedField);
    }
  };

  return {
    data: response.data
      ? fromApiSupportUsersToFeSupportUsers(response?.data)
      : undefined,
    error: response.error,
    isLoading: response.isLoading,
    refetch: response.refetch,
    fetchNextPage: response.fetchNextPage,
    sortField,
    totalOfDisplayableSupportUsersWithCurrentParameters:
      response?.data?.pages?.[0]?.AccountsMsSearchSupportUsers?.pagination
        ?.total || 0,
  };
};

export const useGetCountAgentsByStatus = () => {
  const response = useQuery({
    queryKey: [queries.GET_COUNT_AGENTS_BY_STATUS],
    queryFn: async () => {
      try {
        const { AccountsMsCountSupportUsersByStatus } =
          await getCountAgentsByStatus();

        return AccountsMsCountSupportUsersByStatus;
      } catch (_err) {
        return;
      }
    },
  });

  return {
    isLoading: response.isLoading,
    data: response.data,
    error: response.error,
    refetch: response.refetch,
  };
};
