import { GetUserQuery } from "@/api/graphql/accounts/getUser/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { AccountsMsSearchUsersRequest } from "@/api/graphql/types/graphql.ts";

export const getUserDetail = ({ filters }: AccountsMsSearchUsersRequest) => {
  const ids = filters?.ids || [];
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(GetUserQuery, {
    request: {
      filters: {
        ids,
      },
    },
  });
};
