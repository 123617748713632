import { useMutation } from "@tanstack/react-query";

import { InviteAgentMutation } from "@/api/graphql/accounts/invite/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { AccountsMsInviteSupportUserRequest } from "@/api/graphql/types/graphql.ts";
import { inviteAgent } from "@/hooks/agent/invite/types.ts";

const inviteSupportUserMutation = (
  request: AccountsMsInviteSupportUserRequest,
) => {
  const graphqlClient = getGraphqlClient();

  return graphqlClient.request(InviteAgentMutation, { request });
};
const useInviteSupportUser = () => {
  const { mutateAsync, isSuccess, error } = useMutation({
    mutationFn: async (supportUserInfo: inviteAgent) => {
      const data = await inviteSupportUserMutation(supportUserInfo);

      return data.AccountsMsInviteSupportUser.id;
    },
    onSuccess: () => "success",
    onError: () => "error",
  });

  return { mutateAsync, isSuccess, error };
};

export { inviteSupportUserMutation, useInviteSupportUser };
