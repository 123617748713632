import styled from "@emotion/styled";
import { Text } from "@totalenergiescode/mobility-business-rev-design-system";

import { Props } from "@/components/shared/PageHeader/types";

export const StyledTitle = styled(Text)``;

export const StyledTitleAndBreadcrumbContainer = styled("div")``;

/*
  With a 40px space on the left and without the icon, the content is aligned with the header of the page. 
  But by adding the icon, we have the 40px and the space of the icon, so to respect the alignment with the header I reduce by 20px.
*/
export const StyledContent = styled("div", {
  shouldForwardProp: (prop) => prop !== "$showIcon" && prop !== "$context",
})<{
  $showIcon: boolean;
  $context: Props["context"];
}>`
  padding: ${({ $showIcon, $context }) => {
    if ($showIcon) {
      return "0px 0px 20px 20px";
    }
    if ($context === "account") {
      return "0px 0px 20px 24px";
    }
    return "0px 0px 20px 40px";
  }};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  ${StyledTitleAndBreadcrumbContainer} {
    ${StyledTitle} {
      margin-bottom: 4 ${({ theme }) => theme.spacing(0.5)};
    }
  }
`;
