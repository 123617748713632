import { useStore } from "zustand";
import { persist } from "zustand/middleware";
import { createStore } from "zustand/vanilla";

import { LOCAL_STORAGE_KEYS } from "@/types/localStorage.ts";

type UIState = {
  isDarkMode: boolean;
  isSidebarOpen: boolean;
};

export type UIStore = {
  uiState: UIState;
  setUiState: (newUIState: UIState) => void;
  setIsDarkMode: (isDarkMode: boolean) => void;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  toggleDarkMode: () => void;
  toggleSidebarOpen: () => void;
};
export const uiStateStore = createStore<UIStore>()(
  persist(
    (set) => ({
      uiState: {
        isDarkMode: false as boolean,
        isSidebarOpen: false as boolean,
      },
      setUiState: (uiState) =>
        set((state) => ({
          ...state,
          uiState,
        })),
      setIsDarkMode: (isDarkMode: boolean) => {
        set((state) => ({
          ...state,
          uiState: {
            ...state.uiState,
            isDarkMode,
          },
        }));
      },
      setIsSidebarOpen: (isSidebarOpen: boolean) => {
        set((state) => ({
          ...state,
          uiState: {
            ...state.uiState,
            isSidebarOpen,
          },
        }));
      },
      toggleDarkMode: () => {
        set((state) => ({
          ...state,
          uiState: {
            ...state.uiState,
            isDarkMode: !state.uiState.isDarkMode,
          },
        }));
      },
      toggleSidebarOpen: () => {
        set((state) => ({
          ...state,
          uiState: {
            ...state.uiState,
            isSidebarOpen: !state.uiState.isSidebarOpen,
          },
        }));
      },
    }),
    {
      name: LOCAL_STORAGE_KEYS.UI_STATE,
    },
  ),
);

export const useUiStore = <T>(selector: (state: UIStore) => T) =>
  useStore(uiStateStore, selector);
