import { RFC, useEffect } from "react";

import { useLogoutUser } from "@/hooks/auth/logoutUser";
import { useMe } from "@/hooks/session/useMe";
import { removeLocalStorageItem } from "@/utils/localStorage";

export const AuthorizationLayout: RFC = ({ children }) => {
  const { data: meData, isLoading: isLoadingUseMe, isError, error } = useMe();
  const { logoutUser } = useLogoutUser();

  useEffect(() => {
    if (!isLoadingUseMe && (!meData || isError)) {
      removeLocalStorageItem("oidc.user");
      logoutUser();
    }
  }, [error, isError, isLoadingUseMe, logoutUser, meData]);

  return <>{children}</>;
};
