import { Box, styled } from "@mui/material";
import { Text } from "@totalenergiescode/mobility-business-rev-design-system";

export const StyledContainer = styled(Box)<{ isSelectedTab: boolean }>`
  flex-grow: 1;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 30px;
  cursor: pointer;
  transition: left 0.51s ease;

  ${({ isSelectedTab, theme }) => `
    ${
      isSelectedTab && `border-bottom: 3px solid ${theme.palette.primary[500]};`
    }`}
`;

export const StyledText = styled(Text)`
  ${({ theme }) => `
    &:hover {
      color: ${theme.palette.grey[500]};
    }
  `}
`;
