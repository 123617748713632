import { Snackbar } from "@totalenergiescode/mobility-business-rev-design-system";
import {
  createContext,
  RFC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  defaultSnackbarContext,
  DELAY_BETWEEN_SNACKBARS_MS,
} from "@/contexts/snackbar/constants";

import { type SnackbarContextType, type SnackbarItem } from "./types";

const SnackbarContext = createContext<SnackbarContextType>(
  defaultSnackbarContext,
);

export const SnackbarContextProvider: RFC = ({ children }) => {
  const [snackbarsQueue, setSnackbarsQueue] = useState<Array<SnackbarItem>>([]);
  const incrementalSnackbarId = useRef(0);
  const addSnackbar = useCallback<SnackbarContextType["addSnackbar"]>(
    (snackbar) => {
      incrementalSnackbarId.current += 1;

      setSnackbarsQueue((prev) => {
        if (prev.length) {
          return [
            ...prev,
            { ...snackbar, id: incrementalSnackbarId.current, isOpen: false },
          ];
        }
        return [
          { ...snackbar, id: incrementalSnackbarId.current, isOpen: true },
        ];
      });
    },
    [setSnackbarsQueue],
  );
  const onSnackbarClose = useCallback(() => {
    setSnackbarsQueue((prev) => {
      if (!prev.length) {
        return [];
      }
      const [firstSnackbar, ...otherSnackbars] = prev;

      return [{ ...firstSnackbar, isOpen: false }, ...otherSnackbars];
    });

    const timeout = setTimeout(() => {
      setSnackbarsQueue((prev) => {
        if (prev.length <= 1) {
          return [];
        }
        const [_, newSnackbar, ...otherSnackbars] = prev;

        return [{ ...newSnackbar, isOpen: true }, ...otherSnackbars];
      });
    }, DELAY_BETWEEN_SNACKBARS_MS);

    return () => clearTimeout(timeout);
  }, [setSnackbarsQueue]);
  const activeSnackbar = useMemo(() => {
    if (snackbarsQueue.length) {
      return snackbarsQueue[0];
    }
    return null;
  }, [snackbarsQueue]);

  return (
    <SnackbarContext.Provider
      value={{
        addSnackbar,
      }}
    >
      {children}
      {activeSnackbar ? (
        <Snackbar
          key={activeSnackbar.id}
          {...activeSnackbar}
          onClose={onSnackbarClose}
        />
      ) : null}
    </SnackbarContext.Provider>
  );
};

// TODO refacto and create hook?
// eslint-disable-next-line react-refresh/only-export-components
export const useAddSnackbar = () => {
  const snackbarContext = useContext(SnackbarContext);

  if (!snackbarContext) {
    throw new Error(
      "useSnackbar must be used within a SnackbarContextProvider",
    );
  }

  return snackbarContext.addSnackbar;
};
