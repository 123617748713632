import { useTranslation } from "react-i18next";

export const useBreadCrumbItems = (companyName?: string) => {
  const { t: tCommon } = useTranslation("common");

  return [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: companyName || "",
      path: "/accounts/$id" as const,
    },
  ];
};
