export const dateFormatOptions = {
  standard: {
    day: "2-digit",
    month: "long",
    year: "numeric",
  },
  short: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  },
  full: {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
    minute: "numeric",
    hourCycle: "h24",
    hour12: false,
    hour: "2-digit",
  },
  dayAndMonth: {
    day: "numeric",
    month: "long",
  },
} satisfies { [key: string]: Intl.DateTimeFormatOptions };
