import {
  ApiCompanyDetail,
  CompanyDetailBO,
} from "@/api/graphql/accounts/getAccountDocument/types.ts";

export const toCompanyDetails = (
  companyDetail: ApiCompanyDetail | undefined,
): CompanyDetailBO | undefined => ({
  name: companyDetail?.data?.[0].name,
  zipCode: companyDetail?.data?.[0].zipCode,
  city: companyDetail?.data?.[0].city,
  country: companyDetail?.data?.[0].country,
  number: companyDetail?.data?.[0].number,
  address: companyDetail?.data?.[0].address,
  userFirstName:
    companyDetail?.data?.[0]?.MainPrivilegeIdData?.UserIdData?.firstName,
  userLastName:
    companyDetail?.data?.[0]?.MainPrivilegeIdData?.UserIdData?.lastName,
  userEmail: companyDetail?.data?.[0]?.MainPrivilegeIdData?.UserIdData?.email,
});
