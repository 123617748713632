import { createFileRoute, redirect } from "@tanstack/react-router";

function Auth() {
  return (
    <>
      <h1>Auth</h1>
    </>
  );
}

export const Route = createFileRoute("/auth-callback")({
  beforeLoad: async () => {
    throw redirect({
      to: "/",
      search: {
        redirect: location.pathname,
      },
    });
  },
  component: Auth,
});
