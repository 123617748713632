import { useDsTheme } from "@totalenergiescode/mobility-business-rev-design-system";

import Empty_State from "@/assets/animations/Empty-State.json";
import {
  StyledContent,
  StyledHighlightLeftCard,
  StyledLottie,
  StyledPlayer,
  StyledPrincipalText,
  StyledSecondText,
} from "@/components/shared/EmptyComponent/styles";

const EmptyContent: React.FC<{
  principalText: string;
  secondText?: string;
  lottie?: object;
}> = ({ principalText, secondText, lottie }) => (
  <>
    <StyledLottie>
      <StyledPlayer autoplay loop src={lottie || Empty_State} />{" "}
    </StyledLottie>
    <StyledPrincipalText variant='title3'>{principalText}</StyledPrincipalText>
    {secondText ? (
      <StyledSecondText variant='text3'>{secondText}</StyledSecondText>
    ) : null}
  </>
);

export const EmptyDataInDB: React.FC<{
  principalText: string;
  secondText?: string;
  lottie?: object;
}> = ({ principalText, secondText, lottie }) => {
  const theme = useDsTheme();

  return (
    <StyledHighlightLeftCard highlightColor={theme.palette.primary[500] || ""}>
      <EmptyContent
        principalText={principalText}
        secondText={secondText}
        lottie={lottie}
      />
    </StyledHighlightLeftCard>
  );
};

export const EmptyDataForThisFilter: React.FC<{
  principalText: string;
  secondText?: string;
  lottie?: object;
}> = ({ principalText, secondText, lottie }) => (
  <StyledContent>
    <EmptyContent
      principalText={principalText}
      secondText={secondText}
      lottie={lottie}
    />
  </StyledContent>
);
