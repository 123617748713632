import { graphql } from "@/api/graphql/types/gql.ts";

export const GetAccountDetailQuery = graphql(`
  query GetAccountDetail($request: AccountsMsSearchAccountsRequest!) {
    AccountsMsSearchAccounts(request: $request) {
      data {
        name
        zipCode
        city
        country
        number
        address
        MainPrivilegeIdData {
          UserIdData {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`);
