import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { UserDetailBO } from "@/api/graphql/accounts/getUser/types.ts";
import { useStartSupportSession } from "@/api/graphql/impersonate/startSupport/hook";
import { SnackbarItem } from "@/contexts/snackbar/types";
import { env } from "@/env";

export const useBreadCrumbItems = (userDetail: UserDetailBO) => {
  const { t: tCommon } = useTranslation("common");

  return [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: `${userDetail?.firstName} ${userDetail?.lastName}`,
      path: undefined,
    },
  ];
};

export const useStartSupportSessionUtils = (
  addSnackbar: (snackbar: Omit<SnackbarItem, "id" | "isOpen">) => void,
  tUsers: TFunction,
) => {
  const { mutateAsync: startNewSession } = useStartSupportSession();
  const startSession = async ({ userId }: { userId: string }) => {
    try {
      const session = await startNewSession({ userId: userId });

      addSnackbar({
        type: "success",
        title: tUsers("users.impersonate.success"),
        message: ``,
        isAutoClose: true,
      });
      const agentToken = localStorage.getItem(
        `oidc.user:${env.OIDC_AUTHORITY}:${env.OIDC_CLIENT_ID}`,
      );
      const agentTokenJSON =
        !!agentToken && (JSON.parse(agentToken) as { id_token: string });

      if (session && agentTokenJSON) {
        const url = `${env.FRONT_OFFICE_URI}?sessionId=${session.sessionId}&expiration=${session.expiresTs}&agentToken=${agentTokenJSON.id_token}&userId=${userId}`;

        window.open(url, "_blank");
      }
    } catch {
      addSnackbar({
        type: "error",
        title: tUsers("users.impersonate.error"),
        message: ``,
        isAutoClose: true,
      });
    }
  };

  return { startSession };
};
