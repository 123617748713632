import { useMutation } from "@tanstack/react-query";

import { updateAgentMutation } from "@/api/graphql/agents/update/api.ts";
import { UpdateAgent } from "@/api/graphql/agents/update/type.ts";

export const useUpdateAgent = () => {
  const { mutateAsync, isSuccess, error } = useMutation({
    mutationFn: async (agentUpdatedInfo: UpdateAgent) => {
      await updateAgentMutation(agentUpdatedInfo);
    },
    onSuccess: () => "success",
    onError: () => "error",
  });

  return { mutateAsync, isSuccess, error };
};
